import { lightPalette } from '../../theme/roots/palette';
import { Color } from '../../utils/types/palette';
export var getInputBackgroundColor = function getInputBackgroundColor(readOnly, disabled) {
  if (readOnly || disabled) {
    return lightPalette[Color.BackgroundHover];
  }
  return lightPalette[Color.BackgroundStateless];
};
export var getInputTextColor = function getInputTextColor(readOnly, disabled) {
  if (readOnly) {
    return lightPalette[Color.AccentPrimaryHover];
  }
  if (disabled) {
    return lightPalette[Color.TextPlaceholder];
  }
  return lightPalette[Color.TextPrimary];
};
export var getInputBorderColor = function getInputBorderColor(readOnly, disabled) {
  if (readOnly) {
    return lightPalette[Color.BordersItem];
  }
  if (disabled) {
    return lightPalette[Color.BordersSecondary];
  }
  return lightPalette[Color.BorderPrimaryStateless];
};
export var handleCopyIcon = function handleCopyIcon(value, setShowCopyMessage) {
  navigator.clipboard.writeText(value);
  setShowCopyMessage(true);
};