import * as React from 'react';
import Topbar from "./Topbar";
import Body from "./Body";
import StatusBar from "./StatusBar";
import Plugins from "./Plugins";
import {InitialConfigType, LexicalComposer} from "@lexical/react/LexicalComposer";
import makeNodesFromConfig from "../config/makeNodesFromConfig";
import useEditorContext from "./EditorContext/useEditorContext";
import ovaEditorTheme from "./ovaEditorTheme";

type EditorComposerProps = {
    children?: React.ReactNode,
}

const EditorComposer = ({ children } : EditorComposerProps) => {

    const { config, initState } = useEditorContext();

    const initialConfig : InitialConfigType = {
        editorState: config.rtcId ? null : initState,
        namespace: 'ova-editor',
        onError: ((e) => console.error(e)),
        nodes: makeNodesFromConfig(config),
        theme: ovaEditorTheme,
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <Topbar />
            <Body />
            <StatusBar />
            <Plugins />
            {children}
        </LexicalComposer>
    );
}

export default EditorComposer
