import {ElementFormatType} from "lexical/nodes/LexicalElementNode";

type AlignInfo = {
    icon: string;
    label: string;
}

const aligns : Record<ElementFormatType, AlignInfo> = {
    left: {
        icon: 'format-align-left',
        label: 'Вирівняти по лівому краю',
    },
    start: {
        icon: 'format-align-left',
        label: 'Вирівняти по початку',
    },
    center: {
        icon: 'format-align-center',
        label: 'Вирівняти по центру',
    },
    right: {
        icon: 'format-align-right',
        label: 'Вирівняти по правому краю',
    },
    end: {
        icon: 'format-align-right',
        label: 'Вирівняти по кінцю',
    },
    justify: {
        icon: 'format-align-justify',
        label: 'Вирівняти по ширині',
    },
    '': {
        icon: 'format-float-none',
        label: 'Не вирівнювати',
    },
}

export default function getAlignInfo(type: ElementFormatType) : AlignInfo {
    return aligns[type];
}
