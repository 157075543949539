const checkVisibility = (element: HTMLElement) => {
    if (!element) {
        return false;
    }
    if (element.checkVisibility) {
        return element.checkVisibility();
    }
    return !!( element.offsetWidth || element.offsetHeight || element.getClientRects().length );
}

export default checkVisibility;
