import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Accordion, ButtonGroup, Button } from 'react-bootstrap'
import {svgDefaultIcon} from "./svgDefaultIcon";
import image from './images/bonus.jpg'

type BonusBlockItem = {
    icon: string,
    text: string,
}

const defaultBonusBlockItem: BonusBlockItem = {
    icon: '',
    text: '',
}

type BonusBlockData = {
    items: BonusBlockItem[],
}

const defaultBonusBlockData: BonusBlockData = {
    items: [
        {icon: svgDefaultIcon, text: 'Щастям б\'єш жук їх глицю в фон й ґедзь пріч.» (34 знаки; тут і далі рахуються лише літери та апостроф'},
        {icon: svgDefaultIcon, text: 'Фабрикуймо гідність, лящім їжею, ґав хапаймо, з\'єднавці чаш!'},
        {icon: svgDefaultIcon, text: 'В Бахчисараї фельд\'єґер зумів одягнути ящірці жовтий капюшон!'},
        {icon: svgDefaultIcon, text: 'Хвацький юшковар Філіп щодня на ґанку готує сім\'ї вечерю з жаб.'},
        {icon: svgDefaultIcon, text: 'Грішний джиґіт, що хотів у Францію, позбувався цієї думки з\'їдаючи трюфель.'},
        {icon: svgDefaultIcon, text: 'Жебракують філософи при ґанку церкви в Гадячі, ще й шатро їхнє п\'яне знаємо.'},
    ],
}

const isBonusBlockItem = (data: unknown): data is BonusBlockItem => {
    return typeof data === 'object' && data !== null && 'icon' in data && 'text' in data;
}

const isBonusBlockData = (data: unknown): data is BonusBlockData => {
    return typeof data === 'object' && data !== null && 'items' in data && Array.isArray(data.items) && data.items.every(isBonusBlockItem);
}

const bonusBlockDataValidator = async (data: unknown) => {
    if (!isBonusBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Бонус"');
    }
}

type BonusBlockFormRowProps = {
    idx: number,
    handleRemove: () => void,
    handleUp?: () => void,
    handleDown?: () => void,
}

const BonusBlockFormRow = ({ idx, handleUp, handleDown, handleRemove } : BonusBlockFormRowProps) => {
    return (
        <Accordion.Item eventKey={idx.toString()}>
            <Accordion.Header>
                Пункт #{idx}

                <div className='ms-auto'>
                    <ButtonGroup>
                        <Button variant='outline-secondary' onClick={handleUp} className='mdi mdi-arrow-up-bold' />
                        <Button variant='outline-secondary' onClick={handleDown} className='mdi mdi-arrow-down-bold' />
                    </ButtonGroup>

                    <Button variant='outline-danger' onClick={handleRemove} className='mdi mdi-delete' />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Input
                    type='textarea'
                    name={`items.${idx}.icon`}
                    label='Іконка'
                    text='В форматі svg'
                />
                <Form.Input
                    type='textarea'
                    name={`items.${idx}.text`}
                    label='Текст'
                />
            </Accordion.Body>
        </Accordion.Item>
    )
}

const BonusBlockForm = () => {
    const { fields, append, remove, swap } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Accordion defaultActiveKey='0'>
                {fields.map((field, idx) => (
                    <BonusBlockFormRow
                        key={field.id}
                        idx={idx}
                        handleRemove={() => remove(idx)}
                        handleUp={idx > 0 ? () => swap(idx, idx - 1) : undefined}
                        handleDown={idx < fields.length - 1 ? () => swap(idx, idx + 1) : undefined}
                    />
                ))}
            </Accordion>
            <div className='mt-2'><Button onClick={() => append(defaultBonusBlockItem)}>Додати</Button></div>
        </React.Fragment>
    )
}

const BonusBlock : BlockDefinition<BonusBlockData> = {
    name: 'hs--bonus',
    image: image,
    title: 'Блок "Бонус"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: bonusBlockDataValidator,
    form: BonusBlockForm,
    initialData: defaultBonusBlockData,
}

export default BonusBlock;