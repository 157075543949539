import * as React from "react";
import {Media} from "../types/Media";
import JustifiedGallery from "@ova-studio/justified-gallery";
import PictureRender from "./PictureRender";

export type MediaGalleryProps = {
    items: Media[],
    captions?: Record<number, string>,
    lazy?: boolean,
    disableInteraction?: boolean,
}

type MediaGalleryItem = {
    media: Media,
    caption?: string,
    width: number,
    height: number,
}

const fjGalleryHandler : React.LegacyRef<HTMLDivElement> = (container) => {
    if (!container) {
        return;
    }

    new JustifiedGallery(container, {
        itemSelector: '.ova-gallery__item',
        gutter: 2,
        lastRow: 'center',
        rowHeight: 200,
        rowHeightTolerance: 0.2,
    });
}



const MediaGallery = ({ items, captions, lazy, disableInteraction }: MediaGalleryProps) => {

    const galleryItems: MediaGalleryItem[] = React.useMemo(() => {
        return items.filter(item => !!item.image)
            .map(item => ({
                media: item,
                caption: captions?.[item.id],
                width: 1920,
                height: 1920 * item.image!.aspectRatio,
            }));
    }, []);

    const RenderElement = disableInteraction ? 'span' : 'a';

    return (
        <div className='ova-gallery' ref={fjGalleryHandler}>
            {galleryItems.map((item) => (
                <RenderElement href={item.media.image!.url} key={item.media.id} data-lg-size={`${item.width}-${item.height}`} className='ova-gallery__item'>
                    <PictureRender data={item.media.preview!} alt={item.caption} lazy={lazy} />
                </RenderElement>
            ))}
        </div>
    )
}

export default MediaGallery;
