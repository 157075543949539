import {useContext} from "react";
import EditorContext from "./EditorContext";
import {EditorContextData} from "./EditorContextData";

export default function useEditorContext(): EditorContextData {
    const data = useContext<EditorContextData|undefined>(EditorContext);

    if (!data) {
        throw new Error('useEditorContext must be used within an EditorContextProvider');
    }

    return data;
}
