import {EditorConfig} from "../../../config/EditorConfig";
import {ListType} from "@lexical/list";
import {HeadingTagType} from "@lexical/rich-text";

type TypeInfo = {
    icon: string;
    label: string;
}

export type BlockType = HeadingTagType | 'quote' | ListType | 'paragraph' | 'custom';

const lists : Record<BlockType, TypeInfo> = {
    paragraph: {
        icon: 'format-text',
        label: 'Звичайний',
    },
    quote: {
        icon: 'format-quote-open',
        label: 'Цитата',
    },
    h1: {
        icon: 'format-header-1',
        label: 'Заголовок 1',
    },
    h2: {
        icon: 'format-header-2',
        label: 'Заголовок 2',
    },
    h3: {
        icon: 'format-header-3',
        label: 'Заголовок 3',
    },
    h4: {
        icon: 'format-header-4',
        label: 'Заголовок 4',
    },
    h5: {
        icon: 'format-header-5',
        label: 'Заголовок 5',
    },
    h6: {
        icon: 'format-header-5',
        label: 'Заголовок 5',
    },
    number: {
        icon: 'format-list-numbered',
        label: 'Нумерований список',
    },
    bullet: {
        icon: 'format-list-bulleted',
        label: 'Маркований список',
    },
    check: {
        icon: 'format-list-checkbox',
        label: 'Список з галочками',
    },
    custom: {
        icon: 'shape',
        label: 'Вставка',
    }
}

export function getAllowedTypesFromConfig(config : EditorConfig) : BlockType[] {
    const types : BlockType[] = ['paragraph'];

    if (config.headings) {
        types.push(...config.headings);
    }

    if (config.quote) {
        types.push('quote');
    }

    if (config.lists) {
        types.push(...config.lists);
    }

    if (types.length === 1) {
        return [];
    }

    return types;
}

export function getTypeInfo(type: BlockType) : TypeInfo {
    return lists[type];
}
