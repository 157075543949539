import { defaultBreakpoints } from './default-breakpoints';
var createBreakpoints = function createBreakpoints(breakpoints) {
  var _breakpoints$values = breakpoints.values,
    values = _breakpoints$values === void 0 ? defaultBreakpoints.values : _breakpoints$values,
    _breakpoints$classes = breakpoints.classes,
    classes = _breakpoints$classes === void 0 ? defaultBreakpoints.classes : _breakpoints$classes;
  var unit = 'px';
  var keys = Object.keys(values);
  var up = function up(key) {
    var value = typeof values[key] === 'number' ? values[key] : key;
    return "@media (min-width:".concat(value).concat(unit, ")");
  };
  var down = function down(key) {
    var value = values[key];
    return "@media (max-width:".concat(value).concat(unit, ")");
  };
  var between = function between(start, end) {
    var endIndex = keys.indexOf(end);
    return "@media (min-width:".concat(values[start]).concat(unit, ") and (max-width:").concat(values[keys[endIndex]]).concat(unit, ")");
  };
  var only = function only(key) {
    if (keys.indexOf(key) + 1 < keys.length) {
      return between(key, keys[keys.indexOf(key) + 1]);
    }
    return up(key);
  };
  var getBreakpointClass = function getBreakpointClass(width, hideSelector) {
    var removeSelector = function removeSelector(str) {
      return str.slice(1);
    };
    if (width >= values.xs && width < values.sm) {
      return hideSelector ? removeSelector(classes.xs) : classes.xs;
    }
    if (width >= values.sm && width < values.md) {
      return hideSelector ? removeSelector(classes.sm) : classes.sm;
    }
    if (width >= values.md && width < values.lg) {
      return hideSelector ? removeSelector(classes.md) : classes.md;
    }
    if (width >= values.lg && width < values.xl) {
      return hideSelector ? removeSelector(classes.lg) : classes.lg;
    }
    if (width >= values.xl && width < values.xxl) {
      return hideSelector ? removeSelector(classes.xl) : classes.xl;
    }
    if (width >= values.xxl && width < values.xxxl) {
      return hideSelector ? removeSelector(classes.xxl) : classes.xxl;
    }
    if (width >= values.xxxl) {
      return hideSelector ? removeSelector(classes.xxxl) : classes.xxxl;
    }
    return hideSelector ? removeSelector(classes.md) : classes.md;
  };
  return {
    keys: keys,
    values: values,
    classes: classes,
    getBreakpointClass: getBreakpointClass,
    up: up,
    down: down,
    between: between,
    only: only
  };
};
export default createBreakpoints;