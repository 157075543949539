import * as React from "react";
import CollaborationService from "./CollaborationService";
import CollaborationStatusBar from "./StatusBar";
import {CollaborationOptions, isCollaborationConfig} from "./types";
import CollaborationContext from "./CollaborationContext";
import {useExternalStore, useAppAuth, useAppExternalConfig, BouncingLoader} from "@ova-studio/react-hyper-admin";

export type CollaborationContextProviderProps = CollaborationOptions & {
    children: React.ReactNode,
}

const CollaborationContextProvider = ({ children, ...opts }: CollaborationContextProviderProps) => {

    const config = useAppExternalConfig('rtc', isCollaborationConfig);
    const appAuth = useAppAuth();

    const service = React.useMemo(() => new CollaborationService(opts, config, appAuth), []);

    const state = useExternalStore(service);

    React.useEffect(() => {
        return () => {
            service.destroy();
        }
    }, [])

    if (!state.isReady) {
        return <BouncingLoader />;
    }

    return (
        <CollaborationContext.Provider value={service}>
            {children}
            <CollaborationStatusBar data={state} />
        </CollaborationContext.Provider>
    )
}

export default CollaborationContextProvider;