import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { Color as PaletteColor } from '../../utils/types/palette';
import { FontVariant as FV } from '../../utils/types/typography';
import { InputSize } from '../../utils/types';
export var sizeInputMixin = _defineProperty(_defineProperty({}, InputSize.Sm, "\n    padding: 8px 12px;\n    height: 32px;\n  "), InputSize.Md, "\n    padding: 11px 16px;\n    height: 40px;\n  ");
export var sizeCrossInputMixin = _defineProperty(_defineProperty({}, InputSize.Sm, "\n    padding: 4px 6px 4px 12px;\n    max-height: 32px;\n  "), InputSize.Md, "\n    padding: 0px 4px 0px 16px;\n    max-height: 40px;\n  ");
export var fontSizeInputMixin = _defineProperty(_defineProperty({}, InputSize.Sm, function (_ref) {
  var font = _ref.theme.typography.font;
  return css(["", ""], font[FV.InputMd]);
}), InputSize.Md, function (_ref2) {
  var font = _ref2.theme.typography.font;
  return css(["", ""], font[FV.InputLg]);
});
export var sizeInputLabelMixin = _defineProperty(_defineProperty({}, InputSize.Sm, function (_ref3) {
  var font = _ref3.theme.typography.font;
  return css(font[FV.LabelSmall]);
}), InputSize.Md, function (_ref4) {
  var font = _ref4.theme.typography.font;
  return css(font[FV.LabelMedium]);
});
export var errorMixin = function errorMixin(_ref5) {
  var palette = _ref5.theme.palette;
  return css(["background:", " !important;border:1px solid ", " !important;"], palette[PaletteColor.BackgroundSecondary], palette[PaletteColor.Error]);
};