import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { Color as PaletteColor } from '../../utils/types/palette';
import { ButtonSize, IconButtonColor } from '../../utils/types';
export var colorButtonMixin = _defineProperty(_defineProperty(_defineProperty({}, IconButtonColor.Primary, function (_ref) {
  var palette = _ref.theme.palette,
    active = _ref.active;
  return css(["background-color:", ";color:", ";&:hover{background-color:", ";}&:focus{background-color:", ";}&:active{background-color:", ";}", " &:disabled{color:", ";background:", ";cursor:default;}"], palette[PaletteColor.AccentStateless], palette[PaletteColor.IconsInvert], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive], active && css(["background-color:", ";&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity]);
}), IconButtonColor.Secondary, function (_ref2) {
  var palette = _ref2.theme.palette,
    active = _ref2.active;
  return css(["background-color:", ";color:", ";border:1px solid ", ";&:hover{color:", ";background-color:", ";}&:focus{background-color:", ";color:", ";border:1px solid ", ";}&:active{color:", ";background-color:", ";}", " &:disabled{color:", ";background:", ";border:none;cursor:default;}"], palette[PaletteColor.BackgroundStateless], palette[PaletteColor.AccentStateless], palette[PaletteColor.AccentStateless], palette[PaletteColor.IconsInvert], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.IconsInvert], palette[PaletteColor.AccentPrimaryActive], active && css(["color:", ";background-color:", ";&:hover,&:focus{color:", ";background-color:", ";}"], palette[PaletteColor.IconsInvert], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.IconsInvert], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity]);
}), IconButtonColor.Basic, function (_ref3) {
  var palette = _ref3.theme.palette,
    active = _ref3.active;
  return css(["background-color:transparent;color:", ";border:none;&:hover{background-color:", ";color:", ";}&:focus{background-color:", ";color:", ";}&:active{background-color:", ";color:", ";}", " &:disabled{background-color:transparent;color:", ";cursor:default;}"], palette[PaletteColor.IconsPrimary], palette[PaletteColor.BackgroundHover], palette[PaletteColor.IconsPrimaryHover], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkActive], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkActive], active && css(["background-color:", ";color:", ";&:hover,&:focus{background-color:", ";color:", ";}"], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkActive], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkActive]), palette[PaletteColor.BordersDisabled]);
});
export var squarePaddingMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["padding:6px;"])), ButtonSize.Sm, css(["padding:9px;"])), ButtonSize.Md, css(["padding:12px;"])), ButtonSize.Lg, css(["padding:11px;"]));