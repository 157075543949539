import * as React from "react";
import CollaborationService from "./CollaborationService";

const CollaborationContext = React.createContext<CollaborationService|null>(null);

export const useCollaborationContext = () : CollaborationService => {
    const context = React.useContext(CollaborationContext);
    if (!context) {
        throw new Error('useCollaborationContext must be used within a CollaborationContextProvider');
    }
    return context;
}

export default CollaborationContext;