import {LinkFormData, SelectedLink} from "../types";
import valueOrDefault from "../../../../utils/valueOrDefault";

const makeLinkFormData = (data : SelectedLink | null) : LinkFormData => ({
    url: valueOrDefault(data?.url, ''),
    title: valueOrDefault(data?.title, ''),
    rel: valueOrDefault(data?.rel, 'nofollow noopener'),
    target: valueOrDefault(data?.target, '_blank'),
})

export default makeLinkFormData;
