import {TextFormatType} from "lexical";

type FormatInfo = {
    icon: string;
    label: string;
}

const textFormats : Record<TextFormatType, FormatInfo> = {
    bold: {
        icon: 'format-bold',
        label: 'Жирний',
    },
    underline: {
        icon: 'format-underline',
        label: 'Підкреслений',
    },
    italic: {
        icon: 'format-italic',
        label: 'Курсив',
    },
    strikethrough: {
        icon: 'format-strikethrough',
        label: 'Закреслений',
    },
    highlight: {
        icon: 'format-color-highlight',
        label: 'Виділений',
    },
    code: {
        icon: 'format-code',
        label: 'Код',
    },
    subscript: {
        icon: 'format-subscript',
        label: 'Нижній індекс',
    },
    superscript: {
        icon: 'format-superscript',
        label: 'Верхній індекс',
    },
}

export default function getTextFormatInfo(type: TextFormatType) : FormatInfo {
    return textFormats[type];
}
