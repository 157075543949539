import {CollaborationService} from "@ova-studio/rtc-client";
import type {Doc} from "yjs";
import {Provider} from "@lexical/yjs";

const providerFactoryGenerator = (service: CollaborationService, id: string, yjsDocMap: Map<string, Doc>) : Provider => {
    const cDoc = service.makeDocument(id);
    yjsDocMap.set(id, cDoc.doc);

    return cDoc.provider as unknown as Provider;
}

export default providerFactoryGenerator;
