import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Accordion, ButtonGroup, Button } from 'react-bootstrap'
import image from './images/columns-list.jpg'

type ColumnsListBlockItem = {
    text: string,
    link: string | null,
    selected: boolean,
}

const defaultColumnsListBlockItem: ColumnsListBlockItem = {
    text: '',
    link: null,
    selected: false,
}

type ColumnsListBlockData = {
    title: string,
    items: ColumnsListBlockItem[],
}

const isColumnsListBlockItem = (data: unknown): data is ColumnsListBlockItem => {
    return typeof data === 'object' && data !== null && 'text' in data && 'link' in data && 'selected' in data;
}

const isColumnsListBlockData = (data: unknown): data is ColumnsListBlockData => {
    return typeof data === 'object' && data !== null && 'title' in data && 'items' in data && Array.isArray(data.items) && data.items.every(isColumnsListBlockItem);
}

const columnsListBlockDataValidator = async (data: unknown) => {
    if (!isColumnsListBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Список колонками"');
    }
}

type ColumnsListBlockFormRowProps = {
    idx: number,
    handleRemove: () => void,
    handleUp?: () => void,
    handleDown?: () => void,
}

const ColumnsListBlockFormRow = ({ idx, handleUp, handleDown, handleRemove } : ColumnsListBlockFormRowProps) => {
    return (
        <Accordion.Item eventKey={idx.toString()}>
            <Accordion.Header>
                Пункт #{idx}

                <div className='ms-auto'>
                    <ButtonGroup>
                        <Button variant='outline-secondary' onClick={handleUp} className='mdi mdi-arrow-up-bold' />
                        <Button variant='outline-secondary' onClick={handleDown} className='mdi mdi-arrow-down-bold' />
                    </ButtonGroup>

                    <Button variant='outline-danger' onClick={handleRemove} className='mdi mdi-delete' />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Input
                    type='text'
                    name={`items.${idx}.text`}
                    label='Текст'
                />

                <Form.Input
                    type='text'
                    name={`items.${idx}.link`}
                    label='Посилання'
                />

                <Form.Check
                    name={`items.${idx}.selected`}
                    label='Вибрано'
                />
            </Accordion.Body>
        </Accordion.Item>
    )
}

const ColumnsListBlockForm = () => {
    const { fields, append, remove, swap } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Form.Input
                type='text'
                name='title'
                label='Заголовок'
            />

            <Accordion defaultActiveKey='0'>
                {fields.map((field, idx) => (
                    <ColumnsListBlockFormRow
                        key={field.id}
                        idx={idx}
                        handleRemove={() => remove(idx)}
                        handleUp={idx > 0 ? () => swap(idx, idx - 1) : undefined}
                        handleDown={idx < fields.length - 1 ? () => swap(idx, idx + 1) : undefined}
                    />
                ))}
            </Accordion>

            <div className='mb-2'><Button onClick={() => append(defaultColumnsListBlockItem)}>Додати</Button></div>
        </React.Fragment>
    )
}

const ColumnsListBlock : BlockDefinition<ColumnsListBlockData> = {
    name: 'hs--columns-list',
    image: image,
    title: 'Блок "Список колонками"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: columnsListBlockDataValidator,
    form: ColumnsListBlockForm,
    initialData: {
        title: 'Список колонками з посиланнями',
        items: [
            { text: 'Перший пункт', link: '#', selected: false },
            { text: 'Другий пункт', link: '#', selected: true },
            { text: 'Третій пункт', link: '#', selected: false },
        ],
    },
}

export default ColumnsListBlock;