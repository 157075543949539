import {getCurrentImgDataFunction} from "react-filerobot-image-editor";
import {RefObject, useCallback, useRef, useState} from "react";
import {Media} from "../../types/Media";
import {useMediaLibraryService} from "../../services/MediaLibraryService";
import {SimpleCallback, useAppToasts} from "@ova-studio/react-hyper-admin";

type UseImageEditorSaveOptions = {
    close?: SimpleCallback,
}

type SaveFunction = (id: Media['id'], filename : Media['filename']) => Promise<void>;

type UseImageEditorSaveReturn = {
    currentImgRef: RefObject<getCurrentImgDataFunction>,
    isSaving: boolean,
    save: SaveFunction,
}

const useImageEditorSave = ({ close } : UseImageEditorSaveOptions) : UseImageEditorSaveReturn => {
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const isSavingRef = useRef<boolean>(false);
    const currentImgRef = useRef<getCurrentImgDataFunction>(null);

    const mediaService = useMediaLibraryService().mediaManager;
    const toastService = useAppToasts();

    const save: SaveFunction = useCallback<SaveFunction>(async (id, filename) => {
        if (!currentImgRef.current) return;
        if (isSavingRef.current) return;

        isSavingRef.current = true;
        setIsSaving(true);

        const { imageData, designState } = currentImgRef.current({}, true, true);

        try {
            await mediaService.saveMediaImageEdit(id, filename, imageData.imageCanvas!, designState);
            close?.();
            toastService.createToast({
                body: 'Зображення успішно збережено',
                variant: 'success',
                showTime: 10,
            });
        } catch (e) {
            toastService.createToast({
                title: 'Помилка збереження зображення',
                body: e instanceof Error ? e.message : 'Невідома помилка',
                variant: 'danger',
            })
        } finally {
            isSavingRef.current = false;
            setIsSaving(false);
        }

    }, []);

    return {
        currentImgRef,
        isSaving,
        save,
    }
}

export default useImageEditorSave;
