import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { FontVariant as FV } from '../../utils/types/typography';
import { InputSize } from '../../utils/types';
export var sizeHintMixin = _defineProperty(_defineProperty({}, InputSize.Sm, function (_ref) {
  var font = _ref.theme.typography.font;
  return css(font[FV.LabelExtraSmallUp]);
}), InputSize.Md, function (_ref2) {
  var font = _ref2.theme.typography.font;
  return css(font[FV.LabelSmall]);
});