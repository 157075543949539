import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import getAlignInfo from "./alignTypes";
import {$isParagraphNode, FORMAT_ELEMENT_COMMAND} from "lexical";
import getNodeOfTypeFromSelection from "../../../utils/getNodeOfTypeFromSelection";

const AlignPlugin = () => {

    const { config: { align }, topbarRegistry } = useEditorContext();

    if (!align) {
        return;
    }

    React.useEffect(() => {
        return topbarRegistry.registerMultiple(
            align.map((type) => ({
                ...getAlignInfo(type),
                section: 'align',
                command: FORMAT_ELEMENT_COMMAND,
                payload: type,
                isActiveCheck: (selection) => {
                    const paragraph = getNodeOfTypeFromSelection(selection, $isParagraphNode);
                    if (paragraph) {
                        return paragraph.getFormatType() === type;
                    }
                    return false;
                }
            }))
        )
    }, [])

    return null;
}

export default AlignPlugin
