import * as React from 'react';
import {Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import EmojiPicker, {EmojiClickData, EmojiStyle, Theme} from "emoji-picker-react";

type EmojiPickerModalProps = {
    state: UseModalReturn<any>,
    handleSelect: (e: EmojiClickData) => void,
}

const EmojiPickerModal = ({ state, handleSelect }: EmojiPickerModalProps) => {
    return (
        <Modal title='Вставити Emoji' state={state}>
            <EmojiPicker
                theme={Theme.DARK}
                lazyLoadEmojis={true}
                searchPlaceholder='Пошук...'
                width='100%'
                emojiStyle={EmojiStyle.NATIVE}
                style={{ borderRadius: 0 }}
                onEmojiClick={(e) => {
                    handleSelect(e);
                }}
            />
        </Modal>
    );
}

export default EmojiPickerModal
