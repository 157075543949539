import { EditorConfig } from "@ova-studio/react-block-editor";
import BonusBlock from "./BonusBlock";
import CallbackBlock from "./CallbackBlock";
import ColumnsListBlock from "./ColumnsListBlock";
import ConnectBlock from "./ConnectBlock";
import LogosBlock from "./LogosBlock";
import MapBlock from "./MapBlock";
import PromoBlock from "./PromoBlock";
import ServicesBlock from "./ServicesBlock";
import SliderBlock from "./SliderBlock";
import TextAndImageBlock from "./TextAndImageBlock";
import TrustBlock from "./TrustBlock";
import WorkBlock from "./WorkBlock";

const blocks : EditorConfig['blocks'] = [
    BonusBlock,
    CallbackBlock,
    ColumnsListBlock,
    ConnectBlock,
    LogosBlock,
    MapBlock,
    PromoBlock,
    ServicesBlock,
    SliderBlock,
    TextAndImageBlock,
    TrustBlock,
    WorkBlock,
];

export default blocks;