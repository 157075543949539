export default {
    name: 'Ім\'я',
    save: 'Зберегти',
    saveAs: 'Зберегти як',
    back: 'Назад',
    loading: 'Завантаження...',
    resetOperations: 'Скинути/видалити всі операції',
    changesLoseWarningHint:
        'Якщо ви натиснете кнопку "скинути", ваші зміни буде втрачено. Ви хочете продовжити?',
    discardChangesWarningHint:
        'Якщо ви закриєте модальне вікно, ваші останні зміни не будуть збережені.',
    cancel: 'Скасувати',
    apply: 'Застосувати',
    warning: 'Попередження',
    confirm: 'Підтвердити',
    discardChanges: 'Відхилити зміни',
    undoTitle: 'Скасувати останню операцію',
    redoTitle: 'Повторити останню операцію',
    showImageTitle: 'Показати оригінальне зображення',
    zoomInTitle: 'Збільшити',
    zoomOutTitle: 'Зменшити',
    toggleZoomMenuTitle: 'Перемкнути меню масштабування',
    adjustTab: 'Корекція',
    finetuneTab: 'Тонка корекція',
    filtersTab: 'Фільтри',
    watermarkTab: 'Водяний знак',
    annotateTabLabel: 'Анотація',
    resize: 'Змінити розмір',
    resizeTab: 'Змінити розмір',
    imageName: 'Назва зображення',
    invalidImageError: 'Невірне зображення.',
    uploadImageError: 'Помилка під час завантаження зображення.',
    areNotImages: 'не є зображеннями',
    isNotImage: 'не є зображенням',
    toBeUploaded: 'щоб завантажити',
    cropTool: 'Обрізка',
    original: 'Оригінал',
    custom: 'Користувацький',
    square: 'Квадрат',
    landscape: 'Пейзаж',
    portrait: 'Портрет',
    ellipse: 'Еліпс',
    classicTv: 'Класичне ТБ',
    cinemascope: 'Кінескоп',
    arrowTool: 'Стрілка',
    blurTool: 'Розмиття',
    brightnessTool: 'Яскравість',
    contrastTool: 'Контраст',
    ellipseTool: 'Еліпс',
    unFlipX: 'Скасувати обертання по осі X',
    flipX: 'Обертання по осі X',
    unFlipY: 'Скасувати обертання по осі Y',
    flipY: 'Обертання по осі Y',
    hsvTool: 'HSV',
    hue: 'Відтінок',
    brightness: 'Яскравість',
    saturation: 'Насиченість',
    value: 'Значення',
    imageTool: 'Зображення',
    importing: 'Імпорт...',
    addImage: '+ Додати зображення',
    uploadImage: 'Завантажити зображення',
    fromGallery: 'З галереї',
    lineTool: 'Лінія',
    penTool: 'Олівець',
    polygonTool: 'Багатокутник',
    sides: 'Сторони',
    rectangleTool: 'Прямокутник',
    cornerRadius: 'Радіус кута',
    resizeWidthTitle: 'Ширина в пікселях',
    resizeHeightTitle: 'Висота в пікселях',
    toggleRatioLockTitle: 'Перемкнути блокування співвідношення',
    resetSize: 'Скинути до оригінального розміру зображення',
    rotateTool: 'Повернути',
    textTool: 'Текст',
    textSpacings: 'Інтервали тексту',
    textAlignment: 'Вирівнювання тексту',
    fontFamily: 'Шрифт',
    size: 'Розмір',
    letterSpacing: 'Інтервал між літерами',
    lineHeight: 'Висота рядка',
    warmthTool: 'Теплота',
    addWatermark: '+ Додати водяний знак',
    addTextWatermark: '+ Додати текстовий водяний знак',
    addWatermarkTitle: 'Виберіть тип водяного знаку',
    uploadWatermark: 'Завантажити водяний знак',
    addWatermarkAsText: 'Додати як текст',
    padding: 'Відступ',
    paddings: 'Відступи',
    shadow: 'Тінь',
    horizontal: 'Горизонтальний',
    vertical: 'Вертикальний',
    blur: 'Розмиття',
    opacity: 'Непрозорість',
    transparency: 'Прозорість',
    position: 'Позиція',
    stroke: 'Обведення',
    saveAsModalTitle: 'Зберегти як',
    extension: 'Розширення',
    format: 'Формат',
    nameIsRequired: 'Назва обов\'язкова.',
    quality: 'Якість',
    imageDimensionsHoverTitle: 'Розмір збереженого зображення (ширина x висота)',
    cropSizeLowerThanResizedWarning:
        'Зверніть увагу, що вибрана область обрізки менша за застосоване зміщення, що може призвести до зниження якості',
    actualSize: 'Фактичний розмір (100%)',
    fitSize: 'Підібрати розмір',
    addImageTitle: 'Виберіть зображення для додавання...',
    mutualizedFailedToLoadImg: 'Не вдалося завантажити зображення.',
    tabsMenu: 'Меню',
    download: 'Завантажити',
    width: 'Ширина',
    height: 'Висота',
    plus: '+',
    cropItemNoEffect: 'Немає попереднього перегляду для цього елемента обрізки',
};
