import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Breakpoint } from '../../../../utils/types/css';
export var defaultBreakpoints = {
  keys: [Breakpoint.Xs, Breakpoint.Sm, Breakpoint.Md, Breakpoint.Lg, Breakpoint.Xl],
  values: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Breakpoint.Xs, 0), Breakpoint.Sm, 576), Breakpoint.Md, 768), Breakpoint.Lg, 1084), Breakpoint.Xl, 1342), Breakpoint.Xxl, 1600), Breakpoint.Xxxl, 1920),
  classes: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Breakpoint.Xs, '.sfx-breakpoint-xs'), Breakpoint.Sm, '.sfx-breakpoint-sm'), Breakpoint.Md, '.sfx-breakpoint-md'), Breakpoint.Lg, '.sfx-breakpoint-lg'), Breakpoint.Xl, '.sfx-breakpoint-xl'), Breakpoint.Xxl, '.sfx-breakpoint-xxl'), Breakpoint.Xxxl, '.sfx-breakpoint-xxxl'),
  getBreakpointClass: function getBreakpointClass() {
    return '';
  },
  up: function up() {
    return '';
  },
  down: function down() {
    return '';
  },
  between: function between() {
    return '';
  },
  only: function only() {
    return '';
  }
};