import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Accordion, ButtonGroup, Button } from 'react-bootstrap'
import {FormMediaSelectInput, Media} from "@ova-studio/react-media-library";
import image from './images/slider.jpg'

type SliderBlockItem = {
    title: string,
    description: string,
    bgMediaId: Media['id'],
    mediaId: Media['id'],
    primaryButtonText: string | null,
    primaryButtonLink: string | null,
    secondaryButtonText: string | null,
    secondaryButtonLink: string | null,
}

const defaultSliderBlockItem: SliderBlockItem = {
    title: '',
    description: '',
    bgMediaId: 0,
    mediaId: 0,
    primaryButtonText: null,
    primaryButtonLink: null,
    secondaryButtonText: null,
    secondaryButtonLink: null,
}

type SliderBlockData = {
    items: SliderBlockItem[],
}

const isSliderBlockItem = (data: unknown): data is SliderBlockItem => {
    return typeof data === 'object' && data !== null
        && 'title' in data
        && 'description' in data
        && 'bgMediaId' in data
        && 'mediaId' in data
        && 'primaryButtonText' in data
        && 'primaryButtonLink' in data
        && 'secondaryButtonText' in data
        && 'secondaryButtonLink' in data;
}

const isSliderBlockData = (data: unknown): data is SliderBlockData => {
    return typeof data === 'object' && data !== null
        && 'items' in data
        && Array.isArray(data.items)
        && data.items.every(isSliderBlockItem);
}

const sliderBlockDataValidator = async (data: unknown) => {
    if (!isSliderBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Слайдер"');
    }
}

type SliderBlockFormRowProps = {
    idx: number,
    handleRemove: () => void,
    handleUp?: () => void,
    handleDown?: () => void,
}

const SliderBlockFormRow = ({ idx, handleUp, handleDown, handleRemove } : SliderBlockFormRowProps) => {
    return (
        <Accordion.Item eventKey={idx.toString()}>
            <Accordion.Header>
                Пункт #{idx}

                <div className='ms-auto'>
                    <ButtonGroup>
                        <Button variant='outline-secondary' onClick={handleUp} className='mdi mdi-arrow-up-bold' />
                        <Button variant='outline-secondary' onClick={handleDown} className='mdi mdi-arrow-down-bold' />
                    </ButtonGroup>

                    <Button variant='outline-danger' onClick={handleRemove} className='mdi mdi-delete' />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Input
                    type='text'
                    name={`items.${idx}.title`}
                    label='Заголовок'
                />

                <Form.Input
                    type='textarea'
                    name={`items.${idx}.description`}
                    label='Опис'
                />

                <FormMediaSelectInput
                    name={`items.${idx}.bgMediaId`}
                    label='Фон'
                />

                <FormMediaSelectInput
                    name={`items.${idx}.mediaId`}
                    label='Картинка'
                />

                <Form.Input
                    type='text'
                    name={`items.${idx}.primaryButtonText`}
                    label='Текст кнопки 1'
                />

                <Form.Input
                    type='text'
                    name={`items.${idx}.primaryButtonLink`}
                    label='Посилання кнопки 1'
                />

                <Form.Input
                    type='text'
                    name={`items.${idx}.secondaryButtonText`}
                    label='Текст кнопки 2'
                />

                <Form.Input
                    type='text'
                    name={`items.${idx}.secondaryButtonLink`}
                    label='Посилання кнопки 2'
                />
            </Accordion.Body>
        </Accordion.Item>
    )
}

const SliderBlockForm = () => {
    const { fields, append, remove, swap } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Accordion defaultActiveKey='0'>
                {fields.map((field, idx) => (
                    <SliderBlockFormRow
                        key={field.id}
                        idx={idx}
                        handleRemove={() => remove(idx)}
                        handleUp={idx > 0 ? () => swap(idx, idx - 1) : undefined}
                        handleDown={idx < fields.length - 1 ? () => swap(idx, idx + 1) : undefined}
                    />
                ))}
            </Accordion>
            <div className='mt-2'><Button onClick={() => append(defaultSliderBlockItem)}>Додати слайд</Button></div>
        </React.Fragment>
    );
}

const SliderBlock: BlockDefinition<SliderBlockData> = {
    name: 'hs--slider',
    title: 'Блок "Слайдер"',
    image: image,
    initialData: {
        items: [],
    },
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: sliderBlockDataValidator,
    form: SliderBlockForm,
}

export default SliderBlock;

