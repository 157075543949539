import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import normalizeList from "../../../utils/normalizeList";
import {$getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$getSelectionStyleValueForProperty, $patchStyleText} from "@lexical/selection";

const SET_FONT_SIZE_COMMAND : LexicalCommand<string> = createCommand<string>('SET_FONT_SIZE_COMMAND');

const FontSizePlugin = () => {

    const { config: { fontSizes }, topbarRegistry } = useEditorContext();

    if (!fontSizes) return null;

    const [ editor ] = useLexicalComposerContext();

    React.useEffect(() => {
        return topbarRegistry.register({
            section: 'font',
            icon: 'format-size',
            title: 'Розмір',
            defaultLabel: 'Розмір',
            options: normalizeList(fontSizes),
            command: SET_FONT_SIZE_COMMAND,
            isEnableCheck: 'selection',
            currentValue: (selection) => {
                if (!selection) return null;
                return $getSelectionStyleValueForProperty(selection.selection, 'font-size');
            }
        });
    }, [])

    React.useEffect(() => editor.registerCommand(SET_FONT_SIZE_COMMAND, (size) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    'font-size': size,
                });
            }
        });
        return true;
    }, COMMAND_PRIORITY_EDITOR), []);

    return null;
}

export default FontSizePlugin
