import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
export function useMediaQuery(queryInput) {
  var query = queryInput.replace(/^@media( ?)/m, '');
  var _useState = useState(function () {
      return window.matchMedia(query).matches;
    }),
    _useState2 = _slicedToArray(_useState, 2),
    match = _useState2[0],
    setMatch = _useState2[1];
  useEffect(function () {
    var active = true;
    var queryList = window.matchMedia(query);
    var updateMatch = function updateMatch() {
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addEventListener('change', updateMatch);
    return function () {
      active = false;
      queryList.removeEventListener('change', updateMatch);
    };
  }, [query, matchMedia]);
  return match;
}