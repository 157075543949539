import * as React from "react";
import {DOMConversionMap, DOMExportOutput, SerializedLexicalNode, Spread} from "lexical";
import {MediaGallery} from "./decorators/MediaGallery";
import {MediaGalleryEditableData, MediaGalleryEditableItemData} from "./types/MediaGalleryEditableData";
import {DOMConversionFn} from "lexical/LexicalNode";
import {FormValues} from "@ova-studio/react-hyper-admin/dist/Form/types";
import {BaseBlockNode} from "../common/BaseBlockNode";

type MediaGalleryCaptions = Record<number, string>;

type MediaGalleryData = {
    mediaIds: number[],
    captions: MediaGalleryCaptions,
}

type SerializedMediaGalleryNode = Spread<MediaGalleryData, SerializedLexicalNode>

export class MediaGalleryNode extends BaseBlockNode {

    static getType(): string {
        return 'media-gallery';
    }

    static clone(node: MediaGalleryNode): MediaGalleryNode {
        return new MediaGalleryNode(node.__mediaIds, node.__captions, node.__key);
    }

    static importJSON(data: SerializedMediaGalleryNode): MediaGalleryNode {
        return $createMediaGalleryNode(data.mediaIds, data.captions);
    }

    exportJSON(): SerializedMediaGalleryNode {
        return {
            mediaIds: this.__mediaIds,
            captions: this.__captions,
            type: MediaGalleryNode.getType(),
            version: 1,
        };
    }

    __mediaIds: number[];
    __captions: MediaGalleryCaptions;

    constructor(mediaIds: number[], captions?: MediaGalleryCaptions, key?: string) {
        super(key);
        this.__mediaIds = mediaIds;
        this.__captions = captions ?? {};
    }

    createDOM(): HTMLElement {
        return document.createElement('div');
    }

    updateDOM(): false {
        return false;
    }

    static importDOM() : DOMConversionMap | null {
        return {
            ins: (node: Node) => {
                const element = node as HTMLElement;
                if (element.dataset.type !== 'media-gallery') return null;
                return {
                    conversion: $convertMediaGalleryNode,
                    priority: 4,
                }
            }
        }
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('ins');
        element.dataset.type = "media-gallery";
        element.dataset.mediaIds = this.__mediaIds.join(',');
        element.dataset.captions = JSON.stringify(this.__captions);
        return { element }
    }

    private _handleRemove() : void {
        this.remove();
    }

    public handleUpdate(data: FormValues) : void {
        const self = this.getWritable();

        self.__mediaIds = data.media.map((item: MediaGalleryEditableItemData) => item.mediaId);
        self.__captions = data.media
            .reduce((acc: MediaGalleryCaptions, item: MediaGalleryEditableItemData) => ({
                ...acc,
                [item.mediaId]: item.caption,
            }), {} as MediaGalleryCaptions);
    }

    public getEditableData() : MediaGalleryEditableData {
        return {
            media: this.__mediaIds.reduce((acc, id) => [
                ...acc,
                {
                    mediaId: id,
                    caption: this.__captions[id] ?? '',
                }
            ], [] as MediaGalleryEditableData['media']),
        }
    }

    decorate() : React.ReactNode {
        const moveProps = this._getMoveProps();

        return (
            <MediaGallery
                node={this}
                mediaIds={this.__mediaIds}
                captions={this.__captions}
                handleRemove={this._handleRemove.bind(this)}
                {...moveProps}
            />
        )
    }

    isInline() : false {
        return false;
    }
}

export function $createMediaGalleryNode(mediaIds: number[], captions?: Record<number, string>, key?: string): MediaGalleryNode {
    return new MediaGalleryNode(mediaIds, captions, key);
}

export const $convertMediaGalleryNode : DOMConversionFn = (element) => {
    const mediaIds = element.dataset.mediaIds?.split(',').map(id => parseInt(id)) ?? [];
    const captions = JSON.parse(element.dataset.captions ?? '{}');
    return {
        node: $createMediaGalleryNode(mediaIds, captions),
    };
}

export function $isMediaGalleryNode(node: any): node is MediaGalleryNode {
    return node instanceof MediaGalleryNode;
}
