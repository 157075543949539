import * as React from 'react';
import {LinkPlugin as LexicalLinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {AutoLinkPlugin as LexicalAutoLinkPlugin} from "@lexical/react/LexicalAutoLinkPlugin";
import {useModal} from "@ova-studio/react-hyper-admin";
import LinkModal from "./LinkModal";
import {useEditorContext} from "../../EditorContext";
import {COMMAND_PRIORITY_NORMAL, createCommand, LexicalCommand} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {TopbarCommandPayload} from "../../Topbar/types";
import {$isLinkNode} from "@lexical/link";
import getNodeOfTypeFromSelection from "../../../utils/getNodeOfTypeFromSelection";
import {SelectedLink} from "./types";
import validateUrl from "./utils/validateUrl";
import linkMatchers from "./utils/linkMatchers";
import processLinkNode from "./utils/processLinkNode";

const OPEN_LINK_MODAL_COMMAND : LexicalCommand<TopbarCommandPayload<null>> = createCommand<null>('OPEN_LINK_MODAL_COMMAND');

type LinkPluginProps = {
    isAutoLink: boolean;
}

const LinkPlugin = ({ isAutoLink }: LinkPluginProps) => {

    const [ editor ] = useLexicalComposerContext();

    const { topbarRegistry } = useEditorContext();

    const modalState = useModal<SelectedLink>();

    React.useEffect(() => {
        return editor.registerCommand(OPEN_LINK_MODAL_COMMAND, ({ selection }) => {
            if (selection) {
                const link = getNodeOfTypeFromSelection(selection, $isLinkNode);
                if (link) {
                    modalState.setData(processLinkNode(link));
                } else {
                    modalState.setData(null);
                }
            }
            modalState.open();
            return true;
        }, COMMAND_PRIORITY_NORMAL);
    }, [])

    React.useEffect(() => {
        return topbarRegistry.register({
            section: 'format',
            icon: 'link',
            label: 'Посилання',
            command: OPEN_LINK_MODAL_COMMAND,
            wrapWithData: true,
            payload: null,
            isEnableCheck: 'selection',
            enableOnActive: true,
            isActiveCheck: (selection) => !!getNodeOfTypeFromSelection(selection, $isLinkNode),
        });
    }, [])

    return (
        <React.Fragment>
            <LexicalLinkPlugin validateUrl={validateUrl} />
            {isAutoLink && <LexicalAutoLinkPlugin matchers={linkMatchers} />}
            <LinkModal state={modalState} />
        </React.Fragment>
    );
}

export default LinkPlugin
