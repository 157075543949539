import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import getTextFormatInfo from "./textFormatTypes";
import {FORMAT_TEXT_COMMAND} from "lexical";

const TextFormatPlugin = () => {

    const { config: { formats }, topbarRegistry } = useEditorContext();

    if (!formats) {
        return;
    }

    React.useEffect(() => {
        return topbarRegistry.registerMultiple(
            formats.map((type) => ({
                ...getTextFormatInfo(type),
                section: 'text-format',
                command: FORMAT_TEXT_COMMAND,
                payload: type,
                isActiveCheck: (selection) => {
                    return !!selection && selection.selection.hasFormat(type);
                }
            }))
        )
    }, [])

    return null;
}

export default TextFormatPlugin
