export default function valueOrDefault<TDef extends string|null>(value: unknown, defaultValue: TDef): string|TDef {
    if (typeof value !== 'string') {
        return defaultValue;
    }

    if (value.trim().length === 0) {
        return defaultValue;
    }

    return value;
}
