import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Button } from 'react-bootstrap'
import image from './images/workflow.jpg'

type WorkBlockData = {
    title: string,
    items: string[],
}

const isWorkBlockData = (data: unknown): data is WorkBlockData => {
    return typeof data === 'object' && data !== null && 'title' in data && 'items' in data && Array.isArray(data.items) && data.items.every(item => typeof item === 'string');
}

const workBlockDataValidator = async (data: unknown) => {
    if (!isWorkBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Як ми працюємо"');
    }
}

const WorkBlockForm = () => {
    const { fields, append, remove } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Form.Input
                type='text'
                name='title'
                label='Заголовок'
            />

            {fields.map((field, idx) => (
                <Form.Input
                    key={field.id}
                    type='text'
                    name={`items.${idx}`}
                    label={`Пункт #${idx + 1}`}
                    append={<Button variant='link' onClick={() => remove(idx)} className='mdi mdi-delete link-danger p-0' />}
                />
            ))}

            <Button variant='outline-secondary' onClick={() => append('')} className='mdi mdi-plus'> Додати пункт</Button>
        </React.Fragment>
    )
}

const WorkBlock : BlockDefinition<WorkBlockData> = {
    name: 'hs--work',
    image: image,
    title: 'Блок "Як ми працюємо"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    form: WorkBlockForm,
    validator: workBlockDataValidator,
    initialData: {
        title: 'Lorem ipsum українською',
        items: [
            'Постійне вдосконалення',
            'Збільшення асортименту товарів',
            'Вигідні технології та підтримка топ-менеджерів',
            'Визнання і провідну роль на світовому ринку',
        ],
    },
}

export default WorkBlock;