import {App, useAppService} from "@ova-studio/react-hyper-admin";
import {isMediaLibraryConfigData, MediaLibraryConfigData} from "../types/MediaLibraryConfig";
import {Media} from "../types/Media";
import API from "@ova-studio/api-helper";
import {allTypes, MediaType} from "../types/MediaType";
import UploadService from "./UploadService";
import FolderManager from "./FolderManager";
import {FolderModalState} from "../types/MediaFolder";
import MediaManager from "./MediaManager";
import {DeleteModalState} from "../modals/DeleteModal";
import {MediaModalState} from "../modals/MediaEditModal";
import {UploadLinkModalState} from "../modals/UploadLinkModal";
import {MediaImageEditorModalState} from "../modals/MediaEditorModal";

export type ModalsStates = {
    folderEditModal: FolderModalState,
    uploadLinkModal: UploadLinkModalState,
    mediaEditModal: MediaModalState,
    mediaImageEditorModal: MediaImageEditorModalState,
    deleteModal: DeleteModalState,
}

export default class MediaLibraryService {

    public readonly app: App;

    private readonly _config: MediaLibraryConfigData;

    public readonly upload: UploadService;

    public readonly folderManager: FolderManager;
    public readonly mediaManager: MediaManager;
    private _states: ModalsStates|undefined = undefined;

    constructor(app: App) {
        this.app = app;
        this._config = this._resolveConfig();

        this.upload = new UploadService(this);
        this.folderManager = new FolderManager(this);
        this.mediaManager = new MediaManager(this);

        this.app.services.register('ova-media-library', this);
    }

    public get currentContext() : string|null {
        const pageContext = this.app.pageContext.getData();
        if (!pageContext) return null;

        if (pageContext.data.mediaContext && typeof pageContext.data.mediaContext === 'string' && pageContext.data.mediaContext.length > 0) {
            return pageContext.data.mediaContext;
        }

        if (pageContext.data.id) {
            return `page-${pageContext.type}-${pageContext.data.id}`;
        }

        return `page-${pageContext.type}`;
    }

    public setStates(states: ModalsStates) {
        this._states = states;
    }

    public get states() : ModalsStates|undefined {
        return this._states;
    }

    private _resolveConfig() : MediaLibraryConfigData {
        const config = this.app.config.external?.media;

        if (!isMediaLibraryConfigData(config)) {
            throw new Error('Invalid media library config');
        }

        return config;
    }

    public getEndpoint(path: string) : string {
        return `${this._config.endpoint}/${path}`;
    }

    public async loadMedia(id: number) : Promise<Media> {
        return await API.getData<Media>(this.getEndpoint(`media/${id}`));
    }

    public async loadMediaList(ids: number[]) : Promise<Media[]> {
        return await API.getData<Media[]>(this.getEndpoint('media/multiple'), { ids });
    }

    public getAllowedTypes(types?: MediaType[]) : MediaType[] {
        const allowedTypes = this._config.types ?? allTypes;

        if (!types) {
            return allowedTypes;
        }

        return types.filter(type => allowedTypes.includes(type));
    }
}

export const isMediaLibraryService = (service: any) : service is MediaLibraryService => {
    return service instanceof MediaLibraryService;
}

export const useMediaLibraryService = () : MediaLibraryService => {
    return useAppService('ova-media-library', isMediaLibraryService);
}
