import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { Color as PaletteColor } from '@scaleflex/ui/utils/types/palette';
import { Status } from '../popup-status/types';
export var popupContentMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Status.Success, function (_ref) {
  var palette = _ref.theme.palette;
  return css(["background-color:", ";"], palette[PaletteColor.BackgroundGreen]);
}), Status.Info, function (_ref2) {
  var palette = _ref2.theme.palette;
  return css(["background-color:", ";"], palette[PaletteColor.BackgroundBlue]);
}), Status.Error, function (_ref3) {
  var palette = _ref3.theme.palette;
  return css(["background-color:", ";"], palette[PaletteColor.BackgroundRed]);
}), Status.Warning, function (_ref4) {
  var palette = _ref4.theme.palette;
  return css(["background-color:", ";"], palette[PaletteColor.BackgroundOrange]);
});