import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Accordion, ButtonGroup, Button } from 'react-bootstrap'
import {svgDefaultIcon} from "./svgDefaultIcon";
import image from './images/services.jpg'

type ServicesBlockItem = {
    icon: string,
    titleLink: string,
    title: string,
    text: string,
    textSecond: string,
}

const defaultServicesBlockItem: ServicesBlockItem = {
    icon: '',
    titleLink: '',
    title: '',
    text: '',
    textSecond: '',
}

type ServicesBlockData = {
    title: string,
    items: ServicesBlockItem[],
}

const isServicesBlockItem = (data: unknown): data is ServicesBlockItem => {
    return typeof data === 'object' && data !== null && 'icon' in data && 'titleLink' in data && 'title' in data && 'text' in data && 'textSecond' in data;
}

const isServicesBlockData = (data: unknown): data is ServicesBlockData => {
    return typeof data === 'object' && data !== null && 'title' in data && 'items' in data && Array.isArray(data.items) && data.items.every(isServicesBlockItem);
}

const servicesBlockDataValidator = async (data: unknown) => {
    if (!isServicesBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Послуги"');
    }
}

type ServicesBlockFormRowProps = {
    idx: number,
    handleRemove: () => void,
    handleUp?: () => void,
    handleDown?: () => void,
}

const ServicesBlockFormRow = ({ idx, handleUp, handleDown, handleRemove } : ServicesBlockFormRowProps) => {
    return (
        <Accordion.Item eventKey={idx.toString()}>
            <Accordion.Header>
                Пункт #{idx}

                <div className='ms-auto'>
                    <ButtonGroup>
                        <Button variant='outline-secondary' onClick={handleUp} disabled={!handleUp} className='mdi mdi-arrow-up-bold' />
                        <Button variant='outline-secondary' onClick={handleDown} disabled={!handleDown} className='mdi mdi-arrow-down-bold' />
                    </ButtonGroup>

                    <Button variant='outline-danger' onClick={handleRemove} className='mdi mdi-delete' />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Input
                    type='text'
                    name={`items.${idx}.icon`}
                    label='Іконка'
                    text='В форматі svg'
                />
                <Form.Input
                    type='text'
                    name={`items.${idx}.titleLink`}
                    label='Посилання на заголовок'
                />
                <Form.Input
                    type='text'
                    name={`items.${idx}.title`}
                    label='Заголовок'
                />
                <Form.Input
                    type='textarea'
                    name={`items.${idx}.text`}
                    label='Текст'
                />
                <Form.Input
                    type='textarea'
                    name={`items.${idx}.textSecond`}
                    label='Додатковий текст (зелений)'
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

const ServicesBlockForm = () => {
    const { fields, append, remove, swap } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Form.Input
                type='text'
                name='title'
                label='Заголовок'
            />
            <Accordion defaultActiveKey='0'>
                {fields.map((field, idx) => (
                    <ServicesBlockFormRow
                        key={field.id}
                        idx={idx}
                        handleRemove={() => remove(idx)}
                        handleUp={idx > 0 ? () => swap(idx, idx - 1) : undefined}
                        handleDown={idx < fields.length - 1 ? () => swap(idx, idx + 1) : undefined}
                    />
                ))}
            </Accordion>
            <div className='mt-2'><Button onClick={() => append(defaultServicesBlockItem)}>Додати</Button></div>
        </React.Fragment>
    );
}

const ServicesBlock : BlockDefinition = {
    name: 'services',
    title: 'Блок "Послуги"',
    image: image,
    renderer: EditorBlockServerRenderer,
    form: ServicesBlockForm,
    validator: servicesBlockDataValidator,
    initialData: {
        title: 'Lorem ipsum українською',
        items: [
            { icon: svgDefaultIcon, titleLink: '#', title: 'Постійне вдосконалення', text: 'Постійне вдосконалення, збільшення асортименту товарів, вигідні технології та підтримка топ-менеджерів забезпечили підприємству визнання і провідну роль на світовому ринку.', textSecond: 'від 999 грн' },
            { icon: svgDefaultIcon, titleLink: '#', title: 'Впровадження відкриттів', text: 'Ми впевнені, що впровадження відкриттів (створення нових торгових марок) життєво важливе для розвитку, тому ми постійно працюємо над поліпшенням та разом з тим, відкриті до накопичення.', textSecond: 'від 999 грн' },
            { icon: svgDefaultIcon, titleLink: '#', title: 'Широкий вибір', text: 'Незмінно надається широкий вибір продуктів харчування: свіжі овочі та фрукти, інформаційні технології і кімнатні й садові рослини для домашніх улюбленців і киян.', textSecond: 'від 999 грн' },
            { icon: svgDefaultIcon, titleLink: '#', title: 'Широкий спектр послуг', text: 'Одна з провідних міжнародних організацій здійснює діяльність за наступними напрямками: мобільний голосовий зв\'язок, телекомунікації та кредити для малого та середнього бізнесу.', textSecond: 'від 999 грн' },
            { icon: svgDefaultIcon, titleLink: '#', title: 'Оригінальні технології', text: 'На постійній основі товариство застосовує оригінальні фінансово відповідальні технології бізнесу, безпрограшних домовленостей та зберігання даних.', textSecond: 'від 999 грн' },
            { icon: svgDefaultIcon, titleLink: '#', title: 'Хочемо вражати', text: 'стильних особистостей якістю неперервної інтеграції і прагнемо розвивати послуги доставки кореспонденції, закупівлю-продаж і виготовлення ключів разом із акціонерами.', textSecond: 'від 999 грн' },
        ],
    },
    formSize: 'lg',
    exportMode: 'insert',
}

export default ServicesBlock;