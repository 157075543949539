export type NormalizedItem = {
    label: string,
    value: string,
}

const normalizeList = (list: string[]|Record<string, string>) : NormalizedItem[]  => {
    if (Array.isArray(list)) {
        return list.map((value) => ({ label: value, value }));
    }

    return Object.entries(list).map(([ value, label ]) => ({ label, value }));
}

export default normalizeList;
