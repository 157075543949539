import {JustifiedGalleryOptions} from "./types/JustifiedGalleryOptions";
import {GalleryImage} from "./types/GalleryImage";
import defaultOptions from "./data/defaultOptions";
import justifiedLayout from "justified-layout";
import checkVisibility from "./utils/checkVisibility";

export default class JustifiedGallery {

    private static _instances: JustifiedGallery[] = [];

    public static updateAll() {
        this._instances.forEach(i => i.justify(true));
    }

    private _isJustified = false;

    private readonly _element: HTMLDivElement;
    private readonly _options: JustifiedGalleryOptions;
    private readonly _images: GalleryImage[] = [];

    constructor(element: HTMLDivElement, options?: Partial<JustifiedGalleryOptions>) {
        this._element = element;
        this._options = { ...defaultOptions, ...(options ?? {}) };

        JustifiedGallery._instances.push(this);

        this._resolveImages();
        this._initImageLoadListener();
    }

    private _initImageLoadListener() {
        const image = this._images[0] ?? null;
        if (!image) return;

        if (image.image.complete) {
            console.log('Image is already loaded');
            this.justify();
        }

        image.image.addEventListener('load', () => {
            console.log('Image loaded');
            this.justify();
        }, { once: true });
    }

    private _addImage(element: HTMLElement) {
        const image = element.querySelector<HTMLImageElement>(this._options.imageSelector);
        if (!image || !image.style.aspectRatio) return;

        const aspectRatio = parseFloat(image.style.aspectRatio);
        this._images.push({ element, image, aspectRatio });
    }

    private _resolveImages() {
        this._element.querySelectorAll<HTMLImageElement>(this._options.itemSelector)
            .forEach(i => this._addImage(i));
    }

    public justify(force: boolean = false) {
        this._options.onBeforeJustify?.();

        if (!force && this._isJustified) return;
        if (this._images.length === 0) return;
        if (!checkVisibility(this._element)) return;

        const ratios = this._images.map(i => i.aspectRatio);

        const options : Parameters<typeof justifiedLayout>[1] = {
            containerWidth: this._element.clientWidth,
            boxSpacing: this._options.gutter,
            widowLayoutStyle: this._options.lastRow,
            targetRowHeight: this._options.rowHeight,
            targetRowHeightTolerance: this._options.rowHeightTolerance,
        }

        const geometry = justifiedLayout(ratios, options);

        this._element.style.height = `${geometry.containerHeight}px`;
        this._element.style.position = 'relative';
        this._element.style.display = 'block';

        this._images.forEach((image, idx) => {
            const box = geometry.boxes[idx];
            image.element.style.position = 'absolute';
            image.element.style.width = `${box.width}px`;
            image.element.style.height = `${box.height}px`;
            image.element.style.top = `${box.top}px`;
            image.element.style.left = `${box.left}px`;
        });

        this._isJustified = true;
        this._options.onJustify?.();
    }


}

const handleEvent = JustifiedGallery.updateAll.bind(JustifiedGallery);

window.addEventListener('resize', handleEvent);
window.addEventListener('orientationchange', handleEvent);
window.addEventListener('load', handleEvent);
