import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    LexicalCommand,
    LexicalEditor,
    RangeSelection
} from "lexical";
import {$createHeadingNode, $createQuoteNode} from "@lexical/rich-text";
import {$setBlocksType} from '@lexical/selection';
import {
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListType,
    REMOVE_LIST_COMMAND
} from "@lexical/list";
import {BlockType} from "./blockTypes";

function forRangeSelection(callback : (selection : RangeSelection) => void) {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
        callback(selection);
    }
}

function getListCommand(type : ListType) : LexicalCommand<void> {
    switch (type) {
        case "bullet": return INSERT_UNORDERED_LIST_COMMAND;
        case "check": return INSERT_CHECK_LIST_COMMAND;
        case "number": return INSERT_ORDERED_LIST_COMMAND;
    }
}

export default function formatBlock(editor : LexicalEditor, currentType : BlockType, newType : BlockType) : void {
    if (currentType === 'custom' || newType === 'custom') {
        return;
    }

    editor.update(() => {
        switch (newType) {
            case 'paragraph':
                forRangeSelection((selection) => {
                    $setBlocksType(selection, () => $createParagraphNode());
                });
                return;
            case "h1":
            case "h2":
            case "h3":
            case "h4":
            case "h5":
            case "h6":
                if (currentType !== newType) {
                    forRangeSelection((selection) => {
                        $setBlocksType(selection, () => $createHeadingNode(newType));
                    });
                }
                return;
            case "bullet":
            case "check":
            case "number":
                if (currentType !== newType) {
                    editor.dispatchCommand(getListCommand(newType), undefined);
                } else {
                    editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                }
                return;
            case "quote":
                if (currentType !== newType) {
                    forRangeSelection((selection) => {
                        $setBlocksType(selection, () => $createQuoteNode());
                    });
                }
                return;
            default:
                console.warn(`type [${newType}] not found`);
        }
    });
}
