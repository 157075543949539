import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import normalizeList from "../../../utils/normalizeList";
import {$getSelectionStyleValueForProperty, $patchStyleText} from "@lexical/selection";
import {$getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from "lexical";

const SET_FONT_FAMILY_COMMAND : LexicalCommand<string> = createCommand<string>('SET_FONT_FAMILY_COMMAND');

const FontFamilyPlugin = () => {
    const { config: { fonts }, topbarRegistry } = useEditorContext();

    if (!fonts) return null;

    const [ editor ] = useLexicalComposerContext();

    React.useEffect(() => {
        return topbarRegistry.register({
            section: 'font',
            icon: 'format-font',
            title: 'Шрифт',
            defaultLabel: 'Шрифт',
            options: normalizeList(fonts),
            command: SET_FONT_FAMILY_COMMAND,
            isEnableCheck: 'selection',
            currentValue: (selection) => {
                if (!selection) return null;
                return $getSelectionStyleValueForProperty(selection.selection, 'font-family');
            }
        });
    }, [])

    React.useEffect(() => editor.registerCommand(SET_FONT_FAMILY_COMMAND, (size) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $patchStyleText(selection, {
                    'font-family': size,
                });
            }
        });
        return true;
    }, COMMAND_PRIORITY_EDITOR), []);

    return null;
}

export default FontFamilyPlugin
