import * as Y from "yjs";
import {HocuspocusProvider} from "@hocuspocus/provider";
import {AuthUserData} from "@ova-studio/react-hyper-admin";

export type CollaborationConfig = {
    appPrefix?: string,
    rtcWsUrl: string,
    baseEndpoint: string,
}

export const isCollaborationConfig = (config: any): config is CollaborationConfig => {
    return typeof config === 'object'
        && typeof config.rtcWsUrl === 'string'
        && typeof config.baseEndpoint === 'string';
}

export type AuthResponse = { token: string };
export const isAuthResponse = (response: any): response is AuthResponse => {
    return typeof response === 'object'
        && typeof response.token === 'string';
}

export enum SaveStatus {
    Unknown = 'unknown',
    Saving = 'saving',
    Success = 'success',
    Error = 'error',
}

export type CollaborationUserData = {
    id: AuthUserData['id'],
    name: AuthUserData['name'],
    username: AuthUserData['username'],
    avatar: AuthUserData['avatar'],
    color: string,
}

export type InternalCollaborationUser = CollaborationUserData & {
    internalId: string,
    isCurrent: boolean,
}

export type AutoSaveStatus = {
    savedAt: string | null,
    status: SaveStatus,
    message?: string,
}

export const isAutoSaveStatus = (status: any): status is AutoSaveStatus => {
    return typeof status === 'object'
        && typeof status.savedAt === 'string'
        && typeof status.status === 'string';
}

export type CollaborationAwareData = {
    users: InternalCollaborationUser[],
    isOnline: boolean,
    autoSave?: AutoSaveStatus,
    isReady: boolean,
}

export type DocumentInitializer = (doc: Y.Doc) => void;

export type CollaborationOptions = {
    documentId: string,
    syncUrl?: string,
    initDoc?: DocumentInitializer,
};

export type CollaborationDocument = {
    name: string,
    doc: Y.Doc,
    provider: HocuspocusProvider,
}

export type OnlineEventListener = (isOnline: boolean) => void;