import * as React from 'react';
import classNames from "classnames";
import TopbarTabs from "./TopbarTabs";
import TopbarBreadcrumbs from "../TopbarBreadcrumbs";
import TopbarRow from "./TopbarRow";
import useExternalStore from "../../../hooks/useExternalStore";
import {useAppNavigation} from "../../../Application";
import SearchDisplay from "../../../AppHelpers/Search/SearchDisplay/SearchDisplay";
import {Button} from "react-bootstrap";

const Topbar = () => {

    const appNavigation = useAppNavigation();

    const { isVisible, buttons } = useExternalStore(appNavigation.Topbar);
    const { isOpen: isMainNavOpen } = useExternalStore(appNavigation.MainNavigation);

    return (
        <div className={classNames('navbar-custom', { 'navbar-hidden': !isVisible })} style={isMainNavOpen ? { zIndex: 90 } : undefined}>
            <div className='navbar-main-row d-flex align-items-center gap-1'>
                <div className='d-md-none topbar-breadcrumbs'>
                    <TopbarBreadcrumbs />
                </div>
                <SearchDisplay />
                {buttons.length > 0 && (
                    <div className='ms-1 d-flex align-content-stretch'>
                        {buttons.map((button, index) => (
                            <Button
                                key={`btn-${index}`}
                                className={`mdi mdi-${button.icon} font-22 p-0 px-1`}
                                onClick={button.onClick}
                                variant={button.variant}
                                title={button.title}
                            />
                        ))}
                    </div>
                )}
            </div>
            <TopbarTabs />
            <TopbarRow />
        </div>
    )
}

export default Topbar;
