import * as React from 'react';
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {LexicalErrorBoundary} from "@lexical/react/LexicalErrorBoundary";
import {useEditorContext} from "../EditorContext";
import EditorFrame from "./EditorFrame";
import BodyStyles from "./BodyStyles";
import {bodyCss} from "../../generated/editor-body";
import {editorBodyCss} from "../../generated/editor-body-styles";

const Body = () => {

    const { setCursorsContainer } = useEditorContext();

    return (
        <EditorFrame>
            <RichTextPlugin
                contentEditable={<ContentEditable className="ova-editor-input content-editor" />}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <style>{editorBodyCss}</style>
            <style>{bodyCss}</style>
            <BodyStyles/>
            <div ref={(ref) => setCursorsContainer(ref)}></div>
        </EditorFrame>
    );
}

export default Body
