import {LexicalCommand} from "lexical";
import {SelectionData} from "../../utils/getSelectionData";

type TopbarItemGlobalOptions = {
    section: string,
}

export type TopbarCommandPayload<BasePayload = any> = {
    data: BasePayload,
    selection: SelectionData|null,
}

export type EnableCheck = ((selection: SelectionData|null) => boolean) | 'text' | 'selection' | 'collapsed-selection';

export type TopbarButton<BasePayload = any, IsWrap = boolean> = {
    label: string,
    icon: string,
    wrapWithData?: IsWrap,
    command: IsWrap extends true ? LexicalCommand<TopbarCommandPayload<BasePayload>> : LexicalCommand<BasePayload>,
    payload: BasePayload,
    isActiveCheck?: (selection: SelectionData|null) => boolean,
    enableOnActive?: boolean,
    isEnableCheck?: EnableCheck,
}

export function isTopbarButton(item: any): item is TopbarButton {
    return typeof item === 'object'
        && item !== null
        && 'label' in item
        && 'icon' in item
        && 'command' in item
        && 'payload' in item;
}

export type TopbarSelectOption = {
    label: string,
    value: string,
}

export type TopbarSelectOptionWithIcon = TopbarSelectOption & {
    icon: string,
}

export function isTopbarSelectOptionWithIcon(item: any): item is TopbarSelectOptionWithIcon {
    return typeof item === 'object'
        && item !== null
        && 'label' in item
        && 'icon' in item
        && 'value' in item;
}

export type TopbarInsertOption<BasePayload = any, IsWrap = boolean> = {
    icon: string,
    label: string,
    wrapWithData?: IsWrap,
    command: IsWrap extends true ? LexicalCommand<TopbarCommandPayload<BasePayload>> : LexicalCommand<BasePayload>,
    payload: BasePayload,
    isActiveCheck?: (selection: SelectionData|null) => boolean,
    enableOnActive?: boolean,
    isEnableCheck?: EnableCheck,
};

export type TopbarSelect<IsWrap = boolean> = {
    title: string,
    defaultLabel: string,
    icon: string,
    options: TopbarSelectOption[] | TopbarSelectOptionWithIcon[],
    wrapWithData?: IsWrap,
    command: IsWrap extends true ? LexicalCommand<TopbarCommandPayload<string>> : LexicalCommand<string>,
    currentValue?: (selection: SelectionData|null) => string|null,
    isEnableCheck?: EnableCheck,
}

export function isTopbarSelect(item: any): item is TopbarSelect {
    return typeof item === 'object'
        && item !== null
        && 'title' in item
        && 'defaultLabel' in item
        && 'icon' in item
        && 'options' in item
        && 'command' in item;
}

export type TopbarDropdownOption = {
    icon?: string,
    label: string,
    onClick: () => void,
}

export type TopbarDropdown = {
    title: string,
    icon: string,
    hideTitle?: boolean,
    dropdownOptions: TopbarDropdownOption[],
}

export function isTopbarDropdown(item: any): item is TopbarDropdown {
    return typeof item === 'object'
        && item !== null
        && 'title' in item
        && 'icon' in item
        && 'dropdownOptions' in item;
}

export type TopbarItem = TopbarItemGlobalOptions & (TopbarButton | TopbarSelect | TopbarDropdown)

export type TopbarButtonInternal = {
    type: 'button',
    label: string,
    icon: string,
    isActive: boolean,
    isDisabled: boolean,
    handleClick: () => void,
}

export function isTopbarButtonInternal(item: any): item is TopbarButtonInternal {
    return typeof item === 'object'
        && item !== null
        && 'type' in item
        && item.type === 'button'
        && 'label' in item
        && 'icon' in item
        && 'isActive' in item
        && 'handleClick' in item;
}

export type TopbarSelectInternal = {
    type: 'select',
    title: string,
    label: string,
    icon: string,
    options: TopbarSelectOption[] | TopbarSelectOptionWithIcon[],
    currentValue: string|null,
    isDisabled: boolean,
    handleSelect: (value: string) => void,
}

export function isTopbarSelectInternal(item: any): item is TopbarSelectInternal {
    return typeof item === 'object'
        && item !== null
        && 'type' in item
        && item.type === 'select'
        && 'label' in item
        && 'icon' in item
        && 'handleSelect' in item;
}

export type TopbarDropdownInternal = {
    type: 'dropdown',
    title: string,
    label?: string,
    icon: string,
    options: TopbarDropdownOption[],
}

export function isTopbarDropdownInternal(item: any): item is TopbarDropdownInternal {
    return typeof item === 'object'
        && item !== null
        && 'type' in item
        && item.type === 'dropdown'
        && 'title' in item
        && 'icon' in item
        && 'options' in item;
}

export type TopbarItemInternal = TopbarButtonInternal | TopbarSelectInternal | TopbarDropdownInternal;

export type TopbarRegistryData = {
    items: Record<string, TopbarItemInternal[]>,
    insert: TopbarButtonInternal[],
};
