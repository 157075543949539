import * as React from "react";
import {MoveProps} from "./BaseBlockNode";
import Icon from "@mdi/react";
import {mdiArrowDown, mdiArrowUp, mdiChevronDownBoxOutline, mdiChevronUpBoxOutline, mdiPlus} from "@mdi/js";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

type MoveButtonsProps = MoveProps;

const MoveButtons = ({ handleMoveUp, handleMoveDown, handleInsertBefore, handleInsertAfter }: MoveButtonsProps) => {

    const [ editor ] = useLexicalComposerContext();

    return (
        <React.Fragment>
            <button onClick={() => handleMoveUp?.(editor)} title='Перемістити вище' disabled={!handleMoveUp}><Icon
                path={mdiChevronUpBoxOutline}/></button>
            <button onClick={() => handleMoveDown?.(editor)} title='Перемістити нижче' disabled={!handleMoveDown}><Icon
                path={mdiChevronDownBoxOutline}/></button>
            <button onClick={() => handleInsertBefore?.(editor)} title='Додати параграф вище'
                    disabled={!handleInsertBefore}><Icon path={mdiPlus}/><Icon path={mdiArrowUp}/></button>
            <button onClick={() => handleInsertAfter?.(editor)} title='Додати параграф нижче'
                    disabled={!handleInsertAfter}><Icon path={mdiPlus}/><Icon path={mdiArrowDown}/></button>
        </React.Fragment>
    )
}

export default MoveButtons;
