import {ToastCreateFunctionResult} from "@ova-studio/react-hyper-admin";

export enum UploadStatus {
    Uploading = 'uploading',
    Success = 'success',
    Error = 'error',
}

export enum UploadType {
    File = 'file',
    FileGroup = 'file-group',
    Link = 'link',
    LinkGroup = 'link-group',
}

export type UploadItemInfo = {
    type: UploadType,
    name: string,
    size?: number,
}

export type UploadItem = UploadItemInfo & {
    status: UploadStatus,
    progress: number,
    toast: ToastCreateFunctionResult,
    error?: string,
}

export type RequestData = Record<string, any>

export type UploadingFiles = Record<string, UploadItem>
