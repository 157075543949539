import * as React from 'react';
import {isTopbarSelectOptionWithIcon, TopbarSelectInternal} from "../types";
import {Dropdown} from "react-bootstrap";

type ItemBodyProps = {
    icon?: string,
    title: string,
}

const ItemBody = ({ icon, title } : ItemBodyProps) => (
    <div className='d-inline-flex align-items-center lh-sm'>
        {icon && <i className={`mdi mdi-${icon} fs-5 me-1`} />}
        <span>{title}</span>
    </div>
)

type TopbarSelectProps = {
    data: TopbarSelectInternal,
}

const TopbarSelect = ({ data }: TopbarSelectProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant='link' className='link-secondary py-0 px-1 lh-1 arrow-none' title={data.title} disabled={data.isDisabled}>
                <ItemBody icon={data.icon} title={data.label} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {data.options.map((option, i) => (
                    <Dropdown.Item key={i} onClick={() => data.handleSelect(option.value)}>
                        <ItemBody title={option.label} icon={isTopbarSelectOptionWithIcon(option) ? option.icon : undefined} />
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default TopbarSelect
