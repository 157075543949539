import {
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    ElementNode,
    LexicalEditor,
    LexicalNode,
    RangeSelection,
    TextNode
} from "lexical";
import {$findMatchingParent} from "@lexical/utils";
import {getSelectedNode} from "./getSelectedNode";

export type SelectionData = {
    selection: RangeSelection,
    anchorNode: TextNode|ElementNode,
    elementKey: string,
    elementDOM: HTMLElement|null,
    node: TextNode|ElementNode,
    parent: ElementNode|null,
    element: LexicalNode,
    collapsed: boolean,
}

export default function getSelectionData(editor : LexicalEditor) : SelectionData|null {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
        return null;
    }

    const anchorNode = selection.anchor.getNode();

    let element =
        anchorNode.getKey() === 'root'
            ? anchorNode
            : $findMatchingParent(anchorNode, (e) => {
                const parent = e.getParent();
                return parent !== null && $isRootOrShadowRoot(parent);
            });

    if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
    }

    const elementKey = element.getKey();
    const elementDOM = editor.getElementByKey(elementKey);

    const node = getSelectedNode(selection);
    const parent = node.getParent();

    const collapsed = selection.isCollapsed();

    return {
        selection,
        element,
        anchorNode,
        elementDOM,
        elementKey,
        node,
        parent,
        collapsed,
    }
}
