import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import {FormMediaSelectInput, Media} from "@ova-studio/react-media-library";
import image from './images/text-and-image.jpg'

type TextAndImageBlockData = {
    title: string,
    text: string,
    mediaId: Media['id'],
    mediaCaption: string | null,
    isImageRight: boolean,
}

const defaultTextAndImageBlockData: TextAndImageBlockData = {
    title: 'Завжди попереду',
    text: 'Завдяки довершеним та технологічним продуктам та послугам, талановитим співробітникам і відповідальному підходу до інновацій та глобального громадянства, а також кооперації з клієнтами та замовниками, компанія відкриває перед світом нові до найменших дрібниць прораховані можливості. Мета товариства проста: це надання вам новаторства, відкриттів та накопичення. Систематичне вдосконалення, робітнича майстерність, професіоналізм дружньої команди та динамічне впровадження забезпечили товариству визнання і провідну роль на ринку України.',
    mediaId: 0,
    mediaCaption: 'Постійне вдосконалення, збільшення асортименту товарів, вигідні технології та підтримка топ-менеджерів забезпечили підприємству визнання і провідну роль на світовому ринку.',
    isImageRight: false,
}

const isTextAndImageBlockData = (data: unknown): data is TextAndImageBlockData => {
    return typeof data === 'object' && data !== null
        && 'title' in data
        && 'text' in data
        && 'mediaId' in data
        && 'mediaCaption' in data
        && 'isImageRight' in data;
}

const textAndImageBlockDataValidator = async (data: unknown) => {
    if (!isTextAndImageBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Текст та зображення"');
    }
}

const TextAndImageBlockForm = () => {
    return (
        <React.Fragment>
            <Form.Input
                name='title'
                label='Заголовок'
            />

            <Form.Input
                name='text'
                label='Текст'
                type='textarea'
            />

            <FormMediaSelectInput
                name='mediaId'
                label='Зображення'
            />

            <Form.Input
                name='mediaCaption'
                label='Підпис до зображення'
                type='textarea'
            />

            <Form.Check
                name='isImageRight'
                label='Зображення справа'
            />
        </React.Fragment>
    )
}

const TextAndImageBlock : BlockDefinition<TextAndImageBlockData> = {
    name: 'hs--text-and-image',
    image: image,
    title: 'Блок "Текст та зображення"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: textAndImageBlockDataValidator,
    form: TextAndImageBlockForm,
    initialData: defaultTextAndImageBlockData,
}

export default TextAndImageBlock;