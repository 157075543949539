import {EditorConfig} from "./EditorConfig";

const defaultConfig : EditorConfig = {
    quote: true,
    hr: true,
    table: false,

    headings: [ 'h1', 'h2', 'h3', 'h4' ],
    lists: [ 'bullet', 'number' ],
    align: [ 'left', 'center', 'right', 'justify' ],
    formats: [ 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript' ],
    indents: true,
    fonts: [ 'Arial', 'Georgia', 'Times New Roman', 'Verdana' ],
    fontSizes: [ '10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px' ],
    links: 'auto',
    textColor: true,
    bgColor: true,
    symbols: true,
    emoji: true,

    showTopbar: true,
    showStatusBar: true,
}

export default defaultConfig;
