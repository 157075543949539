import * as React from "react";
import FormRtcService from "./FormRtcService";
import {useCollaborationContext} from "../provider";
import {FormRtcOptions} from "./types";
import {AsTopbarRow, useExternalStore, useModal} from "@ova-studio/react-hyper-admin";
import FormOfflineModal from "./FormOfflineModal";
import {Form} from "@ova-studio/react-hyper-admin";
import {CallbackFormProps} from "@ova-studio/react-hyper-admin/dist/Form";

export const CollaborationFormContext = React.createContext<FormRtcService|undefined>(undefined);

export const useCollaborationFormContext = () : FormRtcService => {
    const context = React.useContext(CollaborationFormContext);
    if (!context) {
        throw new Error('CollaborationFormContext must be used within a CollaborationFormContextProvider');
    }
    return context;
}

type CollaborationFormContextProps = FormRtcOptions & {
    formProps: Omit<CallbackFormProps, 'handleSubmit' | 'children'>,
    children: React.ReactNode,
}

const CollaborationFormContextProvider = ({ children, formProps, ...opts }: CollaborationFormContextProps) => {

    const service = useCollaborationContext();

    const offlineModalState = useModal();

    const formService = React.useMemo(() => new FormRtcService(opts, service, { offline: offlineModalState }), []);

    const { isOfflineMode } = useExternalStore(formService);

    return (
        <CollaborationFormContext.Provider value={formService}>
            <Form.Callback handleSubmit={formService.handleSubmit.bind(formService)} {...formProps}>
                {children}
            </Form.Callback>

            <FormOfflineModal
                state={offlineModalState}
                handleEnableOffline={formService.hasOfflineMode ? formService.enableOfflineMode.bind(formService) : undefined}
            />

            {isOfflineMode && (
                <AsTopbarRow desktop={true} mobile={true} fullSize={true}>
                    <div className='p-1 px-lg-2 bg-danger text-bg-danger text-center'>
                        Форма знаходиться в офлайн режимі
                    </div>
                </AsTopbarRow>
            )}
        </CollaborationFormContext.Provider>
    )
}

export default CollaborationFormContextProvider;