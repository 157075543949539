import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import {
    $createTextNode,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand
} from "lexical";
import {useModal} from "@ova-studio/react-hyper-admin";
import EmojiPickerModal from "./EmojiPickerModal";
import {mergeRegister} from "@lexical/utils";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {EmojiClickData} from "emoji-picker-react";

const INSERT_EMOTICON_COMMAND : LexicalCommand<void> = createCommand('INSERT_EMOTICON_COMMAND');

const EmojiPlugin = () => {

    const [ editor ] = useLexicalComposerContext();

    const { topbarRegistry } = useEditorContext();

    const modalState = useModal();

    const handleInsertEmoji = (data: EmojiClickData) => {
        editor.update(() => {
            const selection = $getSelection();

            if (!$isRangeSelection(selection) || data == null) {
                return;
            }

            selection.insertNodes([$createTextNode(data.emoji)]);
            modalState.close();
        });
    }

    React.useEffect(() => {
        return mergeRegister(
            topbarRegistry.register({
                section: 'format',
                icon: 'emoticon',
                label: 'Emoji',
                command: INSERT_EMOTICON_COMMAND,
                payload: undefined,
                isEnableCheck: 'collapsed-selection',
            }),
            editor.registerCommand(
                INSERT_EMOTICON_COMMAND,
                () => {
                    modalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            )
        );
    }, []);

    return (
        <EmojiPickerModal state={modalState} handleSelect={handleInsertEmoji} />
    );
}

export default EmojiPlugin
