import * as React from 'react';
import {Form, Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {BlockData, BlockDefinition} from "./types";
import {CustomBlockNode} from "./CustomBlockNode";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {CREATE_BLOCK_COMMAND, UPDATE_BLOCK_COMMAND} from "./commands";

export type BlockFormData<T extends BlockData> = {
    block: BlockDefinition<T>,
    data?: T,
    node?: CustomBlockNode,
}

type BlockFormProps<T extends BlockData> = {
    state: UseModalReturn<BlockFormData<T>>,
}

const BlockForm = <T extends BlockData>({ state }: BlockFormProps<T>) => {

    const [ editor ] = useLexicalComposerContext();

    if (!state.data) {
        return null;
    }

    const { block, data, node } = state.data;

    const handleSubmit = (formData: unknown) => new Promise<void>((resolve, reject) => {
        if (!block.validator) {
            if (node) {
                editor.dispatchCommand(UPDATE_BLOCK_COMMAND, { node, data: formData as T });
            } else {
                editor.dispatchCommand(CREATE_BLOCK_COMMAND, { name: block.name, data: formData as T });
            }
            state.close();
            resolve();
            return;
        }

        block.validator(formData)
            .then(() => {
                if (node) {
                    editor.dispatchCommand(UPDATE_BLOCK_COMMAND, { node, data: formData as T });
                } else {
                    editor.dispatchCommand(CREATE_BLOCK_COMMAND, { name: block.name, data: formData as T });
                }

                state.close();
                resolve();
            })
            .catch((e) => {
                reject(e instanceof Error ? e.message : (typeof e === 'string' ? e : 'Помилка валідації'));
            });
    });

    return (
        <Modal title={data ? `Редагувати блок ${block.title}` : `Додати блок ${block.title}`} state={state} size={block.formSize ?? 'lg'}>
            <Form.Callback handleSubmit={handleSubmit} data={data}>
                <Modal.Body>
                    {block.form && <block.form name={block.name} data={data} />}
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default BlockForm
