import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Accordion, ButtonGroup, Button } from 'react-bootstrap'
import image from './images/promo.jpg'

type PromoBlockItem = {
    title: string,
    text: string,
}

const defaultPromoBlockItem: PromoBlockItem = {
    title: '',
    text: '',
}

type PromoBlockData = {
    items: PromoBlockItem[],
}

const isPromoBlockItem = (data: unknown): data is PromoBlockItem => {
    return typeof data === 'object' && data !== null && 'title' in data && 'text' in data;
}

const isPromoBlockData = (data: unknown): data is PromoBlockData => {
    return typeof data === 'object' && data !== null && 'items' in data && Array.isArray(data.items) && data.items.every(isPromoBlockItem);
}

const promoBlockDataValidator = async (data: unknown) => {
    if (!isPromoBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Промо"');
    }
}

type PromoBlockFormRowProps = {
    idx: number,
    handleRemove: () => void,
    handleUp?: () => void,
    handleDown?: () => void,
}

const PromoBlockFormRow = ({ idx, handleUp, handleDown, handleRemove } : PromoBlockFormRowProps) => {
    return (
        <Accordion.Item eventKey={idx.toString()}>
            <Accordion.Header>
                Пункт #{idx}

                <div className='ms-auto'>
                    <ButtonGroup>
                        <Button variant='outline-secondary' onClick={handleUp} className='mdi mdi-arrow-up-bold' />
                        <Button variant='outline-secondary' onClick={handleDown} className='mdi mdi-arrow-down-bold' />
                    </ButtonGroup>

                    <Button variant='outline-danger' onClick={handleRemove} className='mdi mdi-delete' />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Form.Input
                    type='text'
                    name={`items.${idx}.title`}
                    label='Заголовок'
                    required
                />

                <Form.Input
                    type='textarea'
                    name={`items.${idx}.text`}
                    label='Текст'
                    required
                />
            </Accordion.Body>
        </Accordion.Item>
    );
}

const PromoBlockForm = () => {
    const { fields, append, remove, swap } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Accordion defaultActiveKey='0'>
                {fields.map((field, idx) => (
                    <PromoBlockFormRow
                        key={field.id}
                        idx={idx}
                        handleRemove={() => remove(idx)}
                        handleUp={idx > 0 ? () => swap(idx, idx - 1) : undefined}
                        handleDown={idx < fields.length - 1 ? () => swap(idx, idx + 1) : undefined}
                    />
                ))}
            </Accordion>
            <div className='mt-2'><Button onClick={() => append(defaultPromoBlockItem)}>Додати</Button></div>
        </React.Fragment>
    );
}

const PromoBlock : BlockDefinition = {
    name: 'hs--promo',
    title: 'Блок "Промо"',
    image: image,
    renderer: EditorBlockServerRenderer,
    form: PromoBlockForm,
    validator: promoBlockDataValidator,
    initialData: {
        items: [
            { title: 'Завжди попереду', text: 'Постійне вдосконалення, збільшення асортименту товарів, вигідні технології та підтримка топ-менеджерів забезпечили підприємству визнання і провідну роль на світовому ринку.' },
            { title: 'Інновації', text: 'Ми впевнені, що впровадження відкриттів (створення нових торгових марок) життєво важливе для розвитку, тому ми постійно працюємо над поліпшенням та разом з тим, відкриті до накопичення.' },
            { title: 'Широкий асортимент', text: 'Незмінно надається широкий вибір продуктів харчування: свіжі овочі та фрукти, інформаційні технології і кімнатні й садові рослини для домашніх улюбленців і киян.' },
        ]
    },
    formSize: 'lg',
    exportMode: 'insert',
}

export default PromoBlock;