import * as React from "react";
import {Form, Modal, UseModalReturn, FormValues} from "@ova-studio/react-hyper-admin";
import {INSERT_TABLE_COMMAND, InsertTableCommandPayload} from "./commands";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

export type TableModalState = UseModalReturn<InsertTableCommandPayload>

type TableModalProps = {
    state: TableModalState;
}

const formDefaults = {
    rows: 5,
    columns: 5,
    includeHeaders: {
        rows: true,
        columns: false,
    }
}

const TableModal = ({ state }: TableModalProps) => {

    const [editor] = useLexicalComposerContext();

    const handleSubmit = async (data: FormValues) => {
        editor.dispatchCommand(INSERT_TABLE_COMMAND, data as InsertTableCommandPayload);
        state.close();
    }

    return (
        <Modal title='Створити таблицю' state={state} >
            <Form.Callback handleSubmit={handleSubmit} data={state.data ?? formDefaults} showSuccess={false}>
                <Modal.Body>
                    <Form.Input name='rows' label='Рядків' type='number' />
                    <Form.Input name='columns' label='Стовпців' type='number' />
                    <Form.Check name='includeHeaders.rows' label='Включити заголовки стовпців' />
                    <Form.Check name='includeHeaders.columns' label='Включити заголовки рядків' />
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit enable>Створити</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    )
}

export default TableModal;
