import {
    DOMConversionMap,
    DOMExportOutput,
    LexicalEditor, LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from "lexical";
import React, {ReactNode} from "react";
import EditorBlockNode from "./EditorBlockNode";
import {renderToString} from "react-dom/server";
import ExportBlockNode from "./ExportBlockNode";
import {BlockData, BlockDefinition} from "./types";
import {DELETE_BLOCK_COMMAND, EDIT_BLOCK_COMMAND} from "./commands";
import {DOMConversionFn} from "lexical/LexicalNode";
import {BaseBlockNode} from "../common/BaseBlockNode";

type SerializedCustomBlockNode = Spread<
    {
        name: string;
        data: any,
    },
    SerializedLexicalNode
>;

export class CustomBlockNode extends BaseBlockNode {

    static blocks : BlockDefinition[] = [];

    __name: string;

    __data: BlockData;

    __block: BlockDefinition;

    static getType(): string {
        return 'custom';
    }

    static clone(node: CustomBlockNode): CustomBlockNode {
        return new CustomBlockNode(node.__name, node.__data, node.__key);
    }

    static importJSON(data: SerializedCustomBlockNode): CustomBlockNode {
        return $createCustomBlock(data.name, data.data);
    }

    exportJSON(): SerializedCustomBlockNode {
        return {
            name: this.__name,
            data: this.__data,
            type: CustomBlockNode.getType(),
            version: 1,
        };
    }

    constructor(name: string, data?: BlockData, key?: NodeKey) {
        super(key);
        this.__name = name;

        this.__block = this._resolveBlock();
        this.__data = data ?? {};
    }

    private _resolveBlock() : BlockDefinition {
        const block = CustomBlockNode.blocks.find(b => b.name === this.__name);
        if (!block) {
            throw new Error(`Block ${this.__name} not found`);
        }
        return block;
    }

    createDOM(): HTMLElement {
        const element = document.createElement('div');
        element.className = 'ova-editor-block';
        return element;
    }

    public getBlockDefinition() : BlockDefinition {
        return this.__block;
    }

    public getData() : BlockData {
        return this.__data;
    }

    updateData(data: BlockData) {
        const self = this.getWritable();
        self.__data = data;
    }

    handleDelete() {
        this.remove();
    }

    updateDOM(): false {
        return false;
    }

    isInline() : false {
        return false;
    }

    private _handleEdit(editor: LexicalEditor) {
        editor.dispatchCommand(EDIT_BLOCK_COMMAND, { node: this });
    }

    private _handleDelete(editor: LexicalEditor) {
        editor.dispatchCommand(DELETE_BLOCK_COMMAND, { node: this });
    }

    decorate(): ReactNode {
        const moveProps = this._getMoveProps();

        return (
            <EditorBlockNode
                data={this.__data}
                block={this.__block}
                handleEdit={this.__block.form ? this._handleEdit.bind(this) : undefined}
                handleDelete={this._handleDelete.bind(this)}
                {...moveProps}
            />
        )
    }

    static importDOM() : DOMConversionMap | null {
        return {
            ins: (node: Node) => {
                const element = node as HTMLElement;
                if (element.dataset.globalType !== 'custom-block') return null;

                const type = element.dataset.type!;
                if (!CustomBlockNode.blocks.some(b => b.name === type)) return null;

                return {
                    conversion: $convertCustomBlock,
                    priority: 4,
                }
            }
        }
    }

    exportDOM(): DOMExportOutput {
        if (this.__block.exportMode === 'render') {
            const code = renderToString(<ExportBlockNode data={this.__data} block={this.__block} />)
            const doc = new DOMParser().parseFromString(code, 'text/html');

            return {
                element: doc.querySelector('body > *') as HTMLElement,
            }
        }

        const element = document.createElement('ins');
        element.dataset.globalType = 'custom-block';
        element.dataset.type = this.__name;
        element.dataset.payload = JSON.stringify(this.__data);

        return { element }
    }

}

export function $createCustomBlock(name: string, data?: any): CustomBlockNode {
    return new CustomBlockNode(name, data);
}

export const $convertCustomBlock : DOMConversionFn = (element) => {
    const name = element.dataset.type!;

    let data : BlockData = {};

    try {
        data = JSON.parse(element.dataset.payload!);
    } catch (e) {
        console.error(e);
    }

    return {
        node: $createCustomBlock(name, data),
    };
}

export function $isCustomBlock(node: LexicalNode | null | undefined): node is CustomBlockNode {
    return node instanceof CustomBlockNode;
}
