import {createCommand, LexicalCommand} from "lexical";

export type InsertTableCommandPayload = {
    rows: number,
    columns: number,
    includeHeaders?: {
        rows: boolean,
        columns: boolean,
    },
}

export const CREATE_TABLE_COMMAND: LexicalCommand<void> = createCommand('CREATE_TABLE_COMMAND');
export const INSERT_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> = createCommand('INSERT_NEW_TABLE_COMMAND');
