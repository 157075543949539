export function asc(a, b) {
  return a - b;
}
export function valueToPercent(value, min, max) {
  return (value - min) * 100 / (max - min);
}
export function percentToValue(percent, min, max) {
  return (max - min) * percent + min;
}
function getDecimalPrecision(num) {
  // This handles the case when num is very small (0.00000001), js will turn this into 1e-8.
  // When num is bigger than 1 or less than -1 it won't get converted to this notation so it's fine.
  if (Math.abs(num) < 1) {
    var parts = num.toExponential().split('e-');
    var matissaDecimalPart = parts[0].split('.')[1];
    return (matissaDecimalPart ? matissaDecimalPart.length : 0) + Number.parseInt(parts[1], 10);
  }
  var decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}
export function roundValueToStep(value, step, min) {
  var nearest = Math.round((value - min) / step) * step + min;
  return Number(nearest.toFixed(getDecimalPrecision(step)));
}
export function setValueIndex(_ref) {
  var values = _ref.values,
    newValue = _ref.newValue,
    index = _ref.index;
  var output = values.slice();
  output[index] = newValue;
  return output.sort(asc);
}
export function findClosest(values, currentValue) {
  var _values$reduce = values.reduce(function (acc, value, index) {
      var distance = Math.abs(currentValue - value);
      if (acc === null || distance < acc.distance || distance === acc.distance) {
        return {
          distance: distance,
          index: index
        };
      }
      return acc;
    }, null),
    closestIndex = _values$reduce.index;
  return closestIndex;
}
export function ownerDocument(node) {
  return node && node.ownerDocument || document;
}
export function clamp(value, min, max) {
  if (value == null) {
    return min;
  }
  return Math.min(Math.max(min, value), max);
}
export function trackFinger(event, touchId) {
  if (touchId.current !== undefined && event.changedTouches) {
    for (var i = 0; i < event.changedTouches.length; i += 1) {
      var touch = event.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY
        };
      }
    }
    return false;
  }
  return {
    x: event.clientX,
    y: event.clientY
  };
}
export var axisProps = {
  horizontal: {
    offset: function offset(percent) {
      return {
        left: "".concat(percent, "%")
      };
    },
    leap: function leap(percent) {
      return {
        width: "".concat(percent, "%")
      };
    }
  },
  'horizontal-reverse': {
    offset: function offset(percent) {
      return {
        right: "".concat(percent, "%")
      };
    },
    leap: function leap(percent) {
      return {
        width: "".concat(percent, "%")
      };
    }
  },
  vertical: {
    offset: function offset(percent) {
      return {
        bottom: "".concat(percent, "%")
      };
    },
    leap: function leap(percent) {
      return {
        height: "".concat(percent, "%")
      };
    }
  }
};
export function focusThumb(_ref2) {
  var sliderRef = _ref2.sliderRef,
    activeIndex = _ref2.activeIndex,
    setActive = _ref2.setActive;
  var doc = ownerDocument(sliderRef.current);
  if (!sliderRef.current.contains(doc.activeElement) || Number(doc.activeElement.getAttribute('data-index')) !== activeIndex) {
    var _sliderRef$current$qu;
    (_sliderRef$current$qu = sliderRef.current.querySelector("[type=\"range\"][data-index=\"".concat(activeIndex, "\"]"))) === null || _sliderRef$current$qu === void 0 || _sliderRef$current$qu.focus();
  }
  if (setActive) {
    setActive(activeIndex);
  }
}