import {SelectionData} from "./getSelectionData";

type Validator<T> = (item: any) => item is T;

export default function getNodeOfTypeFromSelection<T>(selection: SelectionData|null, validator: Validator<T>) : T|null {
    if (selection === null) {
        return null;
    }
    const {node, parent} = selection;
    if (validator(node)) {
        return node;
    }
    if (parent && validator(parent)) {
        return parent;
    }
    return null;
}
