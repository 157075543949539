import * as React from "react";
import {useCollaborationFormContext} from "./CollaborationFormContext";
import {BouncingLoader, Form, useExternalStore} from "@ova-studio/react-hyper-admin";

type CollaborationFormBodyProps = {
    children: React.ReactNode,
}

const CollaborationFormBody = ({ children }: CollaborationFormBodyProps) => {

    const methods = Form.useFormContext();

    const service = useCollaborationFormContext();

    const { isReady } = useExternalStore(service)

    React.useEffect(() => {
        service.init(methods);
        return () => service.destroy();
    }, [])

    if (!isReady) {
        return <BouncingLoader />;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default CollaborationFormBody;