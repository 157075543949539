import {EditorThemeClasses} from "lexical/LexicalEditor";

const CLASS_PREFIX = 'ova-editor-';

const ovaEditorTheme : EditorThemeClasses = {
    // ltr: '',
    text: {
        bold: `${CLASS_PREFIX}-textBold`,
        code: `${CLASS_PREFIX}-textCode`,
        italic: `${CLASS_PREFIX}-textItalic`,
        strikethrough: `${CLASS_PREFIX}-textStrikethrough`,
        subscript: `${CLASS_PREFIX}-textSubscript`,
        superscript: `${CLASS_PREFIX}-textSuperscript`,
        underline: `${CLASS_PREFIX}-textUnderline`,
        underlineStrikethrough: `${CLASS_PREFIX}-textUnderlineStrikethrough`,
    },
};

export default ovaEditorTheme
