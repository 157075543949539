import * as React from 'react';
import {Form, FormGroupContainerBaseProps} from "@ova-studio/react-hyper-admin";
import Editor from "../Editor";
import {EditorConfig} from "../config/EditorConfig";
import {FormDataFormat} from "./types";
import {Controller} from "react-hook-form";
import prepareForSave from "./utils/prepareForSave";
import processEditorInitialData from "./utils/processEditorInitialData";
import {OnChangeProps} from "../config/OnChangeHandler";

type FormEditorBodyProps = {
    value: unknown,
    onChange: (data: unknown) => void,
    config?: EditorConfig,
    format: FormDataFormat,
    children?: React.ReactNode,
}

const FormEditorBody = ({ value, onChange, config, format, children } : FormEditorBodyProps) => {
    const initialData = React.useMemo(() => processEditorInitialData(value), []);

    const handleChange = React.useCallback((data: OnChangeProps) => {
        onChange(prepareForSave(data, format));
    }, []);

    return (
        <Editor
            config={config}
            onChange={handleChange}
            initState={initialData}
            children={children}
        />
    );
}

export type FormEditorProps = FormGroupContainerBaseProps & {
    config?: EditorConfig,
    format: FormDataFormat,
    children?: React.ReactNode,
}

const FormEditor = ({ name, config, format, children, ...props }: FormEditorProps) => {
    return (
        <Form.GroupContainer name={name} {...props}>
            {({ control }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormEditorBody
                            value={value}
                            onChange={onChange}
                            config={config}
                            format={format}
                            children={children}
                        />
                    )}
                />
            )}
        </Form.GroupContainer>
    );
}

export default FormEditor
