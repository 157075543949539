import {JustifiedGalleryOptions} from "../types/JustifiedGalleryOptions";

const defaultOptions : JustifiedGalleryOptions = {
    imageSelector: 'img',
    itemSelector: 'li',
    gutter: 2,
    rowHeight: 120,
    rowHeightTolerance: 0.2,
    lastRow: 'center',
}

export default defaultOptions;
