import {isJsonEditorData} from "../types";
import {InitialEditorStateType} from "@lexical/react/LexicalComposer";
import {$generateNodesFromDOM} from "@lexical/html";
import {defaultInitState} from "../../utils/defaultInitState";
import {$getRoot, $getSelection} from "lexical";

function isJsonString(str: string) : boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function isHTML(str: string) : boolean {
    const dom = new DOMParser().parseFromString(str, 'text/html');
    return dom.body.childNodes.length > 0;
}

function makeInitCallbackFromHTML(str: string) : InitialEditorStateType {
    const dom = new DOMParser().parseFromString(str, 'text/html');
    return (editor) => {
        const nodes = $generateNodesFromDOM(editor, dom);
        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        const selection = $getSelection();
        selection?.insertNodes(nodes);
    }
}

export default function processEditorInitialData(value: unknown) : InitialEditorStateType {

    if (typeof value === 'string') {
        const _value = value.trim();

        if (isJsonString(_value)) {
            return _value;
        }

        if (isHTML(_value)) {
            return makeInitCallbackFromHTML(_value);
        }
    }

    if (isJsonEditorData(value)) {
        if (isJsonString(value.json)) {
            return value.json;
        }

        if (isHTML(value.html)) {
            return makeInitCallbackFromHTML(value.html);
        }
    }

    return defaultInitState;
}
