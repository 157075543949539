export var ButtonColor = {
  Primary: 'primary',
  Secondary: 'secondary',
  Basic: 'basic',
  LinkPrimary: 'link-primary',
  LinkSecondary: 'link-secondary',
  LinkBasicPrimary: 'link-basic-primary',
  LinkBasicSecondary: 'link-basic-secondary',
  ErrorPrimary: 'error-primary',
  ErrorSecondary: 'error-secondary',
  ErrorBasic: 'error-basic',
  WarningPrimary: 'warning-primary',
  WarningSecondary: 'warning-secondary',
  WarningBasic: 'warning-basic'
};