import * as React from "react";
import Icon from "@mdi/react";
import {mdiLoading} from "@mdi/js";

export type EditorLoaderBlockProps = {
    text?: string,
}

const EditorLoaderBlock = ({ text = 'Завантаження...' }: EditorLoaderBlockProps) => {
    return (
        <div className='ova-editor-loader'>
            <Icon path={mdiLoading} size={1} spin/>
            <p>{text}</p>
        </div>
    )
}

export default EditorLoaderBlock;
