import * as React from 'react';
import {BlockData, BlockDefinition} from "./types";
import {LexicalEditor} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import Icon from "@mdi/react";
import {
    mdiDelete,
    mdiPencil,
} from "@mdi/js";
import {useConfirm} from "@ova-studio/react-hyper-admin";
import MoveButtons from "../common/MoveButtons";
import {MoveProps} from "../common/BaseBlockNode";

type DeleteButtonProps = {
    title: string,
    onClick: () => void,
}

const DeleteButton = ({ title, onClick } : DeleteButtonProps) => {
    const confirm = useConfirm({
        text: `Дійсно видалити блок ${title}?`,
        handleContinue: onClick,
    })

    return (
        <React.Fragment>
            {confirm.ConfirmModal}
            <button onClick={confirm.handleClick}><Icon path={mdiDelete} /></button>
        </React.Fragment>
    )
}

export type BlockNodeProps<T extends BlockData> = {
    block: BlockDefinition<T>,
    data: T,
    handleEdit?: (editor: LexicalEditor) => void,
    handleDelete?: (editor: LexicalEditor) => void,
} & MoveProps

const EditorBlockNode = <T extends BlockData>({ block, data, handleDelete, handleEdit, ...moveProps }: BlockNodeProps<T>) => {

    const [ editor ] = useLexicalComposerContext();

    return (
        <React.Fragment>
            <div className='ova-editor-block-actions'>
                <MoveButtons {...moveProps} />
                {handleEdit && <button onClick={() => handleEdit(editor)} title='Редагувати блок'><Icon path={mdiPencil}/></button>}
                {handleDelete && <DeleteButton title={block.title} onClick={() => handleDelete(editor)}/>}
            </div>
            <block.renderer name={block.name} data={data}/>
        </React.Fragment>
    );
}

export default EditorBlockNode
