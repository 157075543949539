import * as React from 'react';
import {Await, useAsyncError} from "react-router-dom";
import EditorErrorBlock from "./EditorErrorBlock";
import EditorLoaderBlock from "./EditorLoaderBlock";

const DefaultError = () => {
    useAsyncError();
    return <EditorErrorBlock txt='Помилка завантаження' />
}

export type EditorAwaitBlockProps<T> = {
    resolve: Promise<T>,
    children: (data: T) => React.ReactNode,
    loaderText?: string,
}

const EditorAwaitBlock = <T extends unknown>({ resolve, children, loaderText }: EditorAwaitBlockProps<T>) => {
    return (
        <React.Suspense fallback={<EditorLoaderBlock text={loaderText} />}>
            <Await
                resolve={resolve}
                children={(data: T) => data ? children(data) : <DefaultError />}
                errorElement={<DefaultError />}
            />
        </React.Suspense>
    );
}

export default EditorAwaitBlock
