import * as React from 'react';
import {IframeHTMLAttributes} from 'react';
import {createPortal} from "react-dom";
import {useEditorContext} from "../EditorContext";

type EditorFrameProps = {
    children: React.ReactNode,
} & IframeHTMLAttributes<HTMLIFrameElement>

const EditorFrame = ({ children, ...props }: EditorFrameProps) => {

    const { contentRef, setContentRef } = useEditorContext();

    const mountNode = contentRef?.contentWindow?.document?.body;

    React.useEffect(() => {
        if (!mountNode) return;

        const _window = contentRef?.contentWindow!;
        const _document = _window?.document!;

        const handleClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;

            if (target === mountNode) {
                const input = mountNode.querySelector<HTMLDivElement>('.ova-editor-input');

                if (input) {
                    const range = _document.createRange();
                    range.setStart(input.childNodes[input.childElementCount - 1], 1);
                    range.collapse(true);

                    const selection = _window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }

                return;
            }

            if (target.tagName === 'A') {
                e.preventDefault();
                const link = target.getAttribute('href');

                if (link?.startsWith('http')) {
                    _window.open(link, '_blank');
                }
            }
        }

        mountNode.addEventListener('click', handleClick);

        const followCursor = () => {
            const selection = _window.getSelection();
            if (!selection || !selection.isCollapsed) return;

            try {
                const range = selection?.getRangeAt(0);

                const block = range.startContainer;
                if (!(block instanceof HTMLElement)) return;

                if (block.offsetTop < 0 || block.offsetTop > _window.innerHeight) {
                    _window.scroll({
                        top: block.offsetTop,
                        behavior: 'smooth',
                    })
                }
            } catch (e) {
                return;
            }
        }

        _document.addEventListener("selectionchange", followCursor);

        return () => {
            mountNode.removeEventListener('click', handleClick);
            _document.removeEventListener("selectionchange", followCursor);
        }
    }, [mountNode]);

    return (
        <iframe className='ova-editor-body' {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    );
}

export default EditorFrame
