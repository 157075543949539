import * as React from "react";
import {Button, ButtonProps} from "react-bootstrap";

export type IconButtonProps = {
    type?: ButtonProps['type'],
    variant?: ButtonProps['variant'],
    size?: ButtonProps['size'],
    className?: string,
    icon?: string,
    isLoading?: boolean,
    loadingIcon?: string,
    disabled?: boolean,
    children?: React.ReactNode,
}

const IconButton = ({ type = 'button', variant, size, className, icon, isLoading, loadingIcon, disabled, children }: IconButtonProps) => {

    const activeIcon = React.useMemo<string|null>(() => {
        if (isLoading) {
            return loadingIcon ?? 'mdi mdi-loading mdi-spin';
        }

        if (icon) {
            return `mdi mdi-${icon}`;
        }

        return null;
    }, [ icon, isLoading ]);

    return (
        <Button
            type={type}
            disabled={disabled || isLoading}
            variant={variant}
            size={size}
            className={className}
        >
            {activeIcon && <i className={`${activeIcon} me-2`} />}
            {children}
        </Button>
    )
}

export default IconButton;