import * as React from 'react';
import {useEditorConfig} from "../EditorContext";
import API from "@ova-studio/api-helper";

type ManifestFileData = {
    [key: string] : {
        file: string;
    }
}

const BodyStyles = () => {
    const { bodyStyles, bodyStylesFromManifest } = useEditorConfig();

    const [ manifestStyles, setManifestStyles ] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!bodyStylesFromManifest) {
            return;
        }

        Promise.all(
            Object.entries(bodyStylesFromManifest)
                .map(async ([manifestUrl, items]) : Promise<string[]> => {
                    try {
                        const data = await API.getData<ManifestFileData>(manifestUrl);
                        const dir = manifestUrl.split('/').slice(0, -1).join('/');
                        return items.map(item => data[item]?.file)
                            .filter(v => !!v)
                            .map(v => `${dir}/${v}`);
                    } catch (e) {
                        return [];
                    }
                })
        ).then((styles) => {
            setManifestStyles(styles.flat());
        }).catch((e) => {
            console.error(e);
        });
    }, [bodyStylesFromManifest]);

    return (
        <React.Fragment>
            {bodyStyles?.map((style, idx) => <link key={`style-${idx}`} rel='stylesheet' href={style} />)}
            {manifestStyles.map((style, idx) => <link key={`style-manifest-${idx}`} rel='stylesheet' href={style} />)}
        </React.Fragment>
    );
}

export default BodyStyles
