export enum FormDataFormat {
    HTML = 'html',
    JSON = 'json',
    Both = 'both',
}

type JsonEditorData = {
    html: string,
    json: string,
}

export type FormDataValueMap = {
    [FormDataFormat.HTML]: string,
    [FormDataFormat.JSON]: string,
    [FormDataFormat.Both]: JsonEditorData,
}

export type FormDataValue<T extends FormDataFormat> = FormDataValueMap[T];

export function isJsonEditorData(value: unknown) : value is JsonEditorData {
    return typeof value === 'object' && value !== null && 'html' in value && 'json' in value;
}
