import * as React from 'react';
import {Form, Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {FormValues} from "@ova-studio/react-hyper-admin/dist/Form/types";
import {Button} from "react-bootstrap";
import {MediaGalleryNode} from "../MediaGalleryNode";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {Media, useMedia} from "@ova-studio/react-media-library";
import EditorAwaitBlock from "../../../../components/EditorAwaitBlock";
// @ts-ignore
import fallbackImage from "../../../../assets/fallback_media.jpg";

type MediaGalleryItemProps = {
    index: number,
    handleMoveUp?: () => void,
    handleMoveDown?: () => void,
    handleRemove?: () => void,
}

const MediaGalleryItem = ({ index, handleMoveUp, handleMoveDown, handleRemove } : MediaGalleryItemProps) => {

    const mediaId = Form.useWatch({ name: `media.${index}.mediaId` }) as number;

    const media = useMedia(mediaId);

    return (
        <div className='d-flex align-items-center gap-2'>
            <div className='ratio ratio-1x1' style={{ width: 200 }}>
                <EditorAwaitBlock resolve={media}>
                    {(media: Media) => (
                        <img src={media.thumb?.url ?? fallbackImage} alt={media.filename}
                             style={{objectFit: 'cover'}}/>
                    )}
                </EditorAwaitBlock>
            </div>
            <div className='flex-grow-1'>
                <Form.HiddenInput name={`media.${index}.mediaId`} />
                <Form.Input type='textarea' name={`media.${index}.caption`} placeholder='Підпис' groupClass='' rows={7} />
            </div>
            <div className='d-flex flex-column gap-1'>
                <Button variant='link' className='link-secondary p-0 mdi mdi-arrow-up-bold font-22' onClick={handleMoveUp} disabled={!handleMoveUp} />
                <Button variant='link' className='link-secondary p-0 mdi mdi-arrow-down-bold font-22' onClick={handleMoveDown} disabled={!handleMoveDown} />
                <Button variant='link' className='link-danger p-0 mdi mdi-close-circle-outline font-22' onClick={handleRemove} disabled={!handleRemove} />
            </div>
        </div>
    )
}

const MediaGalleryItems = () => {
    const { fields, swap, remove } = Form.useFieldArray({ name: 'media' });

    const cnt = fields.length;

    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <MediaGalleryItem
                    key={field.id}
                    index={index}
                    handleMoveUp={index > 0 ? () => swap(index, index - 1) : undefined}
                    handleMoveDown={index < (cnt - 1) ? () => swap(index, index + 1) : undefined}
                    handleRemove={cnt > 2 ? () => remove(index) : undefined}
                />
            ))}
        </React.Fragment>
    )
}

export type MediaGalleryEditFormState = UseModalReturn<MediaGalleryNode>;

type MediaGalleryEditFormProps = {
    state: MediaGalleryEditFormState,
}

const MediaGalleryEditForm = ({ state }: MediaGalleryEditFormProps) => {

    const [editor] = useLexicalComposerContext();

    const handleSubmit = React.useCallback(async (data: FormValues) => {
        state.close();
        editor.update(() => state.data?.handleUpdate(data));
    }, [editor, state.data]);

    return (
        <Modal title='Редагування галереї' state={state} size='lg'>
            <Form.Callback data={state.data?.getEditableData()} handleSubmit={handleSubmit} showSuccess={false}>
                <Modal.Body>
                    <div className='d-flex flex-column gap-2'>
                        <MediaGalleryItems />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default MediaGalleryEditForm
