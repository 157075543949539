import * as React from 'react';
import {isTopbarButtonInternal, isTopbarDropdownInternal, isTopbarSelectInternal, TopbarItemInternal} from "./types";
import TopbarButton from "./items/TopbarButton";
import TopbarSelect from "./items/TopbarSelect";
import TopbarDropdown from "./items/TopbarDropdown";

type TopbarItemDisplayProps = {
    data: TopbarItemInternal,
}

const TopbarItemDisplay = ({ data }: TopbarItemDisplayProps) => {
    if (isTopbarButtonInternal(data)) {
        return <TopbarButton data={data} />;
    }

    if (isTopbarSelectInternal(data)) {
        return <TopbarSelect data={data} />;
    }

    if (isTopbarDropdownInternal(data)) {
        return <TopbarDropdown data={data} />;
    }

    return null;
}

export default TopbarItemDisplay
