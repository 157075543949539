import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import {BlockType, getAllowedTypesFromConfig, getTypeInfo} from "./blockTypes";
import {COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {TopbarCommandPayload} from "../../Topbar/types";
import formatBlock from "./formatBlock";
import getCurrentTypeFromSelection from "./getCurrentTypeFromSelection";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";

const FORMAT_ELEMENT_COMMAND : LexicalCommand<TopbarCommandPayload<BlockType>> = createCommand('FORMAT_ELEMENT_COMMAND');

const BlockTypePlugin = () => {

    const { config, topbarRegistry } = useEditorContext();

    const [ editor ] = useLexicalComposerContext();

    const types = getAllowedTypesFromConfig(config);

    if (types.length === 0) {
        return null;
    }

    React.useEffect(() => {
        const normalType = getTypeInfo('paragraph');

        return topbarRegistry.register({
            section: 'block-type',
            icon: normalType.icon,
            title: 'Тип блоку',
            options: types.map((value) => ({ ...getTypeInfo(value), value })),
            defaultLabel: normalType.label,
            command: FORMAT_ELEMENT_COMMAND,
            wrapWithData: true,
            currentValue: (selection) => {
                const type = getCurrentTypeFromSelection(selection);
                return type === 'custom' ? 'paragraph' : type;
            },
        })
    }, [])

    React.useEffect(() => {
        return editor.registerCommand(FORMAT_ELEMENT_COMMAND, ({ data: type, selection }) => {
            const currentType = getCurrentTypeFromSelection(selection);
            formatBlock(editor, currentType, type);
            return true;
        }, COMMAND_PRIORITY_EDITOR)
    }, [])

    return (
        <React.Fragment>
            {config.lists && <ListPlugin />}
        </React.Fragment>
    );
}

export default BlockTypePlugin
