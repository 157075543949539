import * as React from "react";
import {
    $deleteTableColumn__EXPERIMENTAL,
    $deleteTableRow__EXPERIMENTAL, $getTableCellNodeFromLexicalNode,
    $insertTableColumn__EXPERIMENTAL,
    $insertTableRow__EXPERIMENTAL, TableCellNode
} from "@lexical/table";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import getSelectionData from "../../../utils/getSelectionData";
import {useEditorContext} from "../../EditorContext";

const TableActionPlugin = () => {
    const [editor] = useLexicalComposerContext();
    const { topbarRegistry } = useEditorContext();
    const [tableCellNode, setTableMenuCellNode] = React.useState<TableCellNode | null>(null);

    const insertTableRowAtSelection = React.useCallback((shouldInsertAfter: boolean) => {
        editor.update(() => {
            $insertTableRow__EXPERIMENTAL(shouldInsertAfter);
        });
    }, [editor]);

    const insertTableColumnAtSelection = React.useCallback((shouldInsertAfter: boolean) => {
        editor.update(() => {
            $insertTableColumn__EXPERIMENTAL(shouldInsertAfter);
        });
    }, [editor]);

    const deleteTableRowAtSelection = React.useCallback(() => {
        editor.update(() => {
            $deleteTableRow__EXPERIMENTAL();
        });
    }, [editor]);

    const deleteTableColumnAtSelection = React.useCallback(() => {
        editor.update(() => {
            $deleteTableColumn__EXPERIMENTAL();
        });
    }, [editor]);

    React.useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                const selection = getSelectionData(editor);

                if (selection === null) {
                    setTableMenuCellNode(null);
                    return;
                }

                const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchorNode);

                if (tableCellNodeFromSelection === null) {
                    setTableMenuCellNode(null);
                    return;
                }

                const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey());

                if (tableCellParentNodeDOM === null) {
                    setTableMenuCellNode(null);
                    return;
                }

                setTableMenuCellNode(tableCellNodeFromSelection);
            });
        });
    }, [editor]);

    React.useEffect(() => {
        if (tableCellNode === null) {
            return;
        }

        return topbarRegistry.register({
            section: 'active-selection',
            icon: 'table-cog',
            title: 'Опції таблиці',
            dropdownOptions: [
                {
                    icon: 'table-row-plus-before',
                    label: 'Додати рядок вище',
                    onClick: () => insertTableRowAtSelection(false),
                },
                {
                    icon: 'table-row-plus-after',
                    label: 'Додати рядок нижче',
                    onClick: () => insertTableRowAtSelection(true),
                },
                {
                    icon: 'table-column-plus-before',
                    label: 'Додати стовпець ліворуч',
                    onClick: () => insertTableColumnAtSelection(false),
                },
                {
                    icon: 'table-column-plus-after',
                    label: 'Додати стовпець праворуч',
                    onClick: () => insertTableColumnAtSelection(true),
                },
                {
                    icon: 'table-row-remove',
                    label: 'Видалити рядок',
                    onClick: deleteTableRowAtSelection,
                },
                {
                    icon: 'table-column-remove',
                    label: 'Видалити стовпець',
                    onClick: deleteTableColumnAtSelection,
                }
            ],
        });

    }, [ tableCellNode ]);

    return null;
}

export default TableActionPlugin;
