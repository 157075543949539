import * as React from 'react';
import {BlockData, BlockDefinition} from "./types";

type ExportBlockNodeProps<T extends BlockData> = {
    data: T,
    block: BlockDefinition<T>,
}

const ExportBlockNode = <T extends BlockData>({ block, data }: ExportBlockNodeProps<T>) => {
    return (
        <block.renderer name={block.name} data={data} />
    );
}

export default ExportBlockNode
