import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Button } from 'react-bootstrap'
import image from './images/connect.jpg'

type ConnectBlockData = {
    title: string,
    description: string,
    phones: string[],
}

const isConnectBlockData = (data: unknown): data is ConnectBlockData => {
    return typeof data === 'object' && data !== null && 'title' in data && 'description' in data && 'phones' in data && Array.isArray(data.phones) && data.phones.every((phone) => typeof phone === 'string');
}

const connectBlockDataValidator = async (data: unknown) => {
    if (!isConnectBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Контакти"');
    }
}

const ConnectBlockForm = () => {
    const { fields, append } = Form.useFieldArray({ name: 'phones' });

    return (
        <React.Fragment>
            <Form.Input
                name='title'
                label='Заголовок'
            />

            <Form.Input
                type='textarea'
                name='description'
                label='Опис'
            />

            {fields.map((field, idx) => (
                <Form.Input
                    key={field.id}
                    type='text'
                    name={`phones.${idx}`}
                    label={`Телефон #${idx + 1}`}
                />
            ))}

            <div className='mt-2'><Button onClick={() => append('+38 (050) 123-45-67')}>Додати номер телефону</Button></div>
        </React.Fragment>
    )
}

const ConnectBlock : BlockDefinition<ConnectBlockData> = {
    name: 'hs--connect',
    image: image,
    title: 'Блок "Зв\'яжіться з нами"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: connectBlockDataValidator,
    form: ConnectBlockForm,
    initialData: {
        title: 'Lorem ipsum українською',
        description: 'Упевнене поліпшення, динамічне впровадження, створення оптимальних підходів і технологій та оригінальні ціни забезпечили товариству визнання і провідну роль на ринку України.',
        phones: [
            '+38 (050) 123-45-67',
        ],
    },
}

export default ConnectBlock;