import {AbstractDataStore, useExternalStore} from "@ova-studio/react-hyper-admin";
import MediaLibraryService, {useMediaLibraryService} from "../../services/MediaLibraryService";
import API from "@ova-studio/api-helper";

export type ImageEditorData = {
    watermarks: string[],
}

export class ImageEditorHelper extends AbstractDataStore<ImageEditorData|undefined>{
    private readonly _service: MediaLibraryService;
    private _data: ImageEditorData|undefined = undefined;

    constructor(service: MediaLibraryService) {
        super();
        this._service = service;
        void this._loadData();
    }

    private async _loadData() {
        this._data = await API.getData<ImageEditorData>(this._service.getEndpoint('helpers/image-editor-data'));
        this._callListeners();
    }

    getData(): ImageEditorData | undefined {
        return this._data;
    }
}

export const useImageEditorHelper = () : ImageEditorData|undefined => {
    const service = useMediaLibraryService();
    return useExternalStore(() => new ImageEditorHelper(service));
}
