import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { Color as PaletteColor } from '../../utils/types/palette';
import { BadgeColor } from '../../utils/types';
export var colorBadgeMixin = _defineProperty(_defineProperty(_defineProperty({}, BadgeColor.Primary, function (_ref) {
  var palette = _ref.theme.palette;
  return css(["background-color:", ";color:", ";"], palette[PaletteColor.AccentPrimary], palette[PaletteColor.ButtonPrimaryText]);
}), BadgeColor.White, function (_ref2) {
  var palette = _ref2.theme.palette;
  return css(["background-color:", ";color:", ";"], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimary]);
}), BadgeColor.Secondary, function (_ref3) {
  var palette = _ref3.theme.palette;
  return css(["background-color:", ";color:", ";"], palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.TextPrimary]);
});