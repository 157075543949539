import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import image from './images/map.jpg'

type MapBlockData = {
    mapFrameUrl: string,
}

const isMapBlockData = (data: unknown): data is MapBlockData => {
    return typeof data === 'object' && data !== null && 'mapFrameUrl' in data && typeof data.mapFrameUrl === 'string';
}

const mapBlockDataValidator = async (data: unknown) => {
    if (!isMapBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Карта"');
    }
}

const MapBlockForm = () => {
    return (
        <React.Fragment>
            <Form.Input
                type='textarea'
                name='mapFrameUrl'
                label='Посилання на карту'
            />
        </React.Fragment>
    )
}

const MapBlock : BlockDefinition<MapBlockData> = {
    name: 'hs--map',
    image: image,
    title: 'Блок "Карта"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'sm',
    validator: mapBlockDataValidator,
    form: MapBlockForm,
    initialData: {
        mapFrameUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2573.0117957063167!2d24.027392573939412!3d49.842235883438775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add7258bf7bbd%3A0x8d22d75bebda3123!2z0KTQvtC90YLQsNC9INCx0ZbQu9GPINCe0L_QtdGA0L3QvtCz0L4g0YLQtdCw0YLRgNGD!5e0!3m2!1suk!2sua!4v1721661290415!5m2!1suk!2sua',
    },
}

export default MapBlock;