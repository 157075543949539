import * as React from 'react';
import {useModal} from "@ova-studio/react-hyper-admin";
import {Media, MediaLibraryModal, MediaSelectAction} from "@ova-studio/react-media-library";
import {COMMAND_PRIORITY_EDITOR} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
    EDIT_MEDIA_GALLERY_COMMAND,
    INSERT_MEDIA_COMMAND,
    INSERT_MEDIA_EMBED_COMMAND,
    INSERT_MEDIA_GALLERY_COMMAND,
    INSERT_SINGLE_MEDIA_COMMAND
} from "./commands";
import {$insertNodeToNearestRoot, mergeRegister} from "@lexical/utils";
import {$createMediaSingleNode, MediaSingleNode} from "./MediaSingleNode";
import {$createMediaGalleryNode, MediaGalleryNode} from "./MediaGalleryNode";
import {useEditorContext} from "../../EditorContext";
import {defaultMediaConfig} from "../../../config/defaultMediaConfig";
import {useMediaLibraryService} from "@ova-studio/react-media-library";
import MediaGalleryEditForm, {MediaGalleryEditFormState} from "./forms/MediaGalleryEditForm";

const MediaPlugin = () => {

    const [editor] = useLexicalComposerContext();

    const { config: { mediaConfig: baseMediaConfig }, topbarRegistry, contentRef, editorRef } = useEditorContext();

    const mediaService = useMediaLibraryService();

    const mediaConfig = React.useMemo(() => {
        if (!baseMediaConfig) {
            throw new Error("MediaPlugin: mediaConfig not found in editor config");
        }

        if (baseMediaConfig === true) {
            return defaultMediaConfig;
        }

        return {
            ...defaultMediaConfig,
            ...baseMediaConfig,
        }

    }, [])

    const modalState = useModal<{ isMulti?: boolean }>();

    const editModalState: MediaGalleryEditFormState = useModal();

    const handleSubmit = React.useCallback((media : Media[]) => {
        if (media.length > 1) {
            editor.dispatchCommand(INSERT_MEDIA_GALLERY_COMMAND, media.map(({ id }) => id));
        } else {
            editor.dispatchCommand(INSERT_SINGLE_MEDIA_COMMAND, media[0].id);
        }
    }, [ editor ]);

    React.useEffect(() => {
        if (!editorRef.current) return;

        const mountNode = contentRef?.contentWindow?.document?.body;
        if (!mountNode) return;

        return mediaService.upload.initDropdownIframe(mountNode, {
            block: editorRef.current,
            handler: (promise) => {
                promise.then((media) => {
                    handleSubmit(media);
                });
            },
            message: cnt => cnt === 1 ? 'Відпустіть щоб вставити як медіа' : `Відпустіть щоб вставити як медіа галерею (${cnt} елементів)`,
        });
    }, [ editorRef.current, contentRef ]);

    React.useEffect(() => {
        if (!editor.hasNodes([ MediaSingleNode, MediaGalleryNode ])) {
            throw new Error("MediaSingleNode: node not registered on editor");
        }

        return mergeRegister(
            editor.registerCommand(
                INSERT_MEDIA_COMMAND,
                (isMulti: boolean) => {
                    if (isMulti) {
                        if (!mediaConfig.enableGallery) {
                            throw new Error("MediaPlugin: gallery is disabled");
                        }
                    } else {
                        if (!mediaConfig.enableSingle) {
                            throw new Error("MediaPlugin: single is disabled");
                        }
                    }

                    modalState.setData({ isMulti });
                    modalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                INSERT_MEDIA_EMBED_COMMAND,
                () => {
                    mediaService.upload.openUploadLinkDialog((promise) => {
                        promise.then((media) => {
                            handleSubmit(media);
                        });
                    })
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            mediaConfig.enableSingle ? editor.registerCommand(
                INSERT_SINGLE_MEDIA_COMMAND,
                (mediaId) => {
                    $insertNodeToNearestRoot($createMediaSingleNode(mediaId));
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ) : () => {},
            mediaConfig.enableGallery ? editor.registerCommand(
                INSERT_MEDIA_GALLERY_COMMAND,
                (mediaIds) => {
                    $insertNodeToNearestRoot($createMediaGalleryNode(mediaIds))
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ) : () => {},
            mediaConfig.enableGallery ? editor.registerCommand(
                EDIT_MEDIA_GALLERY_COMMAND,
                (node) => {
                    editModalState.setData(node);
                    editModalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ) : () => {},
            mediaConfig.enableSingle ? topbarRegistry.registerInsert({
                icon: 'multimedia',
                label: 'Медіа',
                command: INSERT_MEDIA_COMMAND,
                payload: false,
            }) : () => {},
            mediaConfig.enableGallery ? topbarRegistry.registerInsert({
                icon: 'view-gallery-outline',
                label: 'Галерея',
                command: INSERT_MEDIA_COMMAND,
                payload: true,
            }) : () => {},
            mediaConfig.enableSingle ? topbarRegistry.registerInsert({
                icon: 'link-variant-plus',
                label: 'Вставка з соцмереж',
                command: INSERT_MEDIA_EMBED_COMMAND,
                payload: undefined,
            }) : () => {},
        )
    }, [editor]);

    return (
        <React.Fragment>
            <MediaLibraryModal
                state={modalState}
                handleSubmit={handleSubmit}
                closeOnSubmit={true}
                types={modalState.data?.isMulti ? mediaConfig.galleryTypes : mediaConfig.singleTypes}
                selectAction={modalState.data?.isMulti ? MediaSelectAction.SelectMultiple : MediaSelectAction.SelectSingle}
            />

            <MediaGalleryEditForm state={editModalState} />
        </React.Fragment>
    );
}

export default MediaPlugin
