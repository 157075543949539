export var FontVariant = {
  TextExtraSmall: 'text-extra-small',
  TextSmall: 'text-small',
  TextSmallUp: 'text-small-up',
  TextMedium: 'text-medium',
  TextLarge: 'text-large',
  TextExtraLarge: 'text-extra-large',
  TextXExtraLarge: 'text-x-extra-large',
  TitleH6: 'title-h6',
  TitleH5: 'title-h5',
  TitleH4: 'title-h4',
  TitleH3: 'title-h3',
  TitleH2: 'title-h2',
  TitleH1: 'title-h1',
  LabelExtraSmall: 'label-extra-small',
  LabelExtraSmallEmphasis: 'label-extra-small-emphasis',
  LabelExtraSmallUp: 'label-extra-small-up',
  LabelSmall: 'label-small',
  LabelSmallEmphasis: 'label-small-emphasis',
  LabelSmallUp: 'label-small-up',
  LabelSmallUpEmphasis: 'label-small-up-emphasis',
  LabelMedium: 'label-medium',
  LabelMediumEmphasis: 'label-medium-emphasis',
  LabelLarge: 'label-large',
  LabelLargeEmphasis: 'label-large-emphasis',
  LabelLargeUp: 'label-large-up',
  LabelExtraLargeEmphasis: 'label-extra-large-emphasis',
  LabelExtraLarge: 'label-extra-large',
  LabelXExtraLargeEmphasis: 'label-x-extra-large-emphasis',
  LabelXExtraLarge: 'label-x-extra-large',
  LabelXXExtraLargeEmphasis: 'label-x-x-extra-large-emphasis',
  LabelXXExtraLarge: 'label-x-x-extra-large',
  LabelXXXExtraLarge: 'label-x-x-x-extra-large',
  ButtonSmEmphasis: 'btn-sm-emphasis',
  ButtonSm: 'btn-sm',
  ButtonSmUpEmphasis: 'btn-sm-up-emphasis',
  ButtonSmUp: 'btn-sm-up',
  ButtonMdEmphasis: 'btn-md-emphasis',
  ButtonMd: 'btn-md',
  ButtonLgEmphasis: 'btn-lg-emphasis',
  ButtonLg: 'btn-lg',
  ButtonLgUpEmphasis: 'btn-lg-up-emphasis',
  ButtonLgUp: 'btn-lg-up',
  LinkSmall: 'link-small',
  LinkSmallEmphasis: 'link-small-emphasis',
  LinkSmallUp: 'link-small-up',
  LinkSmallUpEmphasis: 'link-small-up-emphasis',
  LinkMedium: 'link-medium',
  LinkMediumEmphasis: 'link-medium-emphasis',
  LinkLarge: 'link-large',
  LinkLargeEmphasis: 'link-large-emphasis',
  LinkLargeUp: 'link-large-up',
  LinkLargeUpEmphasis: 'link-large-up-emphasis',
  InputSm: 'input-sm',
  InputSmUp: 'input-sm-up',
  InputMd: 'input-md',
  InputLg: 'input-lg'
};