import * as React from 'react';
import {DateTime} from "luxon";
import {AutoSaveStatus, SaveStatus} from "../types";
import {DateTimeFormat} from "@ova-studio/react-hyper-admin";

type AutoSaveStatusDisplayProps = {
    data: AutoSaveStatus,
}

const AutoSaveStatusDisplay = ({ data }: AutoSaveStatusDisplayProps) => {

    if (data.status === SaveStatus.Unknown) {
        return <>Статус автозбереження невідомий</>
    }

    if (data.status === SaveStatus.Saving) {
        return <>Виконується автозбереження...</>
    }

    if (data.status === SaveStatus.Success) {
        return (
            <>
                Автозбереження успішно виконано
                {data.savedAt && (
                    <span> о <DateTimeFormat dt={data.savedAt} children={dt => dt.toLocaleString(DateTime.DATETIME_SHORT)} /></span>
                )}
                {data.message && (
                    <span>: {data.message}</span>
                )}
            </>
        )
    }

    if (data.status === SaveStatus.Error) {
        return (
            <>
                Помилка автозбереження
                {data.message && (
                    <span>: {data.message}</span>
                )}
            </>
        )
    }

    return null;
}

export default AutoSaveStatusDisplay
