import * as React from "react";
import {useEditorContext} from "../EditorContext";
import {useExternalStore} from "@ova-studio/react-hyper-admin";
import {Dropdown} from "react-bootstrap";
import clsx from "clsx";

type ItemBodyProps = {
    icon?: string,
    title: string,
    disabled?: boolean,
}

const ItemBody = ({ icon, title, disabled } : ItemBodyProps) => (
    <div className={clsx('d-inline-flex align-items-center lh-sm', { 'text-muted': disabled })}>
        {icon && <i className={`mdi mdi-${icon} fs-5 me-1`} />}
        <span>{title}</span>
    </div>
)

const TopbarInsertDropdown = () => {

    const { topbarRegistry } = useEditorContext();
    const { insert } = useExternalStore(() => topbarRegistry);

    if (insert.length === 0) {
        return null;
    }

    return (
        <div className='ova-editor-topbar-group' data-name="insert">
            <Dropdown>
                <Dropdown.Toggle variant='link' className='link-secondary py-0 px-1 lh-1 arrow-none' title='Вставити'>
                    <ItemBody icon='plus' title='Вставити' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {insert.map((option, i) => (
                        <Dropdown.Item key={i} onClick={option.handleClick} disabled={option.isDisabled}>
                            <ItemBody title={option.label} icon={option.icon} disabled={option.isDisabled} />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default TopbarInsertDropdown;
