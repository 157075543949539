import * as React from "react";
import {Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Button} from "react-bootstrap";

type FormOfflineModalProps = {
    state: UseModalReturn<any>,
    handleEnableOffline?: () => void,
}

const FormOfflineModal = ({ state, handleEnableOffline }: FormOfflineModalProps) => {
    return (
        <Modal state={state} lockClose={true} title="Втрачено з'єднання з сервером" titleBg='danger'>
            <Modal.Body>
                {handleEnableOffline ? (
                    <React.Fragment>
                        <p>З'єднання з сервером втрачено. Ви можете продовжити редагування в офлайн режимі або
                            дочекатись відновлення з'єднання.</p>
                        <p className='text-danger mb-0'>Редагування форми в офлайн режимі може спричинити конфлікт змін.
                            Будуть збережені лише останні зміни.</p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p>З'єднання з сервером втрачено. Дочекайтесь відновлення з'єднання.</p>
                        <p className='mb-0'>Ця форма не може бути використана в офлайн режимі.</p>
                    </React.Fragment>
                )}
            </Modal.Body>
            {handleEnableOffline && (
                <Modal.Footer>
                    <Button variant='outline-danger' onClick={handleEnableOffline}>
                        Продовжити офлайн (не рекомендується)
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default FormOfflineModal;