export default function convertEmptyStringToNull(value: unknown): string | null {
    if (typeof value !== 'string') {
        return null;
    }

    if (value.trim().length === 0) {
        return null;
    }

    return value;
}
