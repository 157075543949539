import * as React from "react";
import {RenderedBlockProps} from "../Editor/Plugins/BlocksPlugin/types";
import API from "@ova-studio/api-helper";
import EditorAwaitBlock from "./EditorAwaitBlock";

type RenderedBlockData = {
    html: string,
}

export type EditorBlockServerRendererProps = RenderedBlockProps<any>

const EditorBlockServerRenderer = ({ name, data }: EditorBlockServerRendererProps) => {
    const renderDataAwait = React.useMemo(() =>
        API.post(EditorBlockServerRenderer.endpoint, { name, data: JSON.stringify(data) })
            .then(res => res.data as RenderedBlockData),
        [ name, data ]);

    return (
        <EditorAwaitBlock
            resolve={renderDataAwait}
            children={(data) => (
                <div
                    dangerouslySetInnerHTML={{__html: data.html}}
                />
            )}
        />
    )
}

EditorBlockServerRenderer.endpoint = '/api/helpers/content/block-renderer';

export default EditorBlockServerRenderer;
