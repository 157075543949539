import {createCommand, LexicalCommand} from "lexical";
import {CreateBlockData, DeleteBlockData, EditCommandPayload, InsertBlockData, UpdateCommandPayload} from "./types";

export const NEW_BLOCK_COMMAND : LexicalCommand<void> = createCommand('NEW_BLOCK_COMMAND');
export const INSERT_BLOCK_COMMAND : LexicalCommand<InsertBlockData> = createCommand('INSERT_BLOCK_COMMAND');
export const CREATE_BLOCK_COMMAND : LexicalCommand<CreateBlockData> = createCommand('CREATE_BLOCK_COMMAND');

export const EDIT_BLOCK_COMMAND : LexicalCommand<EditCommandPayload> = createCommand('EDIT_BLOCK_COMMAND');
export const UPDATE_BLOCK_COMMAND : LexicalCommand<UpdateCommandPayload> = createCommand('UPDATE_BLOCK_COMMAND');

export const DELETE_BLOCK_COMMAND : LexicalCommand<DeleteBlockData> = createCommand('DELETE_BLOCK_COMMAND');
