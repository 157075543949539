import * as React from 'react';
import {EditorConfig} from "../config/EditorConfig";
import EditorComposer from "./EditorComposer";
import {OnChangeHandler} from "../config/OnChangeHandler";
import defaultConfig from "../config/defaultConfig";
import {InitialEditorStateType} from "@lexical/react/LexicalComposer";
import {defaultInitState} from "../utils/defaultInitState";
import clsx from "clsx";
import TopbarRegistry from "./Topbar/TopbarRegistry";
import EditorContext, {EditorContextData} from "./EditorContext";

export type EditorProps = {
    initState?: InitialEditorStateType,
    config?: EditorConfig,
    onChange?: OnChangeHandler,
    children?: React.ReactNode,
}

const defaultChangeHandler : OnChangeHandler = (data) => console.log(data);

const Editor = ({ config: userConfig, onChange = defaultChangeHandler, initState = defaultInitState, children }: EditorProps) => {

    const config : EditorConfig = React.useMemo(() => ({ ...defaultConfig, ...(userConfig ?? {}) }), []);

    const [ contentRef, setContentRef ] = React.useState<HTMLIFrameElement|null>(null);

    const [ cursorsContainer, setCursorsContainer ] = React.useState<HTMLDivElement|null>(null);

    const topbarRegistry = React.useMemo(() => new TopbarRegistry(), []);

    const ref = React.useRef<HTMLDivElement|null>(null);

    const contextData : EditorContextData = {
        editorRef: ref,
        config,
        topbarRegistry,
        cursorsContainer,
        setCursorsContainer,
        contentRef,
        setContentRef,
        onChange,
        initState,
    }

    return (
        <div className={clsx('ova-editor')} ref={ref}>
            <EditorContext.Provider value={contextData}>
                <EditorComposer children={children} />
            </EditorContext.Provider>
        </div>
    );
}

export default Editor
