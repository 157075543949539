import {$createParagraphNode, $isDecoratorNode, DecoratorNode, LexicalEditor, LexicalNode} from "lexical";
import {ReactNode} from "react";

export type MoveProps = {
    handleInsertBefore?: (editor: LexicalEditor) => void;
    handleInsertAfter?: (editor: LexicalEditor) => void;
    handleMoveUp?: (editor: LexicalEditor) => void;
    handleMoveDown?: (editor: LexicalEditor) => void;
}

export abstract class BaseBlockNode extends DecoratorNode<ReactNode> {

    private _handleInsertBefore(editor: LexicalEditor) {
        editor.update(() => {
            this.insertBefore($createParagraphNode());
        })
    }

    private _handleInsertAfter(editor: LexicalEditor) {
        editor.update(() => {
            this.insertAfter($createParagraphNode());
        })
    }

    private _handleMoveUp(editor: LexicalEditor) {
        editor.update(() => {
            const node = this.getPreviousSibling();
            if (!node) return;
            node.insertBefore(this);
        })
    }

    private _handleMoveDown(editor: LexicalEditor) {
        editor.update(() => {
            const node = this.getNextSibling();
            if (!node) return;
            node.insertAfter(this);
        })
    }

    private _enableInsert(node: LexicalNode | null) : boolean {
        return node === null || $isDecoratorNode(node);
    }

    private _canMove(node: LexicalNode | null) : boolean {
        return node !== null;
    }

    protected _getMoveProps() : MoveProps {
        const prev = this.getPreviousSibling();
        const next = this.getNextSibling();

        return {
            handleInsertBefore: this._enableInsert(prev) ? this._handleInsertBefore.bind(this) : undefined,
            handleInsertAfter: this._enableInsert(next) ? this._handleInsertAfter.bind(this) : undefined,
            handleMoveUp: this._canMove(prev) ? this._handleMoveUp.bind(this) : undefined,
            handleMoveDown: this._canMove(next) ? this._handleMoveDown.bind(this) : undefined,
        }
    }

}
