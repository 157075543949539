import * as React from 'react';
import {useEditorConfig} from "../../EditorContext";
import FontFamilyPlugin from "./FontFamilyPlugin";
import FontSizePlugin from "./FontSizePlugin";

const FontStylePlugin = () => {

    const config = useEditorConfig();

    return (
        <React.Fragment>
            {config.fonts && <FontFamilyPlugin />}
            {config.fontSizes && <FontSizePlugin />}
        </React.Fragment>
    );
}

export default FontStylePlugin
