import * as React from 'react';
import Icon from "@mdi/react";
import {mdiAlert} from "@mdi/js";

export type EditorErrorBlockProps = {
    txt: string,
}

const EditorErrorBlock = ({ txt }: EditorErrorBlockProps) => {
    return (
        <div className='ova-editor-error'>
            <Icon path={mdiAlert} size={1} />
            <p>{txt}</p>
        </div>
    );
}

export default EditorErrorBlock
