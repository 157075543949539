import {AbstractDataStore, SimpleCallback} from "@ova-studio/react-hyper-admin";
import {
    EnableCheck,
    isTopbarButton, isTopbarDropdown,
    isTopbarSelect,
    isTopbarSelectOptionWithIcon,
    TopbarButton,
    TopbarButtonInternal, TopbarDropdown, TopbarDropdownInternal, TopbarInsertOption,
    TopbarItem,
    TopbarItemInternal,
    TopbarRegistryData,
    TopbarSelect,
    TopbarSelectInternal,
} from "./types";
import {$isTextNode, COMMAND_PRIORITY_EDITOR, LexicalEditor, SELECTION_CHANGE_COMMAND} from "lexical";
import {mergeRegister} from "@lexical/utils";
import getSelectionData, {SelectionData} from "../../utils/getSelectionData";
import getNodeOfTypeFromSelection from "../../utils/getNodeOfTypeFromSelection";

export default class TopbarRegistry extends AbstractDataStore<TopbarRegistryData> {

    private _editor: LexicalEditor|null = null;
    private _onEditorClear: SimpleCallback|null = null;

    private _items: Array<TopbarItem> = [];
    private _insertItems: Array<TopbarInsertOption> = [];

    private _data: TopbarRegistryData = {
        items: {},
        insert: [],
    };

    public register(item: TopbarItem) : SimpleCallback {
        this._items.push(item);
        this._callListeners();

        return () => {
            this._items = this._items.filter(i => i !== item);
            this._callListeners();
        }
    }

    public registerMultiple(item: TopbarItem[]) : SimpleCallback {
        this._items.push(...item);
        this._callListeners();

        return () => {
            this._items = this._items.filter(i => !item.includes(i));
            this._callListeners();
        }
    }

    public registerInsert(option: TopbarInsertOption) : SimpleCallback {
        this._insertItems.push(option);
        this._callListeners();

        return () => {
            this._insertItems = this._insertItems.filter(i => i !== option);
            this._callListeners();
        }
    }

    public registerInserts(options: TopbarInsertOption[]) : SimpleCallback {
        this._insertItems.push(...options);
        this._callListeners();

        return () => {
            this._insertItems = this._insertItems.filter(i => !options.includes(i));
            this._callListeners();
        }
    }

    private _checkTopbarButtonEnable(selection: SelectionData|null, item?: EnableCheck) : boolean {
        if (!item) return true;
        if (item === 'text') {
            return !!getNodeOfTypeFromSelection(selection, $isTextNode);
        }
        if (item === 'selection') {
            return !!selection && !selection.collapsed;
        }
        if (item === 'collapsed-selection') {
            return !!selection && selection.collapsed;
        }
        return item(selection);
    }

    private _processTopbarButton(item: TopbarButton) : TopbarButtonInternal {
        const _this = this;

        const { isActive, isEnable, selectionData } = this._editor?.getEditorState().read(function () {
            const selectionData = getSelectionData(_this._editor!);

            const isActive = item.isActiveCheck ? item.isActiveCheck(selectionData) : false;
            const isEnable = (item.enableOnActive && isActive) || _this._checkTopbarButtonEnable(selectionData, item.isEnableCheck);

            return { isActive, isEnable, selectionData }
        }) || { isActive: false, isEnable: false, selectionData: null };

        return {
            type: 'button',
            label: item.label,
            icon: item.icon,
            isActive: isActive,
            isDisabled: !isEnable,
            handleClick: () => {
                if (item.wrapWithData) {
                    this._editor?.dispatchCommand(item.command, {
                        data: item.payload,
                        selection: selectionData,
                    });
                    return;
                }

                this._editor?.dispatchCommand(item.command, item.payload);
            },
        }
    }

    private _processTopbarSelect(item: TopbarSelect) : TopbarSelectInternal {

        const _this = this;

        const { currentValue, isEnable, selectionData } = this._editor?.getEditorState().read(function () {
            const selectionData = getSelectionData(_this._editor!);

            const currentValue = item.currentValue ? item.currentValue(selectionData) : null;
            const isEnable = _this._checkTopbarButtonEnable(selectionData, item.isEnableCheck);

            return { currentValue, isEnable, selectionData }
        }) || { currentValue: null, isEnable: false, selectionData: null };

        const selectedOption = item.options.find(o => o.value === currentValue);

        const icon = isTopbarSelectOptionWithIcon(selectedOption) ? selectedOption.icon : item.icon;
        const label = selectedOption ? selectedOption.label : item.defaultLabel;

        return {
            type: 'select',
            icon: icon,
            label: label,
            title: item.title,
            options: item.options,
            currentValue: currentValue,
            isDisabled: !isEnable,
            handleSelect: (value: string) => {
                if (item.wrapWithData) {
                    this._editor?.dispatchCommand(item.command, {
                        data: value,
                        selection: selectionData,
                    });
                    return;
                }

                this._editor?.dispatchCommand(item.command, value);
            },
        }
    }

    private _processTopbarInsert(item: TopbarInsertOption) : TopbarButtonInternal {
        const _this = this;

        const { isActive, isEnable, selectionData } = this._editor?.getEditorState().read(function () {
            const selectionData = getSelectionData(_this._editor!);

            const isActive = item.isActiveCheck ? item.isActiveCheck(selectionData) : false;
            const isEnable = (item.enableOnActive && isActive) || _this._checkTopbarButtonEnable(selectionData, item.isEnableCheck);

            return { isActive, isEnable, selectionData }
        }) || { isActive: false, isEnable: false, selectionData: null };

        return {
            type: 'button',
            label: item.label,
            icon: item.icon,
            isActive: isActive,
            isDisabled: !isEnable,
            handleClick: () => {
                if (item.wrapWithData) {
                    this._editor?.dispatchCommand(item.command, {
                        data: item.payload,
                        selection: selectionData,
                    });
                    return;
                }

                this._editor?.dispatchCommand(item.command, item.payload);
            },
        }
    }

    private _processTopbarDropdown(item: TopbarDropdown) : TopbarDropdownInternal {
        return {
            type: 'dropdown',
            label: item.hideTitle ? undefined : item.title,
            title: item.title,
            icon: item.icon,
            options: item.dropdownOptions,
        }
    }

    private _processTopbarItem(item: TopbarItem) : TopbarItemInternal {
        if (isTopbarButton(item)) {
            return this._processTopbarButton(item);
        }

        if (isTopbarSelect(item)) {
            return this._processTopbarSelect(item);
        }

        if (isTopbarDropdown(item)) {
            return this._processTopbarDropdown(item);
        }

        throw new Error('Invalid topbar item');
    }

    protected _callListeners() {
        if (!this._editor) return;

        this._data = {
            items: this._items.reduce((acc, item) => ({
                ...acc,
                [item.section]: [
                    ...(acc[item.section] || []),
                    this._processTopbarItem(item),
                ],
            }), {} as TopbarRegistryData['items']),
            insert: this._insertItems.map(this._processTopbarInsert.bind(this)),
        };

        super._callListeners();
    }

    private _handleSelectionChange() {
        if (!this._editor) return;
        // this._selectionData = getSelectionData(this._editor);
        this._callListeners();
    }

    private _initEditorListeners() {
        if (!this._editor) return;

        this._onEditorClear = mergeRegister(
            this._editor.registerUpdateListener(({ editorState }) => {
                editorState.read(this._handleSelectionChange.bind(this));
            }),
            this._editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    this._handleSelectionChange();
                    return false;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
        );
    }

    private _clearEditorListeners() {
        if (!this._editor) return;
        this._onEditorClear?.();
        this._onEditorClear = null;
    }

    public setEditor(editor: LexicalEditor) : SimpleCallback {
        this._editor = editor;
        this._initEditorListeners();
        this._callListeners();
        return () => {
            this._clearEditorListeners();
            this._editor = null;
        }
    }

    getData(): TopbarRegistryData {
        return this._data;
    }
}
