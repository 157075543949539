import * as React from "react";
import {FormRtcOptions} from "./types";
import {CollaborationContextProvider} from "../provider";
import {CallbackFormProps} from "@ova-studio/react-hyper-admin/dist/Form";
import CollaborationFormContextProvider from "./CollaborationFormContext";
import CollaborationFormBody from "./CollaborationFormBody";

export type CollaborationFormProps = Omit<CallbackFormProps, 'handleSubmit'> & {
    formId: FormRtcOptions['formId'],
    documentId: string,
    handleOfflineSubmit?: FormRtcOptions['handleOfflineSubmit'],
    handleOnlineSubmit?: FormRtcOptions['handleOnlineSubmit'],
}

const CollaborationForm = ({ documentId, children, formId, handleOfflineSubmit, handleOnlineSubmit, ...formProps }: CollaborationFormProps) => {

    return (
        <CollaborationContextProvider documentId={documentId}>
            <CollaborationFormContextProvider formId={formId} handleOfflineSubmit={handleOfflineSubmit} handleOnlineSubmit={handleOnlineSubmit} formProps={formProps}>
                <CollaborationFormBody>
                    {children}
                </CollaborationFormBody>
            </CollaborationFormContextProvider>
        </CollaborationContextProvider>
    )
}

export default CollaborationForm;