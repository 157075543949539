import * as React from "react";
import {DOMConversionMap, DOMExportOutput, SerializedLexicalNode, Spread} from "lexical";
import {MediaSingle} from "./decorators/MediaSingle";
import {MediaSingleData} from "./types/MediaSingleData";
import {DOMConversionFn} from "lexical/LexicalNode";
import {BaseBlockNode} from "../common/BaseBlockNode";

type MediaSingleNodeProps = {
    mediaId: number,
} & MediaSingleData

type SerializedMediaSingleNode = Spread<MediaSingleNodeProps, SerializedLexicalNode>

export class MediaSingleNode extends BaseBlockNode {

    static getType(): string {
        return 'media-single';
    }

    static clone(node: MediaSingleNode): MediaSingleNode {
        return new MediaSingleNode(node.__mediaId, node.__caption, node.__key);
    }

    static importJSON(data: SerializedMediaSingleNode): MediaSingleNode {
        return $createMediaSingleNode(data.mediaId, data.caption);
    }

    exportJSON(): SerializedMediaSingleNode {
        return {
            mediaId: this.__mediaId,
            caption: this.__caption,
            type: MediaSingleNode.getType(),
            version: 1,
        };
    }

    __mediaId: number;
    __caption?: string;

    constructor(mediaId: number, caption?: string, key?: string) {
        super(key);
        this.__mediaId = mediaId;
        this.__caption = caption;
    }

    createDOM(): HTMLElement {
        return document.createElement('div');
    }

    static importDOM() : DOMConversionMap | null {
        return {
            ins: (node: Node) => {
                const element = node as HTMLElement;
                if (element.dataset.type !== 'media-single') return null;
                return {
                    conversion: $convertMediaSingleNode,
                    priority: 4,
                }
            }
        }
    }

    updateDOM(): false {
        return false;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('ins');
        element.dataset.type = "media-single";
        element.dataset.mediaId = this.__mediaId.toString();
        element.dataset.caption = this.__caption ?? '';
        return { element }
    }

    private _handleUpdate(data: MediaSingleData) : void {
        const self = this.getWritable();
        self.__caption = data.caption?.length ? data.caption : undefined;
    }

    private _handleRemove() : void {
        this.remove();
    }

    decorate(): React.ReactNode {
        const moveProps = this._getMoveProps();

        return (
            <MediaSingle
                mediaId={this.__mediaId}
                caption={this.__caption}
                handleUpdate={this._handleUpdate.bind(this)}
                handleRemove={this._handleRemove.bind(this)}
                {...moveProps}
            />
        );
    }

    isInline() : false {
        return false;
    }

}

export const $createMediaSingleNode = (mediaId: number, caption?: string, key?: string): MediaSingleNode => {
    return new MediaSingleNode(mediaId, caption, key);
}

export const $convertMediaSingleNode : DOMConversionFn = (element) => {
    const mediaId = parseInt(element.dataset.mediaId ?? '0');
    const caption = element.dataset.caption ?? undefined;
    return {
        node: $createMediaSingleNode(mediaId, caption),
    };
}

export const $isMediaSingleNode = (node: any): node is MediaSingleNode => {
    return node instanceof MediaSingleNode;
}
