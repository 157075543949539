import * as React from "react";
import {CollaborationAwareData, SaveStatus} from "../types";
import classNames from "classnames";
import AutoSaveStatusDisplay from "./AutoSaveStatusDisplay";
import CollaborationUserDisplay from "./CollaborationUserDisplay";
import {Tooltip, useBoolean} from "@ova-studio/react-hyper-admin";

type CollaborationStatusBarProps = {
    data: CollaborationAwareData,
}

const CollaborationStatusBar = ({ data }: CollaborationStatusBarProps) => {

    const { state: isOpen, toggle: toggleIsOpen } = useBoolean()

    return (
        <div className={classNames('rtc-data', isOpen && 'open')}>
            <div className={classNames('rtc-stat', data.isOnline && 'rtc-stat-is-online')}
                 onClick={() => toggleIsOpen()} role='button'>
                <i className='mdi mdi-account-multiple-outline'/>
                <span className='rtc-stat-user-count'>{data.users.length}</span>
                {data.autoSave && (
                    <Tooltip text={<AutoSaveStatusDisplay data={data.autoSave} />} placement='left'>
                        <i className={classNames('mdi', {
                            'mdi-content-save-check-outline text-success': data.autoSave.status === SaveStatus.Success,
                            'mdi-content-save-alert text-danger': data.autoSave.status === SaveStatus.Error,
                            'mdi-content-save-move-outline text-warning': data.autoSave.status === SaveStatus.Saving,
                        })}/>
                    </Tooltip>
                )}
            </div>
            <div className='rtc-users'>
                {data?.users?.map((user) => (
                    <CollaborationUserDisplay data={user} key={`rtc-user-${user.internalId}`} />
                ))}
            </div>
        </div>
    )
}

export default CollaborationStatusBar;