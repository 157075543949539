import {FormSelectOption} from "@ova-studio/react-hyper-admin";

const relOptions : FormSelectOption[] = [
    { value: 'nofollow noopener', label: 'за замовчуванням' },
    { value: 'dofollow', label: 'dofollow' },
    { value: 'ugc', label: 'ugc' },
    { value: 'sponsored', label: 'sponsored' },
];

export default relOptions;
