import * as React from "react";
import {useEditorContext} from "../../EditorContext";
import {Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Card, Col, Row} from "react-bootstrap";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {BlockDefinition} from "./types";
import {INSERT_BLOCK_COMMAND} from "./commands";

// @ts-ignore
import noImage from "../../../assets/fallback_media.jpg";
import {filterSpecialBlocks, hasSpecialBlocks} from "./utils";

type BlockSelectorProps = {
    state: UseModalReturn<any>,
}

const BlockSelector = ({ state }: BlockSelectorProps) => {

    const { config: { blocks } } = useEditorContext();
    const [editor] = useLexicalComposerContext();

    const handleSelect = (block: BlockDefinition) => {
        state.close();
        editor.dispatchCommand(INSERT_BLOCK_COMMAND, block.name);
    }

    if (!hasSpecialBlocks(blocks ?? [])) {
        return false;
    }

    const _blocks = filterSpecialBlocks(blocks ?? []);

    return (
        <Modal title='Вставити блок' state={state} size="lg" scrollable={true}>
            <Modal.Body>
                <Row xs={2} sm={2} lg={3} className='g-2'>
                    {_blocks.map((block, i) => (
                        <Col key={`block-${i}`}>
                            <Card role='button' className='mb-0' onClick={() => handleSelect(block)}>
                                <Card.Img src={block.image ?? noImage} style={{ aspectRatio: 16/9, objectFit: "cover" }} />
                                <Card.ImgOverlay className='d-flex align-items-end bg-black bg-opacity-50'>
                                    <Card.Title className='m-0 text-white'>{block.title}</Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                    ))}
                </Row>

            </Modal.Body>
        </Modal>
    )
}

export default BlockSelector;
