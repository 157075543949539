import * as React from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
    $createTableNodeWithDimensions,
    TableNode
} from "@lexical/table";
import {mergeRegister} from "@lexical/utils";
import {CREATE_TABLE_COMMAND, INSERT_TABLE_COMMAND} from "./commands";
import {$insertNodes, COMMAND_PRIORITY_EDITOR} from "lexical";
import {useModal} from "@ova-studio/react-hyper-admin";
import TableModal, {TableModalState} from "./TableModal";
import {useEditorContext} from "../../EditorContext";
import TableActionPlugin from "./TableActionPlugin";

const TablePlugin = () => {

    const [editor] = useLexicalComposerContext();
    const {topbarRegistry} = useEditorContext();
    const modalState : TableModalState = useModal();

    React.useEffect(() => {
        if (!editor.hasNodes([TableNode])) {
            throw new Error("TableNode: node not registered on editor");
        }

        return mergeRegister(
            editor.registerCommand(
                CREATE_TABLE_COMMAND,
                () => {
                    modalState.open();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                INSERT_TABLE_COMMAND,
                ({ rows, columns, includeHeaders }) => {
                    const tableNode = $createTableNodeWithDimensions(
                        Number(rows),
                        Number(columns),
                        includeHeaders,
                    );
                    $insertNodes([tableNode]);
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            topbarRegistry.registerInsert({
                command: CREATE_TABLE_COMMAND,
                icon: 'table',
                label: 'Таблиця',
                payload: undefined,
            })
        );

    }, [ editor ]);

    return (
        <React.Fragment>
            <TableModal state={modalState} />
            <TableActionPlugin />
        </React.Fragment>
    )
}

export default TablePlugin;
