import {
    CollaborationAwareData, CollaborationConfig,
    CollaborationDocument,
    CollaborationOptions,
    CollaborationUserData,
    InternalCollaborationUser, isAuthResponse, isAutoSaveStatus, OnlineEventListener
} from "./types";
import * as Y from "yjs";
import {HocuspocusProviderWebsocket, HocuspocusProvider} from "@hocuspocus/provider";
import {onAwarenessUpdatePayload} from "@hocuspocus/provider/dist/packages/server/src";
import {StatesArray} from "@hocuspocus/provider/dist/packages/server/src/types";
import {awarenessStatesToArray} from "@hocuspocus/common";
import API, { isApiNotAuthorizedError } from "@ova-studio/api-helper";
import {AbstractDataStore, Auth, SimpleCallback} from "@ova-studio/react-hyper-admin";
import {Doc} from "yjs";

type TokenResolver = string|Promise<string>;

export default class CollaborationService extends AbstractDataStore<CollaborationAwareData> {

    private static readonly ROOT_DOC_NAME = '__root';

    private readonly _opts: CollaborationOptions;
    private readonly _config: CollaborationConfig;
    private readonly _auth: Auth;

    private readonly _wsProvider: HocuspocusProviderWebsocket;

    private readonly _documents: Record<string, CollaborationDocument> = {};

    private readonly _authData: CollaborationUserData;

    private _awareData: CollaborationAwareData  = {
        users: [],
        isOnline: false,
        autoSave: undefined,
        isReady: false,
    }

    private _authToken: TokenResolver|undefined = undefined;

    private _isOnlineServer: boolean = false;
    private _isOnlineWs: boolean = false;
    private _pingInterval: NodeJS.Timeout|null = null;

    private _onlineEventListeners: OnlineEventListener[] = [];

    constructor(opts: CollaborationOptions, config: CollaborationConfig, auth: Auth) {
        super();

        this._opts = opts;
        this._config = config;
        this._auth = auth;

        this._pingServer();
        this._pingInterval = setInterval(() => {
            this._pingServer();
        }, 5000);

        this._wsProvider = this._makeWsProvider();

        this._authData = this._makeAuthData();

        this.makeDocument(CollaborationService.ROOT_DOC_NAME);

        this._initWaitReady();
        this._initAware();
        this._initAutoSave();
    }

    private _makeWsProvider() : HocuspocusProviderWebsocket {
        return new HocuspocusProviderWebsocket({
            url: this._config.rtcWsUrl,
            onStatus: (e) => {
                this._isOnlineWs = e.status === 'connected';
                this._updateOnline();
            },
        })
    }

    private _makeDocumentId(name?: string) : string {
        if (!name) {
            return `${this._config.appPrefix ?? 'ova-admin-rtc'}::${this._opts.documentId}`;
        }

        return `${this._config.appPrefix ?? 'ova-admin-rtc'}::${this._opts.documentId}/${name}`;
    }

    private get _authEndpoint() : string {
        return `${this._config.baseEndpoint}/auth`;
    }

    private get _pingEndpoint() : string {
        return `${this._config.baseEndpoint}/ping`;
    }

    private _tokenResolver() : (() => string) | (() => Promise<string>) {
        if (!this._authToken) {
            this._authToken = new Promise<string>(async (resolve, reject) => {
                try {
                    const { data } = await API.post(this._authEndpoint, { document_id: this._makeDocumentId() });
                    if (isAuthResponse(data)) {
                        resolve(data.token);
                        return;
                    }

                    reject(new Error('Invalid token response'));
                } catch (e) {
                    reject(e);
                }
            });
        }

        return () => this._authToken as string
    }

    private _pingServer() {
        API.get(this._pingEndpoint, { document_id: this._makeDocumentId() }, { timeout: 1000 })
            .then(() => {
                this._isOnlineServer = true;
                this._updateOnline();
            })
            .catch((e) => {
                this._isOnlineServer = false;
                this._updateOnline();

                if (isApiNotAuthorizedError(e)) {
                    this._auth.refresh();
                    return;
                }
            })
    }

    private _updateOnline() {
        const isOnline = this._isOnlineServer && this._isOnlineWs;
        if (isOnline !== this._awareData.isOnline) {
            this._updateAwareData({ isOnline });
            this._onlineEventListeners.forEach((listener) => {
                listener(isOnline);
            });
        }
    }

    public addOnlineEventListener(listener: OnlineEventListener) : SimpleCallback {
        this._onlineEventListeners.push(listener);
        return () => {
            this._onlineEventListeners = this._onlineEventListeners.filter((l) => l !== listener);
        }
    }

    public makeDocument(name: string) : CollaborationDocument {
        const fullName = this._makeDocumentId(name);

        const doc = new Y.Doc({ autoLoad: true });

        const provider = new HocuspocusProvider({
            websocketProvider: this._wsProvider,
            name: fullName,
            token: this._tokenResolver(),
            document: doc,
        });

        const cDoc: CollaborationDocument = {
            name: fullName,
            doc,
            provider,
        }

        this._documents[fullName] = cDoc;

        return cDoc;
    }

    public clearDocument(fullName: string) {
        const doc = this._documents[fullName];
        if (doc) {
            doc.provider.destroy();
            delete this._documents[fullName];
        }
    }

    private _getDocument(name: string) : CollaborationDocument {
        const fullName = this._makeDocumentId(name);
        const doc = this._documents[fullName];
        if (!doc) throw new Error(`Document ${fullName} not found`);
        return doc;
    }

    private _generateColor() : string {
        return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
    }

    private _makeAuthData() : CollaborationUserData {
        const data = this._auth.getData();
        if (!data) throw new Error('User is not authenticated');

        return {
            id: data.id,
            name: data.name,
            username: data.username,
            avatar: data.avatar,
            color: this._generateColor(),
        }
    }

    // noinspection JSUnusedGlobalSymbols
    public get authData() : CollaborationUserData {
        return this._authData;
    }

    private get _rootCollaborationDoc() : CollaborationDocument {
        return this._getDocument(CollaborationService.ROOT_DOC_NAME);
    }

    private get _rootProvider() : HocuspocusProvider {
        return this._rootCollaborationDoc.provider;
    }

    private get _rootDoc() : Doc {
        return this._rootCollaborationDoc.doc;
    }

    private _updateAutoSaveStatus() {
        const data = this._rootDoc.getMap('__autoSave').toJSON();

        if (isAutoSaveStatus(data)) {
            this._updateAwareData({ autoSave: data });
        } else {
            this._updateAwareData({ autoSave: undefined });
        }
    }

    private _initAutoSave() {
        this._rootDoc.on('update', () => {
            this._updateAutoSaveStatus();
        });

        this._updateAutoSaveStatus();
    }
    
    private _updateAwareState(state: StatesArray) {
        const users : InternalCollaborationUser[] = state.map(a => ({
            internalId: a.clientId,
            isCurrent: a.clientId === this._rootProvider.awareness?.clientID,
            ...a.user,
        }));

        this._updateAwareData({ users });
    }

    private _initAware() {
        this._rootProvider.setAwarenessField('user', this._authData);

        this._rootProvider.on('awarenessUpdate', ({ states } : onAwarenessUpdatePayload) => {
            this._updateAwareState(states);
        });

        const initState = this._rootProvider.awareness ?  awarenessStatesToArray(this._rootProvider.awareness.getStates()) : [];
        this._updateAwareState(initState);
    }

    private _updateAwareData(data: Partial<CollaborationAwareData>) {
        this._awareData = {
            ...this._awareData,
            ...data,
        }
        this._callListeners();
    }

    getData(): CollaborationAwareData {
        return this._awareData;
    }

    public _initWaitReady() : void {
        const isReady = () => {
            return this._rootDoc.getMap('__init').get('__initialized') === 'true';
        }

        if (isReady()) {
            this._updateAwareData({ isReady: true })
        }

        const check = () => {
            if (isReady()) {
                this._updateAwareData({ isReady: true })
                this._rootDoc.off('update', check);
            }
        }

        this._rootDoc.on('update', check);
    }

    public destroy() {
        Object.keys(this._documents).forEach((doc) => {
            this.clearDocument(doc);
        });

        if (this._pingInterval) {
            clearInterval(this._pingInterval);
            this._pingInterval = null;
        }

        this._wsProvider.destroy();
    }
}