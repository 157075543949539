import {SelectionData} from "../../../utils/getSelectionData";
import {BlockType} from "./blockTypes";
import {$isListNode, ListNode} from "@lexical/list";
import {$getNearestNodeOfType} from "@lexical/utils";
import {$isHeadingNode, $isQuoteNode} from "@lexical/rich-text";
import {$isDecoratorNode, $isParagraphNode} from "lexical";

const getCurrentTypeFromSelection = (selection: SelectionData | null) : BlockType => {
    if (!selection) return 'paragraph';

    const { anchorNode, element } = selection;

    if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
        );
        return parentList
            ? parentList.getListType()
            : element.getListType();
    }

    if ($isHeadingNode(element)) {
        return element.getTag();
    }

    if ($isQuoteNode(element)) {
        return 'quote';
    }

    if ($isParagraphNode(element)) {
        return 'paragraph';
    }

    if ($isDecoratorNode(element)) {
        return 'custom';
    }

    return 'paragraph';
}

export default getCurrentTypeFromSelection
