import { ButtonSize } from '../../utils/types';
export var getIconSize = function getIconSize(sizeName) {
  switch (sizeName) {
    case ButtonSize.Lg:
      return 18;
    case ButtonSize.Md:
      return 16;
    case ButtonSize.Sm:
      return 14;
    case ButtonSize.Xs:
      return 12;
    default:
      return 16;
  }
};
export var getSideBarIconSize = function getSideBarIconSize(sizeName) {
  switch (sizeName) {
    case ButtonSize.Lg:
      return 12;
    case ButtonSize.Md:
      return 11;
    case ButtonSize.Sm:
      return 10;
    default:
      return 11;
  }
};