import TopbarTabs from "./TopbarTabs";
import TopbarRow from "./TopbarRow";
import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {NavigationConfig, TopbarButton} from "./types/NavigationConfig";
import {App} from "../../Application";

export type TopbarData = {
    isVisible: boolean,
    buttons: TopbarButton[],
}

export default class Topbar extends AbstractDataStore<TopbarData> {

    private readonly _app: App;
    private readonly _config: NavigationConfig;

    public readonly Tabs: TopbarTabs;
    public readonly Row: TopbarRow;

    private _prevScroll: number = 0;

    private _data : TopbarData = {
        isVisible: true,
        buttons: [],
    }


    constructor(app: App, config: NavigationConfig) {
        super();
        this._app = app;
        this._config = config;

        this.Tabs = new TopbarTabs();
        this.Row = new TopbarRow();

        window.addEventListener('scroll', () => this._handleScroll());
        this._loadButtons();
    }

    private _updateData(data: Partial<TopbarData>) {
        this._data = {
            ...this._data,
            ...data,
        };
        this._callListeners();
    }

    private _handleScroll() {
        this.Tabs.handleScroll();

        if (this.Tabs.isScrollLocked()) {
            return;
        }

        const currentScroll = window.scrollY;
        const isVisible = this._prevScroll > currentScroll || currentScroll < 10;

        this._prevScroll = currentScroll;

        if (isVisible !== this._data.isVisible) {
            this._updateData({ isVisible });
        }
    }

    private _loadButtons() {
        this._config.topbarButtonsResolver?.(this._app)
            .then(buttons => {
                this._updateData({ buttons });
            })
            .catch(e => {
                console.error(e);
            });
    }

    getData(): TopbarData {
        return this._data;
    }
}
