import * as React from 'react';
import {TopbarButtonInternal} from "../types";
import {Button} from "react-bootstrap";
import clsx from "clsx";

type TopbarButtonProps = {
    data: TopbarButtonInternal,
}

const TopbarButton = ({ data }: TopbarButtonProps) => {
    return (
        <Button variant='link' className={clsx('py-0 px-1 font-20 lh-1', data.isActive ? 'link-primary' : 'link-secondary')} title={data.label} onClick={data.handleClick} disabled={data.isDisabled}>
            <i className={`mdi mdi-${data.icon}`} />
        </Button>
    );
}

export default TopbarButton
