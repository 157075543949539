import * as React from 'react';
import {MediaDisplay, useMedia} from "@ova-studio/react-media-library";
import EditorAwaitBlock from "../../../../../components/EditorAwaitBlock";
import {MediaSingleData} from "../../types/MediaSingleData";
import MediaDisplayWrapper from "./MediaDisplayWrapper";
import {MoveProps} from "../../../common/BaseBlockNode";

export type MediaSingleProps = {
    mediaId: number,
    handleUpdate: (data: MediaSingleData) => void,
    handleRemove: () => void,
} & MediaSingleData & MoveProps

const MediaSingle = ({ mediaId, handleUpdate, handleRemove, caption, ...moveProps }: MediaSingleProps) => {

    const media = useMedia(mediaId)

    return (
        <MediaDisplayWrapper handleUpdate={handleUpdate} handleRemove={handleRemove} data={{ caption }} {...moveProps}>
            <EditorAwaitBlock resolve={media}>
                {media => <MediaDisplay data={media} caption={caption} disableInteraction={true} />}
            </EditorAwaitBlock>
        </MediaDisplayWrapper>
    );
}

export default MediaSingle
