import * as React from "react";
import {InternalCollaborationUser} from "../types";
import classNames from "classnames";
import {Avatar, Tooltip} from "@ova-studio/react-hyper-admin";

type CollaborationUserDisplayProps = {
    data: InternalCollaborationUser,
}

const CollaborationUserTooltip = ({ data }: CollaborationUserDisplayProps) => {
    return (
        <React.Fragment>
            <span className='fw-bold'>{data.name}</span>
            {data.isCurrent && <span className='text-muted small ms-1'>Ви</span>}
        </React.Fragment>
    )
}

const CollaborationUserDisplay = ({ data }: CollaborationUserDisplayProps) => {
    return (
        <Tooltip text={<CollaborationUserTooltip data={data} />} placement='top'>
            <span>
                <Avatar
                    name={data.name}
                    avatarUrl={data.avatar}
                    borderColor={data.color}
                    className={classNames('rtc-user-avatar', data.isCurrent && 'rtc-user-current')}
                />
            </span>
        </Tooltip>
    )
}

export default CollaborationUserDisplay;