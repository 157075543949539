import * as React from 'react';
import {useExternalStore} from "@ova-studio/react-hyper-admin";
import {useEditorContext} from "../EditorContext";
import TopbarItemDisplay from "./TopbarItemDisplay";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import TopbarInsertDropdown from "./TopbarInsertDropdown";

const Topbar = () => {

    const [ editor ] = useLexicalComposerContext();

    const { topbarRegistry } = useEditorContext();

    React.useEffect(() => {
        return topbarRegistry.setEditor(editor);
    }, [ editor ]);

    const { items } = useExternalStore(() => topbarRegistry);

    return (
        <div className='ova-editor-topbar'>
            {Object.entries(items).map(([ groupName, groupItems ]) => (
                <div key={`group-${groupName}`} className='ova-editor-topbar-group' data-name={groupName}>
                    {groupItems.map((item, index) => (
                        <TopbarItemDisplay key={`item-${groupName}-${index}`} data={item} />
                    ))}
                </div>
            ))}
            <TopbarInsertDropdown />
        </div>
    );
}

export default Topbar
