import * as React from 'react';
import {MediaSingleData} from "../../types/MediaSingleData";
import Icon from "@mdi/react";
import {mdiDelete, mdiPencil} from "@mdi/js";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {Form, Modal, useModal} from "@ova-studio/react-hyper-admin";
import {Button} from "react-bootstrap";
import {MoveProps} from "../../../common/BaseBlockNode";
import MoveButtons from "../../../common/MoveButtons";

type MediaDisplayWrapperProps = {
    data: MediaSingleData,
    children: React.ReactNode,
    handleUpdate: (data: MediaSingleData) => void,
    handleRemove: () => void,
} & MoveProps

const MediaDisplayWrapper = ({ children, data, handleUpdate, handleRemove, ...moveProps }: MediaDisplayWrapperProps) => {

    const [ editor ] = useLexicalComposerContext();

    const editModal = useModal();

    const _handleRemove = () => {
        editor.update(() => handleRemove())
    }

    const _handleUpdate = (data: MediaSingleData) => new Promise<void>((resolve, reject) => {
        try {
            editor.update(() => handleUpdate(data));
            editModal.close();
            resolve();
        } catch (e) {
            reject(e);
        }
    })

    return (
        <React.Fragment>
            <Modal title='Змінити медіа' state={editModal}>
                <Form.Callback data={data} handleSubmit={_handleUpdate}>
                    <Modal.Body>
                        <Form.Input type='textarea' name='caption' label='Підпис' rows={3} groupClass='' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={editModal.close}>Скасувати</Button>
                        <Form.Submit>Зберегти</Form.Submit>
                    </Modal.Footer>
                </Form.Callback>
            </Modal>

            <div className='ova-editor-block-actions-container'>
                <div className='ova-editor-block-actions'>
                    <MoveButtons {...moveProps} />
                    <button onClick={editModal.open}><Icon path={mdiPencil}/></button>
                    <button onClick={_handleRemove}><Icon path={mdiDelete}/></button>
                </div>
            </div>
            {children}
        </React.Fragment>
    );
}

export default MediaDisplayWrapper
