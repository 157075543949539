import * as React from "react";
import {TopbarDropdownInternal} from "../types";
import {Dropdown} from "react-bootstrap";

type ItemBodyProps = {
    icon?: string,
    label?: string,
}

const ItemBody = ({ icon, label } : ItemBodyProps) => (
    <div className='d-inline-flex align-items-center lh-sm'>
        {icon && <i className={`mdi mdi-${icon} fs-5 me-1`} />}
        {label && <span>{label}</span>}
    </div>
)

type TopbarDropdownProps = {
    data: TopbarDropdownInternal,
}

const TopbarDropdown = ({ data }: TopbarDropdownProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant='link' className='link-secondary py-0 px-1 lh-1 arrow-none' title={data.title}>
                <ItemBody icon={data.icon} label={data.label} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {data.options.map((option, i) => (
                    <Dropdown.Item key={i} onClick={() => option.onClick()}>
                        <ItemBody icon={option.icon} label={option.label} />
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default TopbarDropdown;
