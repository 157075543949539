import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _colorButtonMixin;
import { css } from 'styled-components';
import StyledBadge from '../badge/badge.styles';
import { Color as PaletteColor } from '../../utils/types/palette';
import { FontVariant } from '../../utils/types/typography';
import { ButtonSize, ButtonColor } from '../../utils/types';
export var colorButtonMixin = (_colorButtonMixin = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_colorButtonMixin, ButtonColor.Primary, function (_ref) {
  var palette = _ref.theme.palette,
    active = _ref.active;
  return css(["background-color:", ";color:", ";", "{color:", ";background:transparent;}&:hover{background-color:", ";}&:active{background-color:", ";}", " &:disabled{color:", ";background:", ";cursor:default;", "{color:", ";background:transparent;}}"], palette[PaletteColor.AccentStateless], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], active && css(["background-color:", ";&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.Secondary, function (_ref2) {
  var palette = _ref2.theme.palette,
    active = _ref2.active;
  return css(["background-color:", ";color:", ";border:1px solid ", ";", "{color:", ";background:transparent;}&:hover{color:", ";background-color:", ";", "{color:", ";}}&:focus{color:", ";background-color:", ";border:1px solid ", ";", "{color:", ";}}&:active{color:", ";background-color:", ";", "{color:", ";}}", " &:disabled{color:", ";background:", ";border:none;cursor:default;", "{color:", ";}}"], palette[PaletteColor.BackgroundStateless], palette[PaletteColor.AccentStateless], palette[PaletteColor.AccentStateless], StyledBadge.Badge, palette[PaletteColor.AccentStateless], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryHover], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.AccentPrimaryActive], StyledBadge.Badge, palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryActive], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], active && css(["color:", ";background-color:", ";", "{color:", ";}&:hover,&:focus{color:", ";background-color:", ";", "{color:", ";}}"], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryActive], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.AccentPrimaryActive], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.Basic, function (_ref3) {
  var palette = _ref3.theme.palette,
    active = _ref3.active;
  return css(["background-color:transparent;border:1px solid ", ";color:", ";", "{background-color:transparent;color:", ";}&:hover{background-color:", ";color:", ";", "{color:", ";}}&:active{background-color:", ";color:", ";", "{color:", ";}}", " &:disabled{background-color:", ";color:", ";cursor:default;border:none;", "{color:", ";}}"], palette[PaletteColor.BordersButton], palette[PaletteColor.LinkStateless], StyledBadge.Badge, palette[PaletteColor.LinkStateless], palette[PaletteColor.BackgroundHover], palette[PaletteColor.LinkHover], StyledBadge.Badge, palette[PaletteColor.LinkHover], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkHover], StyledBadge.Badge, palette[PaletteColor.LinkHover], active && css(["background-color:", ";color:", ";", "{color:", ";}&:hover,&:focus{background-color:", ";color:", ";}"], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkHover], StyledBadge.Badge, palette[PaletteColor.LinkHover], palette[PaletteColor.BackgroundActive], palette[PaletteColor.LinkHover]), palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.BordersDisabled], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.LinkPrimary, function (_ref4) {
  var palette = _ref4.theme.palette,
    active = _ref4.active;
  return css(["color:", ";background-color:transparent;padding:0px !important;&:hover{background-color:transparent;color:", ";}&:active{color:", ";}", " &:disabled{background-color:transparent;color:", ";cursor:default;}"], palette[PaletteColor.AccentStateless], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], active && css(["color:", ";&:hover,&:focus{color:", ";"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled]);
}), ButtonColor.LinkSecondary, function (_ref5) {
  var palette = _ref5.theme.palette,
    active = _ref5.active;
  return css(["color:", ";background-color:transparent;padding:0px !important;&:hover{background-color:transparent;color:", ";}&:active{color:", ";}", " &:disabled{background-color:transparent;color:", ";cursor:default;}"], palette[PaletteColor.LinkStateless], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], active && css(["color:", ";&:hover,&:focus{color:", ";}"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled]);
}), ButtonColor.LinkBasicPrimary, function (_ref6) {
  var palette = _ref6.theme.palette,
    active = _ref6.active;
  return css(["color:", ";background-color:transparent;padding:0px !important;&:hover{background-color:transparent;color:", ";}&:active{color:", ";}", " &:disabled{background-color:transparent;color:", ";cursor:default;}"], palette[PaletteColor.AccentStateless], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], active && css(["color:", ";&:hover,&:focus{color:", ";}"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled]);
}), ButtonColor.LinkBasicSecondary, function (_ref7) {
  var palette = _ref7.theme.palette,
    active = _ref7.active;
  return css(["color:", ";background-color:transparent;padding:0px !important;&:hover{background-color:transparent;color:", ";}&:active{color:", ";}", " &:disabled{background-color:transparent;color:", ";cursor:default;}"], palette[PaletteColor.LinkStateless], palette[PaletteColor.AccentPrimaryHover], palette[PaletteColor.AccentPrimaryActive], active && css(["color:", ";&:hover,&:focus{color:", ";}"], palette[PaletteColor.AccentPrimaryActive], palette[PaletteColor.AccentPrimaryActive]), palette[PaletteColor.BordersDisabled]);
}), ButtonColor.ErrorPrimary, function (_ref8) {
  var palette = _ref8.theme.palette,
    active = _ref8.active;
  return css(["background-color:", ";color:", ";", "{color:", ";background:transparent;}&:hover{background-color:", ";}&:active{background-color:", ";}", " &:disabled{background-color:", ";color:", ";cursor:default;", "{color:", ";background:transparent;}}"], palette[PaletteColor.Error], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.ErrorHover], palette[PaletteColor.ErrorActive], active && css(["background-color:", ";&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.ErrorActive], palette[PaletteColor.ErrorActive]), palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.BordersDisabled], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.ErrorSecondary, function (_ref9) {
  var palette = _ref9.theme.palette,
    active = _ref9.active;
  return css(["background-color:", ";color:", ";border:1px solid ", ";", "{color:", ";background-color:transparent;}&:hover{background-color:", ";color:", ";border:none;", "{color:", ";}}&:active{background-color:", ";color:", ";border:none;}", " &:disabled{color:", ";background:", ";border:none;cursor:default;", "{color:", ";}}"], palette[PaletteColor.BackgroundStateless], palette[PaletteColor.LinkStateless], palette[PaletteColor.BordersButton], StyledBadge.Badge, palette[PaletteColor.LinkStateless], palette[PaletteColor.ErrorHover], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.ErrorActive], palette[PaletteColor.ButtonPrimaryText], active && css(["background-color:", ";color:", ";border:none;&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.ErrorActive], palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.ErrorActive]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.ErrorBasic, function (_ref10) {
  var palette = _ref10.theme.palette,
    active = _ref10.active;
  return css(["color:", ";background-color:transparent;&:hover{color:", ";}&:active{color:", ";}", " &:disabled{color:", ";cursor:default;}"], palette[PaletteColor.LinkStateless], palette[PaletteColor.ErrorHover], palette[PaletteColor.ErrorActive], active && css(["color:", ";&:hover,&:focus{color:", ";}"], palette[PaletteColor.ErrorActive], palette[PaletteColor.ErrorActive]), palette[PaletteColor.BordersDisabled]);
}), _defineProperty(_defineProperty(_defineProperty(_colorButtonMixin, ButtonColor.WarningPrimary, function (_ref11) {
  var palette = _ref11.theme.palette,
    active = _ref11.active;
  return css(["background-color:", ";color:", ";", "{color:", ";background:transparent;}&:hover{background-color:", ";}&:active{background-color:", ";}", " &:disabled{background-color:", ";color:", ";cursor:default;", "{color:", ";background:transparent;}}"], palette[PaletteColor.Warning], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.WarningHover], palette[PaletteColor.WarningActive], active && css(["background-color:", ";&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.WarningActive], palette[PaletteColor.WarningActive]), palette[PaletteColor.Accent_1_2_Opacity], palette[PaletteColor.BordersDisabled], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.WarningSecondary, function (_ref12) {
  var palette = _ref12.theme.palette,
    active = _ref12.active;
  return css(["background-color:", ";color:", ";border:1px solid ", ";", "{color:", ";background-color:transparent;}&:hover{background-color:", ";color:", ";border:none;", "{color:", ";}}&:active{background-color:", ";color:", ";border:none;}", " &:disabled{color:", ";background:", ";border:none;cursor:default;", "{color:", ";}}"], palette[PaletteColor.BackgroundStateless], palette[PaletteColor.LinkStateless], palette[PaletteColor.BordersButton], StyledBadge.Badge, palette[PaletteColor.LinkStateless], palette[PaletteColor.WarningHover], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.WarningActive], palette[PaletteColor.ButtonPrimaryText], active && css(["background-color:", ";color:", ";border:none;", "{color:", ";background-color:transparent;}&:hover,&:focus{background-color:", ";}"], palette[PaletteColor.WarningActive], palette[PaletteColor.ButtonPrimaryText], StyledBadge.Badge, palette[PaletteColor.ButtonPrimaryText], palette[PaletteColor.WarningActive]), palette[PaletteColor.BordersDisabled], palette[PaletteColor.Accent_1_2_Opacity], StyledBadge.Badge, palette[PaletteColor.BordersDisabled]);
}), ButtonColor.WarningBasic, function (_ref13) {
  var palette = _ref13.theme.palette,
    active = _ref13.active;
  return css(["color:", ";background-color:transparent;&:hover{color:", ";}&:active{color:", ";}", " &:disabled{color:", ";cursor:default;}"], palette[PaletteColor.LinkStateless], palette[PaletteColor.WarningHover], palette[PaletteColor.WarningActive], active && css(["color:", ";&:hover,&:focus{color:", ";}"], palette[PaletteColor.WarningActive], palette[PaletteColor.WarningActive]), palette[PaletteColor.BordersDisabled]);
}));
export var sizeButtonMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["padding:5px 10px;"])), ButtonSize.Sm, css(["padding:8px 12px;"])), ButtonSize.Md, css(["padding:11px 16px;"])), ButtonSize.Lg, css(["padding:14px 24px;"]));
export var sizeSecondaryButtonMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["padding:4px 10px;"])), ButtonSize.Sm, css(["padding:7px 12px;"])), ButtonSize.Md, css(["padding:10px 16px;"])), ButtonSize.Lg, css(["padding:13px 24px;"]));
export var sizeSidebarMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["padding:5px 10px;"])), ButtonSize.Sm, css(["padding:8px 12px;"])), ButtonSize.Md, css(["padding:11px 14px;"])), ButtonSize.Lg, css(["padding:14px 16px;"]));
export var sizeSidebarDividerMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["height:14px;"])), ButtonSize.Sm, css(["height:16px;"])), ButtonSize.Md, css(["height:18px;"])), ButtonSize.Lg, css(["height:20px;"]));
export var paddingIconLabelMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, css(["margin-right:5px;"])), ButtonSize.Sm, css(["margin-right:6px;"])), ButtonSize.Md, css(["margin-right:7px;"])), ButtonSize.Lg, css(["margin-right:8px;"]));
export var sizeButtonLabelMixin = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ButtonSize.Xs, function (_ref14) {
  var font = _ref14.theme.typography.font;
  return css(font[FontVariant.ButtonSm]);
}), ButtonSize.Sm, function (_ref15) {
  var font = _ref15.theme.typography.font;
  return css(font[FontVariant.ButtonMd]);
}), ButtonSize.Md, function (_ref16) {
  var font = _ref16.theme.typography.font;
  return css(font[FontVariant.ButtonLg]);
}), ButtonSize.Lg, function (_ref17) {
  var font = _ref17.theme.typography.font;
  return css(font[FontVariant.ButtonLgUp]);
});