import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import {$getSelection, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from "lexical";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import clearFormatForSelection from "./clearFormatForSelection";

const CLEAR_FORMAT_COMMAND : LexicalCommand<null> = createCommand<null>('CLEAR_FORMAT');

const ClearFormatPlugin = () => {

    const { topbarRegistry } = useEditorContext();

    const [ editor ] = useLexicalComposerContext();

    React.useEffect(() => {
        return editor.registerCommand(CLEAR_FORMAT_COMMAND, () => {
            editor.update(() => {
                clearFormatForSelection($getSelection())
            })
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, []);

    React.useEffect(() => {
        return topbarRegistry.register({
            section: 'text-format',
            label: 'Очистити форматування',
            icon: 'format-clear',
            command: CLEAR_FORMAT_COMMAND,
            payload: null,
            wrapWithData: true,
            isEnableCheck: 'selection',
        })
    }, []);

    return null;
}

export default ClearFormatPlugin
