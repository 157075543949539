import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
// import type { With } from '../../utils/types';

// import { Color as PaletteColor } from '../../utils/types/palette';
import { FontVariant as FV } from '../../utils/types/typography';
import { Size } from './types';
export var sizeMenuItemMixin = _defineProperty(_defineProperty({}, Size.Sm, function (_ref) {
  var font = _ref.theme.typography.font;
  return css(["padding:8px 16px;min-height:32px;", ""], font[FV.LabelMedium]);
}), Size.Md, function (_ref2) {
  var font = _ref2.theme.typography.font;
  return css(["padding:11px 16px;min-height:40px;", ""], font[FV.LabelLarge]);
});