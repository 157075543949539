import * as React from 'react';
import {Form, Modal, useBaseAppConfig, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Button, Col, Row} from "react-bootstrap";
import {TOGGLE_LINK_COMMAND} from "@lexical/link";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import convertEmptyStringToNull from "../../../utils/convertEmptyStringToNull";
import {SelectedLink} from "./types";
import targetOptions from "./options/targetOptions";
import relOptions from "./options/relOptions";
import makeLinkFormData from "./utils/makeLinkFormData";
import validateUrl from "./utils/validateUrl";
import valueOrDefault from "../../../utils/valueOrDefault";

const UrlAutoTarget = () => {
    const url = Form.useWatch({ name: 'url' });
    const { setValue } = Form.useFormContext();
    const { siteDomain } = useBaseAppConfig();

    React.useEffect(() => {

        if (!siteDomain) return;
        if (!url) return;

        const urlObj = new URL(url);

        if (urlObj.hostname === siteDomain || urlObj.hostname.endsWith(`.${siteDomain}`)) {
            setValue('target', '_self');
        } else {
            setValue('target', '_blank');
        }

    }, [ url ]);

    return null;
}

type LinkModalProps = {
    state: UseModalReturn<SelectedLink>
}

const LinkModal = ({ state }: LinkModalProps) => {

    const node = state.data?.node;

    const [editor] = useLexicalComposerContext();

    const handleSubmit = async (data: any) => {
        if (!validateUrl(data.url)) {
            throw new Error('Невірний формат посилання');
        }

        const linkData = {
            url: data.url,
            title: convertEmptyStringToNull(data.title),
            rel: valueOrDefault(data.rel, 'nofollow noopener'),
            target: valueOrDefault(data.target, '_blank'),
        }

        if (!node) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkData);
        } else {
            editor.update(() => {
                node.setURL(linkData.url);
                node.setTitle(linkData.title);
                node.setRel(linkData.rel);
                node.setTarget(linkData.target);
            });
        }
        state.close();
    }

    const handleRemove = () => {
        if (!node) return;
        editor.update(() => {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        });
        state.setData(null);
        state.close();
    }

    return (
        <Modal state={state} title={node ? 'Редагування посилання' : 'Додати посилання'}>
            <Form.Callback handleSubmit={handleSubmit} data={makeLinkFormData(state.data)}>
                <Modal.Body>
                    <Form.Input name='url' label='URL' placeholder='https://example.com' />
                    <Form.Input name='title' label='Опис' text='Буде додано до атрбуту title' />
                    <Row>
                        <Col>
                            <Form.BasicSelect name='target' label='Відкривання' options={targetOptions} groupClass='mb-0' />
                        </Col>
                        <Col>
                            <Form.BasicSelect name='rel' label='Rel атрибут' options={relOptions} groupClass='mb-0' />
                        </Col>
                    </Row>
                    <UrlAutoTarget />
                </Modal.Body>
                <Modal.Footer>
                    {node && <Button variant='danger' onClick={handleRemove}>Видалити</Button>}
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default LinkModal
