import * as React from 'react';
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import OnChangePlugin from "./OnChangePlugin";
import {useEditorConfig} from "../EditorContext";
import {MediaPlugin} from "./MediaPlugin";
import LinkPlugin from "./LinkPlugin";
import AlignPlugin from "./AlignPlugin";
import TextFormatPlugin from "./TextFormatPlugin";
import FullScreenPlugin from "./FullScreenPlugin/FullScreenPlugin";
import ClearFormatPlugin from "./ClearFormatPlugin";
import FontStylePlugin from "./FontStylePlugin";
import BlockTypePlugin from "./BlockTypePlugin";
import BlocksPlugin from "./BlocksPlugin";
import EmojiPlugin from "./EmojiPlugin";
import CollaborationPlugin from "./CollaborationPlugin";
import TablePlugin from "./TablePlugin";

const Plugins = () => {

    const config = useEditorConfig();

    return (
        <React.Fragment>
            <HistoryPlugin />
            <OnChangePlugin />
            {/*<MarkdownShortcutPlugin />*/}
            <BlockTypePlugin />
            <FontStylePlugin />
            {config.formats && <TextFormatPlugin />}
            {config.align && <AlignPlugin />}
            {config.links && <LinkPlugin isAutoLink={config.links === 'auto'} />}
            {config.mediaConfig && <MediaPlugin />}
            {config.emoji && <EmojiPlugin />}
            {config.table && <TablePlugin />}
            {config.blocks && <BlocksPlugin />}
            {config.rtcId && <CollaborationPlugin />}
            <ClearFormatPlugin />
            <FullScreenPlugin />
        </React.Fragment>
    );
}

export default Plugins
