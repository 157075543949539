import * as React from 'react';
import {useEditorContext} from "../../EditorContext";
import {COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from "lexical";
import {mergeRegister} from "@lexical/utils";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useBoolean} from "@ova-studio/react-hyper-admin";

const TOGGLE_FULL_SCREEN_COMMAND : LexicalCommand<void> = createCommand('TOGGLE_FULL_SCREEN_COMMAND');

const FullScreenPlugin = () => {

    const [ editor ] = useLexicalComposerContext();
    const { state, toggle } = useBoolean();

    const { topbarRegistry, editorRef } = useEditorContext();

    React.useEffect(() => {
        editorRef.current?.classList.toggle('ova-editor-fullscreen', state);

        return topbarRegistry.register({
            section: 'editor-fullscreen',
            command: TOGGLE_FULL_SCREEN_COMMAND,
            payload: undefined,
            icon: state ? 'arrow-collapse-all' : 'arrow-expand-all',
            label: state ? 'Вийти з повноекранного режиму' : 'Повноекранний режим',
            isActiveCheck: () => state,
        })
    }, [ state ]);

    React.useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                TOGGLE_FULL_SCREEN_COMMAND,
                () => {
                    toggle();
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
        );
    }, [])

    return null;
}

export default FullScreenPlugin
