import * as React from 'react';
import {Media, useMedia, MediaGallery as MediaGalleryRender} from "@ova-studio/react-media-library";
import EditorAwaitBlock from "../../../../../components/EditorAwaitBlock";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import Icon from "@mdi/react";
import {mdiDelete, mdiPencil} from "@mdi/js";
import {MediaGalleryNode} from "../../MediaGalleryNode";
import {EDIT_MEDIA_GALLERY_COMMAND} from "../../commands";
import {MoveProps} from "../../../common/BaseBlockNode";
import MoveButtons from "../../../common/MoveButtons";

type MediaGalleryProps = {
    node: MediaGalleryNode,
    mediaIds: number[],
    captions: Record<number, string>,
    handleRemove: () => void,
} & MoveProps

const MediaGallery = ({ node, mediaIds, captions, handleRemove, ...moveProps }: MediaGalleryProps) => {

    const [ editor ] = useLexicalComposerContext();

    const mediaAwait : Promise<Media[]> = useMedia(mediaIds);

    const _handleRemove = () => {
        editor.update(() => handleRemove())
    }

    const _handleOpenEditModal = () => {
        editor.dispatchCommand(EDIT_MEDIA_GALLERY_COMMAND, node)
    }

    return (
        <EditorAwaitBlock<Media[]> resolve={mediaAwait}>
            {(media) => (
                <div className="editor-gallery">
                    <div className='ova-editor-block-actions-container'>
                        <div className='ova-editor-block-actions'>
                            <MoveButtons {...moveProps} />
                            <button onClick={_handleOpenEditModal}><Icon path={mdiPencil}/></button>
                            <button onClick={_handleRemove}><Icon path={mdiDelete}/></button>
                        </div>
                    </div>
                    <MediaGalleryRender items={media} captions={captions} disableInteraction={true} />
                </div>
            )}
        </EditorAwaitBlock>
    );
}

export default MediaGallery
