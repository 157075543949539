import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable sonarjs/no-duplicate-string */
import { FontVariant, FontWeight } from '../../../../utils/types/typography';
var defaultFontValue = {
  fontFamily: '"Roboto"',
  fontStyle: 'normal',
  fontWeight: FontWeight.Regular
};
var getLabelFontVariants = function getLabelFontVariants() {
  var _ref;
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, FontVariant.LabelExtraSmall, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '10px',
    lineHeight: '12px'
  }, fontOverride)), FontVariant.LabelExtraSmallEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: 'Bold',
    fontSize: '10px',
    lineHeight: '12px'
  }, fontOverride)), FontVariant.LabelExtraSmallUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '11px',
    lineHeight: '12px'
  }, fontOverride)), FontVariant.LabelSmall, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.LabelSmallEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.LabelSmallUp, _objectSpread(_objectSpread({
    fontSize: '13px',
    lineHeight: '16px'
  }, defaultFontValue), fontOverride)), FontVariant.LabelSmallUpEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LabelMedium, _objectSpread(_objectSpread({
    fontSize: '14px',
    lineHeight: '16px'
  }, defaultFontValue), fontOverride)), FontVariant.LabelMediumEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LabelLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, FontVariant.LabelLargeEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.LabelLargeUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Regular,
    fontSize: '18px',
    lineHeight: '20px'
  }, fontOverride)), FontVariant.LabelExtraLargeEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '24px',
    lineHeight: '28px'
  }, fontOverride)), FontVariant.LabelExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Regular,
    fontSize: '24px',
    lineHeight: '28px'
  }, fontOverride)), FontVariant.LabelXExtraLargeEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '32px',
    lineHeight: '36px'
  }, fontOverride)), FontVariant.LabelXExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '32px',
    lineHeight: '36px'
  }, fontOverride)), FontVariant.LabelXXExtraLargeEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '48px',
    lineHeight: '54px'
  }, fontOverride)), FontVariant.LabelXXExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '48px',
    lineHeight: '54px'
  }, fontOverride)), FontVariant.LabelXXXExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '60px',
    lineHeight: '68px'
  }, fontOverride));
};
var getTextFontVariants = function getTextFontVariants() {
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FontVariant.TextExtraSmall, _objectSpread(_objectSpread({}, getLabelFontVariants()[FontVariant.LabelExtraSmallUp]), {}, {
    lineHeight: '14px'
  }, fontOverride)), FontVariant.TextSmall, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '12px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.TextSmallUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '13px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.TextMedium, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '14px',
    lineHeight: '20px'
  }, fontOverride)), FontVariant.TextLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '16px',
    lineHeight: '22px'
  }, fontOverride)), FontVariant.TextExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '18px',
    lineHeight: '26px'
  }, fontOverride)), FontVariant.TextXExtraLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '28px',
    lineHeight: '40px'
  }, fontOverride));
};
var getButtonFontVariants = function getButtonFontVariants() {
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FontVariant.ButtonSmEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.ButtonSm, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.ButtonSmUpEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.ButtonSmUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.ButtonMdEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.ButtonMd, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.ButtonLgEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.ButtonLg, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.ButtonLgUpEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '18px',
    lineHeight: '20px'
  }, fontOverride)), FontVariant.ButtonLgUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '18px',
    lineHeight: '20px'
  }, fontOverride));
};
var getLinkFontVariants = function getLinkFontVariants() {
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FontVariant.LinkSmall, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.LinkSmallEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.LinkSmallUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LinkSmallUpEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LinkMedium, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LinkMediumEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.LinkLarge, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.LinkLargeEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.LinkLargeUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '18px',
    lineHeight: '20px'
  }, fontOverride)), FontVariant.LinkLargeUpEmphasis, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '18px',
    lineHeight: '20px'
  }, fontOverride));
};
var getInputFontVariants = function getInputFontVariants() {
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FontVariant.InputSm, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '12px',
    lineHeight: '14px'
  }, fontOverride)), FontVariant.InputSmUp, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '13px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.InputMd, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '14px',
    lineHeight: '16px'
  }, fontOverride)), FontVariant.InputLg, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontSize: '16px',
    lineHeight: '18px'
  }, fontOverride));
};
var getTitleFontVariants = function getTitleFontVariants() {
  var fontOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FontVariant.TitleH6, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '12px',
    lineHeight: '18px'
  }, fontOverride)), FontVariant.TitleH5, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '14px',
    lineHeight: '21px'
  }, fontOverride)), FontVariant.TitleH4, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '16px',
    lineHeight: '24px'
  }, fontOverride)), FontVariant.TitleH3, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '18px',
    lineHeight: '27px'
  }, fontOverride)), FontVariant.TitleH2, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '20px',
    lineHeight: '30px'
  }, fontOverride)), FontVariant.TitleH1, _objectSpread(_objectSpread({}, defaultFontValue), {}, {
    fontWeight: FontWeight.Medium,
    fontSize: '28px',
    lineHeight: '42px'
  }, fontOverride));
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export var getDefaultTypography = function getDefaultTypography() {
  var typographyOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({
    fontFamily: defaultFontValue.fontFamily,
    baseLineHeight: 1.375
  }, typographyOverride), {}, {
    font: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, getLabelFontVariants(typographyOverride)), getTextFontVariants(typographyOverride)), getButtonFontVariants(typographyOverride)), getLinkFontVariants(typographyOverride)), getInputFontVariants(typographyOverride)), getTitleFontVariants(typographyOverride))
  });
};