import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from 'styled-components';
import { InputSize } from '../../utils/types';
import { Color as PaletteColor } from '../../utils/types/palette';
import { FontVariant as FV } from '../../utils/types/typography';
export var errorMixin = function errorMixin(_ref) {
  var palette = _ref.theme.palette;
  return css(["background:", " !important;border:1px solid ", " !important;"], palette[PaletteColor.BackgroundSecondary], palette[PaletteColor.Error]);
};
export var heightTextAreaMixin = _defineProperty(_defineProperty({}, InputSize.Sm, function () {
  return css(["height:136px;"]);
}), InputSize.Md, function () {
  return css(["height:152px;"]);
});
export var sizeTextAreaMixin = _defineProperty(_defineProperty({}, InputSize.Sm, function (_ref2) {
  var font = _ref2.theme.typography.font;
  return css(["padding:8px 12px;", ""], font[FV.InputMd]);
}), InputSize.Md, function (_ref3) {
  var font = _ref3.theme.typography.font;
  return css(["padding:8px 16px;", ""], font[FV.InputLg]);
});