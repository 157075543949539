import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Button } from 'react-bootstrap'
import image from './images/callback.jpg'

type CallbackBlockData = {
    phones: string[],
    viberPhone: string,
}

const isCallbackBlockData = (data: unknown): data is CallbackBlockData => {
    return typeof data === 'object' && data !== null && 'phones' in data && Array.isArray(data.phones) && data.phones.every((phone) => typeof phone === 'string') && 'viberPhone' in data && typeof data.viberPhone === 'string';
}

const callbackBlockDataValidator = async (data: unknown) => {
    if (!isCallbackBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Зворотній дзвінок"');
    }
}

const CallbackBlockForm = () => {
    const { fields, append, remove } = Form.useFieldArray({ name: 'phones' });

    return (
        <React.Fragment>
            {fields.map((field, idx) => (
                <Form.Input
                    key={field.id}
                    type='text'
                    name={`phones.${idx}`}
                    label={`Телефон #${idx + 1}`}
                    append={<Button variant='link' onClick={() => remove(idx)} className='mdi mdi-delete link-danger p-0' />}
                />
            ))}

            <div className='mb-2'><Button onClick={() => append({ phone: '' })}>Додати</Button></div>

            <Form.Input
                type='text'
                name='viberPhone'
                label='Viber телефон'
            />
        </React.Fragment>
    )
}

const CallbackBlock : BlockDefinition<CallbackBlockData> = {
    name: 'hs--callback',
    image: image,
    title: 'Блок "Контакти"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: callbackBlockDataValidator,
    form: CallbackBlockForm,
    initialData: {
        phones: [
            '+38 (050) 123-45-67',
            '+38 (067) 123-45-67',
            '+38 (093) 123-45-67',
        ],
        viberPhone: '+380501234567',
    },
}

export default CallbackBlock