import * as React from 'react'
import { BlockDefinition, EditorBlockServerRenderer } from '@ova-studio/react-block-editor'
import { makeRequestError, ErrorType } from '@ova-studio/api-helper'
import { Form } from '@ova-studio/react-hyper-admin'
import { Media, FormMediaSelectInput } from '@ova-studio/react-media-library'
import { Button } from 'react-bootstrap'
import image from './images/logos.jpg'

type LogosBlockData = {
    title: string,
    mediaIds: (Media['id'])[],
}

const defaultLogosBlockData: LogosBlockData = {
    title: 'Lorem ipsum: загальні приклади',
    mediaIds: [],
}

const isLogosBlockData = (data: unknown): data is LogosBlockData => {
    return typeof data === 'object' && data !== null && 'title' in data && 'mediaIds' in data && Array.isArray(data.mediaIds);
}

const logosBlockDataValidator = async (data: unknown) => {
    if (!isLogosBlockData(data)) {
        throw makeRequestError(ErrorType.Validation, 'Невірний формат даних блоку "Логотипи"');
    }
}

const LogosBlockForm = () => {
    const { fields, append } = Form.useFieldArray({ name: 'items' });

    return (
        <React.Fragment>
            <Form.Input
                label='Заголовок'
                name='title'
            />

            {fields.map((field, idx) => (
                <FormMediaSelectInput
                    key={field.id}
                    name={`mediaIds.${idx}`}
                    label={`Логотип #${idx + 1}`}
                />
            ))}

            <div className='mt-2'><Button onClick={() => append(0)}>Додати</Button></div>
        </React.Fragment>
    )
}

const LogosBlock : BlockDefinition<LogosBlockData> = {
    name: 'hs--logos',
    image: image,
    title: 'Блок "Логотипи"',
    renderer: EditorBlockServerRenderer,
    exportMode: 'insert',
    formSize: 'lg',
    validator: logosBlockDataValidator,
    form: LogosBlockForm,
    initialData: defaultLogosBlockData,
}

export default LogosBlock