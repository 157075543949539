import {LinkMatcher} from "@lexical/react/LexicalAutoLinkPlugin";

const urlRegex = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

const linkMatchers : Array<LinkMatcher> = [
    (text: string) => {
        const match = urlRegex.exec(text);
        if (match === null) return null;

        const fullMatch = match[0];

        return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
            // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
        };
    }
];

export default linkMatchers;
