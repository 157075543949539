import * as React from 'react';
import {useEditorContext} from "../EditorContext";
import {OnChangePlugin as LexicalOnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {$generateHtmlFromNodes} from '@lexical/html';
import {EditorState, LexicalEditor} from "lexical";

const OnChangePlugin = () => {

    const { onChange } = useEditorContext();

    const handleChange = React.useCallback((editorState: EditorState, editor: LexicalEditor) => {
        editorState.read(() => {
            const html = $generateHtmlFromNodes(editor);
            onChange && onChange({ editor, editorState, html });
        });
    }, []);

    return (
        <LexicalOnChangePlugin onChange={handleChange} ignoreSelectionChange={true} />
    );
}

export default OnChangePlugin
