import {LinkNode} from "@lexical/link";
import {SelectedLink} from "../types";
import valueOrDefault from "../../../../utils/valueOrDefault";

const processLinkNode = (node: LinkNode) : SelectedLink => {
    return {
        url: valueOrDefault(node.getURL(), ''),
        title: valueOrDefault(node.getTitle(), ''),
        rel: valueOrDefault(node.getRel(), 'nofollow noopener'),
        target: valueOrDefault(node.getTarget(), '_blank'),
        node,
    }
}

export default processLinkNode;
