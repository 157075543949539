import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {
    CustomDropdownItem, GlobalActionButtonBaseProps,
    GlobalActionSimpleButton,
    GlobalButton,
    GlobalButtonsData,
    isGlobalActionButtonDropdown, isGlobalActionButtonPortal
} from "./types/GlobalButtons";
import {SimpleCallback} from "../../types/SimpleCallback";
import {deleteRecord} from "../../helpers/recordHelpers";
import {cloneDeep} from "lodash";
import {NavigationConfig} from "./types/NavigationConfig";
import {App} from "../../Application";

export default class GlobalButtons extends AbstractDataStore<GlobalButtonsData> {

    private _basicButtons: GlobalButtonsData = {};
    private _customButtons: GlobalButtonsData = {};
    private _customDropdownItems: CustomDropdownItem[] = [];

    private _buttons: GlobalButtonsData = {};

    private readonly _app: App;
    private readonly _config: NavigationConfig;

    constructor(app: App, config: NavigationConfig) {
        super();
        this._app = app;
        this._config = config;

        void this._init();
    }

    private async _init() {
        if (!this._config.globalButtonsResolver) {
            return;
        }

        try {
            this._basicButtons = await this._config.globalButtonsResolver(this._app);
            this._updateButtons();
        } catch (e) {
            console.error(e);
        }
    }

    private _removeCustomButton(key: string) {
        this._customButtons = deleteRecord(this._customButtons, key);
        this._updateButtons();
    }

    public registerCustomButton(key: string, button: GlobalButton) : SimpleCallback {
        this._customButtons = {
            ...this._customButtons,
            [key]: button,
        }

        this._updateButtons();

        return () => {
            this._removeCustomButton(key);
        }
    }

    private _removeDropdownItem(buttonKey: string, key: string) {
        this._customDropdownItems = this._customDropdownItems.filter(item => !(item.buttonKey === buttonKey && item.key === key));
        this._updateButtons();
    }

    public registerDropdownItem(buttonKey: string, key: string, button: GlobalActionSimpleButton, force: boolean = false) : SimpleCallback {
        if (!this._basicButtons.hasOwnProperty(buttonKey) && !this._customButtons.hasOwnProperty(buttonKey) && !force) {
            throw new Error(`Button with key ${buttonKey} does not exist`);
        }

        this._customDropdownItems = [
            ...this._customDropdownItems,
            { buttonKey, key, button },
        ]

        this._updateButtons();

        return () => {
            this._removeDropdownItem(buttonKey, key);
        }
    }

    private _sortButtons<T extends Record<string, GlobalActionButtonBaseProps>>(buttons: T) : T {
        return Object.entries(buttons)
            .sort(([,a],[,b]) => (a.order ?? -1) - (b.order ?? -1))
            .reduce((r, [k, v]) => {
                if (isGlobalActionButtonDropdown(v)) {
                    v.items = this._sortButtons(v.items);
                }

                return { ...r, [k]: v };
            }, {} as T);
    }

    private _updateButtons() {
        const buttons = cloneDeep({
            ...this._basicButtons,
            ...this._customButtons,
        });

        this._customDropdownItems.forEach(({ buttonKey, key, button }) => {
            if (!buttons.hasOwnProperty(buttonKey)) {
                return;
            }

            let btn = buttons[buttonKey];

            if (isGlobalActionButtonPortal(btn)) {
                throw new Error(`Cannot add dropdown item to portal button with key ${buttonKey}`);
            }

            if (isGlobalActionButtonDropdown(btn)) {
                buttons[buttonKey] = {
                    ...btn,
                    items: {
                        ...btn.items,
                        [key]: cloneDeep(button),
                    }
                }
            } else {
                buttons[buttonKey] = {
                    icon: btn.icon,
                    title: btn.title,
                    order: btn.order,
                    variant: btn.variant,
                    items: {
                        [`${buttonKey}.basic`]: cloneDeep(btn),
                        [key]: cloneDeep(button),
                    },
                }
            }
        });

        this._buttons = this._sortButtons(buttons);

        this._callListeners();
    }

    getData(): GlobalButtonsData {
        return this._buttons;
    }
}
