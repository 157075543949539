import {EditorConfig} from "./EditorConfig";
import {InitialConfigType} from "@lexical/react/LexicalComposer";
import {Klass, LexicalNode} from "lexical";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {HorizontalRuleNode} from "@lexical/react/LexicalHorizontalRuleNode";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {ListItemNode, ListNode} from "@lexical/list";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {MediaSingleNode} from "../Editor/Plugins/MediaPlugin";
import {MediaGalleryNode} from "../Editor/Plugins/MediaPlugin/MediaGalleryNode";
import {defaultMediaConfig} from "./defaultMediaConfig";
import {CustomBlockNode} from "../Editor/Plugins/BlocksPlugin/CustomBlockNode";

export default function makeNodesFromConfig(config : EditorConfig) : InitialConfigType['nodes'] {

    const data : Array<Klass<LexicalNode>> = [
        HeadingNode,
        LinkNode,
    ];

    if (config.links === 'auto') {
        data.push(AutoLinkNode);
    }

    if (config.blocks) {
        CustomBlockNode.blocks = config.blocks;
        data.push(CustomBlockNode);
    }

    if (config.hr) {
        data.push(HorizontalRuleNode);
    }

    if (config.lists) {
        data.push(ListNode, ListItemNode);
    }

    if (config.quote) {
        data.push(QuoteNode);
    }

    if (config.table) {
        data.push(TableNode, TableCellNode, TableRowNode);
    }

    if (config.mediaConfig) {
        const mediaConfig = typeof config.mediaConfig === 'boolean' ? defaultMediaConfig : config.mediaConfig;
        if (mediaConfig.enableSingle) {
            data.push(MediaSingleNode);
        }
        if (mediaConfig.enableGallery) {
            data.push(MediaGalleryNode);
        }
    }

    return data;

}
