import * as React from "react";
import { CollaborationPlugin as LexicalCollaborationPlugin } from "@lexical/react/LexicalCollaborationPlugin";
import {useCollaborationContext} from "@ova-studio/rtc-client";
import providerFactoryGenerator from "./providerFactoryGenerator";
import type {Doc} from "yjs";
import {useEditorContext} from "../../EditorContext";

const CollaborationPlugin = () => {
    const { config: { rtcId }, cursorsContainer, initState } = useEditorContext();

    if (!rtcId) {
        throw new Error('CollaborationPlugin: rtcId is required in the editor config');
    }

    const service = useCollaborationContext();

    const providerFactory = React.useCallback((id: string, yjsDocMap: Map<string, Doc>) => providerFactoryGenerator(service, id, yjsDocMap), []);

    if (!cursorsContainer) {
        return null;
    }

    return (
        <LexicalCollaborationPlugin
            id={rtcId}
            providerFactory={providerFactory}
            initialEditorState={initState}
            shouldBootstrap={true}
            cursorsContainerRef={{ current: cursorsContainer }}
            username={service.authData.name}
            cursorColor={service.authData.color}
        />
    )
}

export default CollaborationPlugin;
