import * as React from 'react';

const StatusBar = () => {
    return (
        <div className='ova-editor-status-bar'>
            <div className='text-black'>
                <b>Enter</b> - новий абзац, <b>Shift + Enter</b> - новий рядок
            </div>
            <div className='ms-auto text-muted'>
                Powered by{' '}
                <a href='https://ova.in.ua' className='link-secondary text-decoration-none'>OVA Editor</a>
            </div>
        </div>
    );
}

export default StatusBar
