import {UseFormReturn} from "react-hook-form/dist/types/form";
import {FormRtcHelperData, FormRtcOptions} from "./types";
import dot from "dot-object";
import {CollaborationDocument, CollaborationService} from "../provider";
import {AbstractDataStore, SimpleCallback, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {FieldValues} from "@ova-studio/api-helper";

type HelperModals = {
    offline: UseModalReturn<any>,
}

export default class FormRtcService extends AbstractDataStore<FormRtcHelperData> {

    private readonly _service: CollaborationService;
    private readonly _options: FormRtcOptions;
    private readonly _cDoc: CollaborationDocument;
    private readonly _modals: HelperModals

    private _methods: UseFormReturn|null = null;

    private _onDestroy: SimpleCallback[] = [];

    private _data: FormRtcHelperData = {
        isReady: false,
        isOfflineMode: false,
    }

    constructor(options: FormRtcOptions, service: CollaborationService, modals: HelperModals) {
        super();

        this._service = service;
        this._options = options;
        this._modals = modals;

        this._cDoc = this._service.makeDocument(this._options.formId);
    }

    private get _doc() {
        return this._cDoc.doc;
    }

    private _updateLocalData() {
        if (!this._methods) {
            throw new Error('FormRtcService: methods is not initialized');
        }

        const data = this._doc.getMap('__data').toJSON();

        for (const [key, value] of Object.entries(data)) {
            if (this._methods.getValues(key) !== value) {
                this._methods.setValue(key, value);
            }
        }
    }

    private _initHandler() {
        this._doc.on('update', () => {
            this._updateLocalData();
        });

        this._onDestroy.push(
            this._service.addOnlineEventListener((isOnline) => {
                if (isOnline) {
                    this._modals.offline.close();
                } else {
                    if (!this._data.isOfflineMode) {
                        this._modals.offline.open();
                    }
                }
            })
        )
    }

    public get hasOfflineMode() : boolean {
        return !!this._options.handleOfflineSubmit;
    }

    public enableOfflineMode() {
        this._service.destroy();
        this._updateData({ isOfflineMode: true });
        this._modals.offline.close();
    }

    private _initWatch() {
        if (!this._methods) {
            throw new Error('FormRtcService: methods is not initialized');
        }

        this._methods.watch((data, { name, type }) => {
            if (type !== 'change' || !name) {
                return;
            }

            this._doc.transact(() => {
                const value = dot.pick(name, data);
                if (value === undefined) {
                    console.warn(`FormRtcService: value for ${name} is undefined`, data);
                    return;
                }
                this._doc.getMap('__data').set(name, value ?? null);
            });
        });
    }

    public init(methods: UseFormReturn) : void {
        if (this._methods) return;
        this._methods = methods;

        this._initWaitReady();
        this._updateLocalData();
        this._initHandler();
        this._initWatch();
    }

    public _initWaitReady() : void {
        const isReady = () => {
            return this._doc.getMap('__init').get('__initialized') === 'true';
        }

        if (isReady()) {
            this._updateData({ isReady: true })
        }

        const check = () => {
            if (isReady()) {
                this._updateData({ isReady: true })
                this._doc.off('update', check);
            }
        }

        this._doc.on('update', check);
    }

    private _updateData(data: Partial<FormRtcHelperData>) {
        this._data = {
            ...this._data,
            ...data,
        }
        this._callListeners();
    }

    public destroy() {
        this._methods = null;

        if (this._cDoc) {
            this._service.clearDocument(this._cDoc.name);
        }

        this._onDestroy.forEach((cb) => cb());
        this._onDestroy = [];
    }

    public async handleSubmit(data: FieldValues) {
        if (this._data.isOfflineMode) {
            if (!this._options.handleOfflineSubmit) {
                throw new Error('FormRtcService: Submit handler for offline mode is not defined. Please provide handleOfflineSubmit prop if you want to use offline submit.');
            }

            return await this._options.handleOfflineSubmit(data);
        }

        if (!this._options.handleOnlineSubmit) {
            throw new Error('FormRtcService: Submit handler for online mode is not defined. Please provide handleOnlineSubmit prop if you want to use online submit.');
        }

        return await this._options.handleOnlineSubmit(data);
    }

    getData() : FormRtcHelperData {
        return this._data;
    }

}