import {OnChangeProps} from "../../config/OnChangeHandler";
import {FormDataFormat, FormDataValue} from "../types";

export default function prepareForSave<T extends FormDataFormat>(data: OnChangeProps, format: T) : FormDataValue<T> {
    switch (format) {
        case FormDataFormat.HTML:
            return data.html as FormDataValue<T>;
        case FormDataFormat.JSON:
            return JSON.stringify(data.editorState.toJSON()) as FormDataValue<T>;
        case FormDataFormat.Both:
            return {
                html: data.html,
                json: JSON.stringify(data.editorState.toJSON()),
            } as FormDataValue<T>;

        default:
            throw new Error(`Unknown format: ${format}`);
    }
}
